// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateConsensusReviews } from './GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateConsensusReviews'
import {
  GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSON,
  GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateConsensusReviewsToJSON,
} from './GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateConsensusReviews'
import type { GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFinalReview } from './GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFinalReview'
import {
  GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFinalReviewFromJSON,
  GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFinalReviewFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFinalReviewToJSON,
} from './GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFinalReview'

/**
 *
 * @export
 * @interface GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewState
 */
export interface GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewState {
  /**
   * The number of consensus reviews required for this entity
   * @type {number}
   * @memberof GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewState
   */
  requiredConsensusReviewCount?: number
  /**
   *
   * @type {Array<GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateConsensusReviews>}
   * @memberof GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewState
   */
  consensusReviews?: Array<GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateConsensusReviews>
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFinalReview}
   * @memberof GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewState
   */
  finalReview?: GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFinalReview
}

/**
 * Check if a given object implements the GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewState interface.
 */
export function instanceOfGetDisplayAdGroupsParamDataAttributesEditorialStatesReviewState(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFromJSON(
  json: any
): GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewState {
  return GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFromJSONTyped(json, false)
}

export function GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewState {
  if (json === undefined || json === null) {
    return json
  }
  return {
    requiredConsensusReviewCount: !exists(json, 'required_consensus_review_count')
      ? undefined
      : json['required_consensus_review_count'],
    consensusReviews: !exists(json, 'consensus_reviews')
      ? undefined
      : (json['consensus_reviews'] as Array<any>).map(
          GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateConsensusReviewsFromJSON
        ),
    finalReview: !exists(json, 'final_review')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFinalReviewFromJSON(
          json['final_review']
        ),
  }
}

export function GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateToJSON(
  value?: GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewState | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    required_consensus_review_count: value.requiredConsensusReviewCount,
    consensus_reviews:
      value.consensusReviews === undefined
        ? undefined
        : (value.consensusReviews as Array<any>).map(
            GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateConsensusReviewsToJSON
          ),
    final_review: GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFinalReviewToJSON(
      value.finalReview
    ),
  }
}
