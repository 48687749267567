// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ApiCampaignsControllerBulkCreateInput } from '../models/ApiCampaignsControllerBulkCreateInput'
import {
  ApiCampaignsControllerBulkCreateInputFromJSON,
  ApiCampaignsControllerBulkCreateInputToJSON,
} from '../models/ApiCampaignsControllerBulkCreateInput'
import type { ApiCampaignsControllerBulkUpdateInput } from '../models/ApiCampaignsControllerBulkUpdateInput'
import {
  ApiCampaignsControllerBulkUpdateInputFromJSON,
  ApiCampaignsControllerBulkUpdateInputToJSON,
} from '../models/ApiCampaignsControllerBulkUpdateInput'
import type { ApiCampaignsControllerCreateInput } from '../models/ApiCampaignsControllerCreateInput'
import {
  ApiCampaignsControllerCreateInputFromJSON,
  ApiCampaignsControllerCreateInputToJSON,
} from '../models/ApiCampaignsControllerCreateInput'
import type { ApiCampaignsControllerDeleteInput } from '../models/ApiCampaignsControllerDeleteInput'
import {
  ApiCampaignsControllerDeleteInputFromJSON,
  ApiCampaignsControllerDeleteInputToJSON,
} from '../models/ApiCampaignsControllerDeleteInput'
import type { ApiCampaignsControllerDuplicateAsyncInput } from '../models/ApiCampaignsControllerDuplicateAsyncInput'
import {
  ApiCampaignsControllerDuplicateAsyncInputFromJSON,
  ApiCampaignsControllerDuplicateAsyncInputToJSON,
} from '../models/ApiCampaignsControllerDuplicateAsyncInput'
import type { ApiCampaignsControllerNegativeKeywordsInput } from '../models/ApiCampaignsControllerNegativeKeywordsInput'
import {
  ApiCampaignsControllerNegativeKeywordsInputFromJSON,
  ApiCampaignsControllerNegativeKeywordsInputToJSON,
} from '../models/ApiCampaignsControllerNegativeKeywordsInput'
import type { ApiCampaignsControllerUpdateInput } from '../models/ApiCampaignsControllerUpdateInput'
import {
  ApiCampaignsControllerUpdateInputFromJSON,
  ApiCampaignsControllerUpdateInputToJSON,
} from '../models/ApiCampaignsControllerUpdateInput'
import type { AsyncTaskResponse } from '../models/AsyncTaskResponse'
import { AsyncTaskResponseFromJSON, AsyncTaskResponseToJSON } from '../models/AsyncTaskResponse'
import type { BulkCampaignsResponse } from '../models/BulkCampaignsResponse'
import {
  BulkCampaignsResponseFromJSON,
  BulkCampaignsResponseToJSON,
} from '../models/BulkCampaignsResponse'
import type { DeleteCampaignsResponse } from '../models/DeleteCampaignsResponse'
import {
  DeleteCampaignsResponseFromJSON,
  DeleteCampaignsResponseToJSON,
} from '../models/DeleteCampaignsResponse'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { GetCampaignActionsResponse } from '../models/GetCampaignActionsResponse'
import {
  GetCampaignActionsResponseFromJSON,
  GetCampaignActionsResponseToJSON,
} from '../models/GetCampaignActionsResponse'
import type { GetCampaignAnalyticsResponse } from '../models/GetCampaignAnalyticsResponse'
import {
  GetCampaignAnalyticsResponseFromJSON,
  GetCampaignAnalyticsResponseToJSON,
} from '../models/GetCampaignAnalyticsResponse'
import type { GetCampaignResponse } from '../models/GetCampaignResponse'
import {
  GetCampaignResponseFromJSON,
  GetCampaignResponseToJSON,
} from '../models/GetCampaignResponse'
import type { GetCampaignsResponse } from '../models/GetCampaignsResponse'
import {
  GetCampaignsResponseFromJSON,
  GetCampaignsResponseToJSON,
} from '../models/GetCampaignsResponse'
import type { GetCampaignsSumResponse } from '../models/GetCampaignsSumResponse'
import {
  GetCampaignsSumResponseFromJSON,
  GetCampaignsSumResponseToJSON,
} from '../models/GetCampaignsSumResponse'
import type { PostCampaignResponse } from '../models/PostCampaignResponse'
import {
  PostCampaignResponseFromJSON,
  PostCampaignResponseToJSON,
} from '../models/PostCampaignResponse'
import type { PutCampaignResponse } from '../models/PutCampaignResponse'
import {
  PutCampaignResponseFromJSON,
  PutCampaignResponseToJSON,
} from '../models/PutCampaignResponse'

export interface GetCampaignsRequest {
  excludeAnalytics?: boolean
  query?: string
  dateRangeStartDate?: string
  dateRangeEndDate?: string
  attributionModels?: Array<string>
  enabled?: boolean
  status?: GetCampaignsStatusEnum
  type?: GetCampaignsTypeEnum
  sortBy?: Array<GetCampaignsSortByEnum>
  page?: string
  includeSupplementaryStatuses?: boolean
  automatedBiddingEnabled?: boolean
  xApiKey?: string
  xSecretKey?: string
}

export interface GetCampaignsIdRequest {
  id: string
  dateRangeStartDate?: string
  dateRangeEndDate?: string
  attributionModels?: Array<string>
  excludeAnalytics?: boolean
  xApiKey?: string
  xSecretKey?: string
}

export interface GetCampaignsIdActionsRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetCampaignsIdAnalyticsRequest {
  dateRangeStartDate: string
  metric: GetCampaignsIdAnalyticsMetricEnum
  id: string
  query?: string
  dateRangeEndDate?: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetCampaignsSumRequest {
  query?: string
  dateRangeStartDate?: string
  dateRangeEndDate?: string
  attributionModels?: Array<string>
  enabled?: boolean
  status?: GetCampaignsSumStatusEnum
  type?: GetCampaignsSumTypeEnum
  xApiKey?: string
  xSecretKey?: string
}

export interface PostCampaignsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiCampaignsControllerCreateInput
}

export interface PostCampaignsBulkRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiCampaignsControllerBulkCreateInput
}

export interface PostCampaignsDeleteRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiCampaignsControllerDeleteInput
}

export interface PostCampaignsIdActivateRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface PostCampaignsIdActivateAsyncRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface PostCampaignsIdDuplicateAsyncRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiCampaignsControllerDuplicateAsyncInput
}

export interface PutCampaignsBulkRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiCampaignsControllerBulkUpdateInput
}

export interface PutCampaignsIdRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiCampaignsControllerUpdateInput
}

export interface PutCampaignsIdNegativeKeywordsRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiCampaignsControllerNegativeKeywordsInput
}

/**
 *
 */
export class CampaignsApi extends runtime.BaseAPI {
  /**
   * Use GET /campaigns to retrieve a list of all your ad campaigns. You can optionally search for a specific campaign by name and this method returns only the campaigns with matching names. You can also send filter criteria as parameters and this method returns only the campaigns that match.  This method returns details for each campaign, including its attributes and analytics on how it’s performing. In addition, it returns some metadata about the list of campaigns it returns, including the number of pages of results which can be used to break the results you receive up.
   * Retrieve a list of campaigns
   */
  async getCampaignsRaw(
    requestParameters: GetCampaignsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetCampaignsResponse>> {
    const queryParameters: any = {}

    if (requestParameters.excludeAnalytics !== undefined) {
      queryParameters['exclude_analytics'] = requestParameters.excludeAnalytics
    }

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query
    }

    if (requestParameters.dateRangeStartDate !== undefined) {
      queryParameters['date_range[start_date]'] = requestParameters.dateRangeStartDate
    }

    if (requestParameters.dateRangeEndDate !== undefined) {
      queryParameters['date_range[end_date]'] = requestParameters.dateRangeEndDate
    }

    if (requestParameters.attributionModels) {
      queryParameters['attribution_models'] = requestParameters.attributionModels.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled
    }

    if (requestParameters.status !== undefined) {
      queryParameters['status'] = requestParameters.status
    }

    if (requestParameters.type !== undefined) {
      queryParameters['type'] = requestParameters.type
    }

    if (requestParameters.sortBy) {
      queryParameters['sort_by'] = requestParameters.sortBy.join(runtime.COLLECTION_FORMATS['csv'])
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.includeSupplementaryStatuses !== undefined) {
      queryParameters['include_supplementary_statuses'] =
        requestParameters.includeSupplementaryStatuses
    }

    if (requestParameters.automatedBiddingEnabled !== undefined) {
      queryParameters['automated_bidding_enabled'] = requestParameters.automatedBiddingEnabled
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/campaigns`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetCampaignsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use GET /campaigns to retrieve a list of all your ad campaigns. You can optionally search for a specific campaign by name and this method returns only the campaigns with matching names. You can also send filter criteria as parameters and this method returns only the campaigns that match.  This method returns details for each campaign, including its attributes and analytics on how it’s performing. In addition, it returns some metadata about the list of campaigns it returns, including the number of pages of results which can be used to break the results you receive up.
   * Retrieve a list of campaigns
   */
  async getCampaigns(
    requestParameters: GetCampaignsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetCampaignsResponse> {
    const response = await this.getCampaignsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method returns details about a specific campaign, including some analytics and any ad groups it contains. You need to specify the id of the campaign — which was returned by POST /campaigns when you first created the campaign — and optionally a date range to limit the analytics data returned.
   * Retrieve a campaign
   */
  async getCampaignsIdRaw(
    requestParameters: GetCampaignsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetCampaignResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getCampaignsId.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.dateRangeStartDate !== undefined) {
      queryParameters['date_range[start_date]'] = requestParameters.dateRangeStartDate
    }

    if (requestParameters.dateRangeEndDate !== undefined) {
      queryParameters['date_range[end_date]'] = requestParameters.dateRangeEndDate
    }

    if (requestParameters.attributionModels) {
      queryParameters['attribution_models'] = requestParameters.attributionModels.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.excludeAnalytics !== undefined) {
      queryParameters['exclude_analytics'] = requestParameters.excludeAnalytics
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/campaigns/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetCampaignResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method returns details about a specific campaign, including some analytics and any ad groups it contains. You need to specify the id of the campaign — which was returned by POST /campaigns when you first created the campaign — and optionally a date range to limit the analytics data returned.
   * Retrieve a campaign
   */
  async getCampaignsId(
    requestParameters: GetCampaignsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetCampaignResponse> {
    const response = await this.getCampaignsIdRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method returns actions about a specific campaign.
   * Retrieve a campaign\'s actions
   */
  async getCampaignsIdActionsRaw(
    requestParameters: GetCampaignsIdActionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetCampaignActionsResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getCampaignsIdActions.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/campaigns/{id}/actions`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetCampaignActionsResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method returns actions about a specific campaign.
   * Retrieve a campaign\'s actions
   */
  async getCampaignsIdActions(
    requestParameters: GetCampaignsIdActionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetCampaignActionsResponse> {
    const response = await this.getCampaignsIdActionsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will return detailed analytics information on a given Campaign. You must specify the metric you wish to use and the date range to filter the information with. The end date can be left empty but the start date must be defined.
   * Returns analytics for a given Campaign
   */
  async getCampaignsIdAnalyticsRaw(
    requestParameters: GetCampaignsIdAnalyticsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetCampaignAnalyticsResponse>> {
    if (
      requestParameters.dateRangeStartDate === null ||
      requestParameters.dateRangeStartDate === undefined
    ) {
      throw new runtime.RequiredError(
        'dateRangeStartDate',
        'Required parameter requestParameters.dateRangeStartDate was null or undefined when calling getCampaignsIdAnalytics.'
      )
    }

    if (requestParameters.metric === null || requestParameters.metric === undefined) {
      throw new runtime.RequiredError(
        'metric',
        'Required parameter requestParameters.metric was null or undefined when calling getCampaignsIdAnalytics.'
      )
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getCampaignsIdAnalytics.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.dateRangeStartDate !== undefined) {
      queryParameters['date_range[start_date]'] = requestParameters.dateRangeStartDate
    }

    if (requestParameters.metric !== undefined) {
      queryParameters['metric'] = requestParameters.metric
    }

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query
    }

    if (requestParameters.dateRangeEndDate !== undefined) {
      queryParameters['date_range[end_date]'] = requestParameters.dateRangeEndDate
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/campaigns/{id}/analytics`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetCampaignAnalyticsResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will return detailed analytics information on a given Campaign. You must specify the metric you wish to use and the date range to filter the information with. The end date can be left empty but the start date must be defined.
   * Returns analytics for a given Campaign
   */
  async getCampaignsIdAnalytics(
    requestParameters: GetCampaignsIdAnalyticsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetCampaignAnalyticsResponse> {
    const response = await this.getCampaignsIdAnalyticsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use GET /campaigns/sum to retrieve the summation of all the ad campaigns returned behind /campaigns. You can optionally search for a specific campaign by name and this method sums only the campaigns with matching names. You can also send filter criteria as parameters and this method sums only the campaigns that match.
   * Retrieve summation of all campaign metrics
   */
  async getCampaignsSumRaw(
    requestParameters: GetCampaignsSumRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetCampaignsSumResponse>> {
    const queryParameters: any = {}

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query
    }

    if (requestParameters.dateRangeStartDate !== undefined) {
      queryParameters['date_range[start_date]'] = requestParameters.dateRangeStartDate
    }

    if (requestParameters.dateRangeEndDate !== undefined) {
      queryParameters['date_range[end_date]'] = requestParameters.dateRangeEndDate
    }

    if (requestParameters.attributionModels) {
      queryParameters['attribution_models'] = requestParameters.attributionModels.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.enabled !== undefined) {
      queryParameters['enabled'] = requestParameters.enabled
    }

    if (requestParameters.status !== undefined) {
      queryParameters['status'] = requestParameters.status
    }

    if (requestParameters.type !== undefined) {
      queryParameters['type'] = requestParameters.type
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/campaigns/sum`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetCampaignsSumResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use GET /campaigns/sum to retrieve the summation of all the ad campaigns returned behind /campaigns. You can optionally search for a specific campaign by name and this method sums only the campaigns with matching names. You can also send filter criteria as parameters and this method sums only the campaigns that match.
   * Retrieve summation of all campaign metrics
   */
  async getCampaignsSum(
    requestParameters: GetCampaignsSumRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetCampaignsSumResponse> {
    const response = await this.getCampaignsSumRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use POST /campaigns to create a new campaign in Instacart Ads. At the minimum, every campaign you create must have a name, start date, budget, and campaign type. This means sending values for the starts_at, name, and budget fields, and sending \"campaign_type\": \"featured_product\" as we currently only support one type of ad in Instacart. This campaign_type is used to represent the sponsored products ad type at Instacart.
   * Create a new campaign
   */
  async postCampaignsRaw(
    requestParameters: PostCampaignsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostCampaignResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/campaigns`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiCampaignsControllerCreateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      PostCampaignResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use POST /campaigns to create a new campaign in Instacart Ads. At the minimum, every campaign you create must have a name, start date, budget, and campaign type. This means sending values for the starts_at, name, and budget fields, and sending \"campaign_type\": \"featured_product\" as we currently only support one type of ad in Instacart. This campaign_type is used to represent the sponsored products ad type at Instacart.
   * Create a new campaign
   */
  async postCampaigns(
    requestParameters: PostCampaignsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostCampaignResponse> {
    const response = await this.postCampaignsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Please note: This endpoint is in a pre-production state. We expect this endpoint to go live during Q1 2022. In the sandbox environment, you may update up to 20 campaigns per request.   Use this method to bulk create new campaign in Instacart Ads. At the minimum, every campaign you create must have a name, start date, budget, and campaign type. This means sending values for the starts_at, name, and budget fields, and sending \"campaign_type\": \"featured_product\" as we currently only support one type of ad in Instacart.
   * Bulk create campaigns
   */
  async postCampaignsBulkRaw(
    requestParameters: PostCampaignsBulkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BulkCampaignsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/campaigns/bulk`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiCampaignsControllerBulkCreateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkCampaignsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Please note: This endpoint is in a pre-production state. We expect this endpoint to go live during Q1 2022. In the sandbox environment, you may update up to 20 campaigns per request.   Use this method to bulk create new campaign in Instacart Ads. At the minimum, every campaign you create must have a name, start date, budget, and campaign type. This means sending values for the starts_at, name, and budget fields, and sending \"campaign_type\": \"featured_product\" as we currently only support one type of ad in Instacart.
   * Bulk create campaigns
   */
  async postCampaignsBulk(
    requestParameters: PostCampaignsBulkRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BulkCampaignsResponse> {
    const response = await this.postCampaignsBulkRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use this method to delete campaigns. You must provide the list of ids of the campaigns to be deleted. The ids can be supplied as an array of strings. Active or scheduled campaigns are currently \'spending\' and cannot be deleted. You can pause spending campaigns by setting enabled to false before deleting. You will not be able to edit, restore, export or view ad groups, sponsored products or keywords of deleted campaigns.
   * Deletes campaigns
   */
  async postCampaignsDeleteRaw(
    requestParameters: PostCampaignsDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DeleteCampaignsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/campaigns/delete`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiCampaignsControllerDeleteInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      DeleteCampaignsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to delete campaigns. You must provide the list of ids of the campaigns to be deleted. The ids can be supplied as an array of strings. Active or scheduled campaigns are currently \'spending\' and cannot be deleted. You can pause spending campaigns by setting enabled to false before deleting. You will not be able to edit, restore, export or view ad groups, sponsored products or keywords of deleted campaigns.
   * Deletes campaigns
   */
  async postCampaignsDelete(
    requestParameters: PostCampaignsDeleteRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DeleteCampaignsResponse> {
    const response = await this.postCampaignsDeleteRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will take a draft campaign and activate it into it\'s correct workflow state.
   * Activates a draft campaign into a valid workflow state
   */
  async postCampaignsIdActivateRaw(
    requestParameters: PostCampaignsIdActivateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetCampaignResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling postCampaignsIdActivate.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/campaigns/{id}/activate`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetCampaignResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will take a draft campaign and activate it into it\'s correct workflow state.
   * Activates a draft campaign into a valid workflow state
   */
  async postCampaignsIdActivate(
    requestParameters: PostCampaignsIdActivateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetCampaignResponse> {
    const response = await this.postCampaignsIdActivateRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will take a draft campaign and activate it into it\'s correct workflow state. When successfully called, it returns the task URL you’ll need to hit to retrieve the status of your task, as well as your request’s ID and other metadata.
   * Activates a draft campaign into a valid worflow state
   */
  async postCampaignsIdActivateAsyncRaw(
    requestParameters: PostCampaignsIdActivateAsyncRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AsyncTaskResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling postCampaignsIdActivateAsync.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/campaigns/{id}/activate_async`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AsyncTaskResponseFromJSON(jsonValue))
  }

  /**
   * This method will take a draft campaign and activate it into it\'s correct workflow state. When successfully called, it returns the task URL you’ll need to hit to retrieve the status of your task, as well as your request’s ID and other metadata.
   * Activates a draft campaign into a valid worflow state
   */
  async postCampaignsIdActivateAsync(
    requestParameters: PostCampaignsIdActivateAsyncRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AsyncTaskResponse> {
    const response = await this.postCampaignsIdActivateAsyncRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will take an existing campaign, of any state, and duplicate it into a new draft campaign, with duplicated ad groups, products, and keywords. When successfully called, it returns the task URL you’ll need to hit to retrieve the status of your task, as well as your request’s ID and other metadata.
   * Duplicates an existing campaign into a draft state
   */
  async postCampaignsIdDuplicateAsyncRaw(
    requestParameters: PostCampaignsIdDuplicateAsyncRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AsyncTaskResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling postCampaignsIdDuplicateAsync.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/campaigns/{id}/duplicate_async`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiCampaignsControllerDuplicateAsyncInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AsyncTaskResponseFromJSON(jsonValue))
  }

  /**
   * This method will take an existing campaign, of any state, and duplicate it into a new draft campaign, with duplicated ad groups, products, and keywords. When successfully called, it returns the task URL you’ll need to hit to retrieve the status of your task, as well as your request’s ID and other metadata.
   * Duplicates an existing campaign into a draft state
   */
  async postCampaignsIdDuplicateAsync(
    requestParameters: PostCampaignsIdDuplicateAsyncRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AsyncTaskResponse> {
    const response = await this.postCampaignsIdDuplicateAsyncRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Please note: This endpoint is in a pre-production state. We expect this endpoint to go live during Q1 2022. In the sandbox environment, you may update up to 20 campaigns per request.   Call this method to update a list of campaigns. You can enable or disable campaigns, update the effective start date (if it has not already started), end date, budget, objective, memo, and purchase order values. You need to specify the id of each campaign you want to update.
   * Bulk update campaigns
   */
  async putCampaignsBulkRaw(
    requestParameters: PutCampaignsBulkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BulkCampaignsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/campaigns/bulk`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ApiCampaignsControllerBulkUpdateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkCampaignsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Please note: This endpoint is in a pre-production state. We expect this endpoint to go live during Q1 2022. In the sandbox environment, you may update up to 20 campaigns per request.   Call this method to update a list of campaigns. You can enable or disable campaigns, update the effective start date (if it has not already started), end date, budget, objective, memo, and purchase order values. You need to specify the id of each campaign you want to update.
   * Bulk update campaigns
   */
  async putCampaignsBulk(
    requestParameters: PutCampaignsBulkRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BulkCampaignsResponse> {
    const response = await this.putCampaignsBulkRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Call this method to update a specific campaign. You can enable or disable the campaign, update its effective start date (if it has not already started), end date, budget, objective, memo, and purchase order value. You need to specify the id of the campaign you want to update — which was returned by POST /campaigns when you first created the campaign — when calling this method.
   * Update a campaign
   */
  async putCampaignsIdRaw(
    requestParameters: PutCampaignsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PutCampaignResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putCampaignsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/campaigns/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ApiCampaignsControllerUpdateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      PutCampaignResponseFromJSON(jsonValue)
    )
  }

  /**
   * Call this method to update a specific campaign. You can enable or disable the campaign, update its effective start date (if it has not already started), end date, budget, objective, memo, and purchase order value. You need to specify the id of the campaign you want to update — which was returned by POST /campaigns when you first created the campaign — when calling this method.
   * Update a campaign
   */
  async putCampaignsId(
    requestParameters: PutCampaignsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PutCampaignResponse> {
    const response = await this.putCampaignsIdRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Updates campaign negative keywords
   */
  async putCampaignsIdNegativeKeywordsRaw(
    requestParameters: PutCampaignsIdNegativeKeywordsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putCampaignsIdNegativeKeywords.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/campaigns/{id}/negative_keywords`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ApiCampaignsControllerNegativeKeywordsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   *
   * Updates campaign negative keywords
   */
  async putCampaignsIdNegativeKeywords(
    requestParameters: PutCampaignsIdNegativeKeywordsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.putCampaignsIdNegativeKeywordsRaw(requestParameters, initOverrides)
  }
}

/**
 * @export
 */
export const GetCampaignsStatusEnum = {
  Active: 'active',
  Deleted: 'deleted',
  Draft: 'draft',
  Ended: 'ended',
  IncompleteAdGroup: 'incomplete_ad_group',
  IncompleteAdGroupProducts: 'incomplete_ad_group_products',
  OutOfBudget: 'out_of_budget',
  Paused: 'paused',
  Scheduled: 'scheduled',
} as const
export type GetCampaignsStatusEnum =
  typeof GetCampaignsStatusEnum[keyof typeof GetCampaignsStatusEnum]
/**
 * @export
 */
export const GetCampaignsTypeEnum = {
  FeaturedProduct: 'featured_product',
  Coupon: 'coupon',
} as const
export type GetCampaignsTypeEnum = typeof GetCampaignsTypeEnum[keyof typeof GetCampaignsTypeEnum]
/**
 * @export
 */
export const GetCampaignsSortByEnum = {
  NameAsc: 'name_asc',
  NameDesc: 'name_desc',
  DraftAsc: 'draft_asc',
  DraftDesc: 'draft_desc',
  StartsAtAsc: 'starts_at_asc',
  StartsAtDesc: 'starts_at_desc',
  EndsAtAsc: 'ends_at_asc',
  EndsAtDesc: 'ends_at_desc',
  BudgetCentsAsc: 'budget_cents_asc',
  BudgetCentsDesc: 'budget_cents_desc',
  BudgetTypeAsc: 'budget_type_asc',
  BudgetTypeDesc: 'budget_type_desc',
  StatusAsc: 'status_asc',
  StatusDesc: 'status_desc',
  ImpressionsAsc: 'impressions_asc',
  ImpressionsDesc: 'impressions_desc',
  ClicksAsc: 'clicks_asc',
  ClicksDesc: 'clicks_desc',
  SpendAsc: 'spend_asc',
  SpendDesc: 'spend_desc',
  AttributedSalesAsc: 'attributed_sales_asc',
  AttributedSalesDesc: 'attributed_sales_desc',
  AttributedQuantitiesAsc: 'attributed_quantities_asc',
  AttributedQuantitiesDesc: 'attributed_quantities_desc',
  CtrAsc: 'ctr_asc',
  CtrDesc: 'ctr_desc',
  CpcAsc: 'cpc_asc',
  CpcDesc: 'cpc_desc',
  RoasAsc: 'roas_asc',
  RoasDesc: 'roas_desc',
  EnabledAsc: 'enabled_asc',
  EnabledDesc: 'enabled_desc',
  NtbAttributedSalesAsc: 'ntb_attributed_sales_asc',
  NtbAttributedSalesDesc: 'ntb_attributed_sales_desc',
  PercentNtbAttributedSalesAsc: 'percent_ntb_attributed_sales_asc',
  PercentNtbAttributedSalesDesc: 'percent_ntb_attributed_sales_desc',
} as const
export type GetCampaignsSortByEnum =
  typeof GetCampaignsSortByEnum[keyof typeof GetCampaignsSortByEnum]
/**
 * @export
 */
export const GetCampaignsIdAnalyticsMetricEnum = {
  Spend: 'spend',
  Clicks: 'clicks',
  Impressions: 'impressions',
  Ctr: 'ctr',
  Roas: 'roas',
  NtbRoas: 'ntb_roas',
  NtbSpend: 'ntb_spend',
  AttributedSales: 'attributed_sales',
  NtbAttributedSales: 'ntb_attributed_sales',
  AttributedQuantities: 'attributed_quantities',
} as const
export type GetCampaignsIdAnalyticsMetricEnum =
  typeof GetCampaignsIdAnalyticsMetricEnum[keyof typeof GetCampaignsIdAnalyticsMetricEnum]
/**
 * @export
 */
export const GetCampaignsSumStatusEnum = {
  Active: 'active',
  Deleted: 'deleted',
  Draft: 'draft',
  Ended: 'ended',
  IncompleteAdGroup: 'incomplete_ad_group',
  IncompleteAdGroupProducts: 'incomplete_ad_group_products',
  OutOfBudget: 'out_of_budget',
  Paused: 'paused',
  Scheduled: 'scheduled',
} as const
export type GetCampaignsSumStatusEnum =
  typeof GetCampaignsSumStatusEnum[keyof typeof GetCampaignsSumStatusEnum]
/**
 * @export
 */
export const GetCampaignsSumTypeEnum = {
  FeaturedProduct: 'featured_product',
  Coupon: 'coupon',
} as const
export type GetCampaignsSumTypeEnum =
  typeof GetCampaignsSumTypeEnum[keyof typeof GetCampaignsSumTypeEnum]
