// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentText } from './GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentText'
import {
  GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentTextFromJSON,
  GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentTextFromJSONTyped,
  GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentTextToJSON,
} from './GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentText'
import type { GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentTitle } from './GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentTitle'
import {
  GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentTitleFromJSON,
  GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentTitleFromJSONTyped,
  GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentTitleToJSON,
} from './GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentTitle'

/**
 *
 * @export
 * @interface GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContent
 */
export interface GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContent {
  /**
   *
   * @type {GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentTitle}
   * @memberof GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContent
   */
  title?: GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentTitle
  /**
   *
   * @type {GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentText}
   * @memberof GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContent
   */
  text?: GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentText
}

/**
 * Check if a given object implements the GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContent interface.
 */
export function instanceOfGetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContent(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentFromJSON(
  json: any
): GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContent {
  return GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentFromJSONTyped(json, false)
}

export function GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: !exists(json, 'title')
      ? undefined
      : GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentTitleFromJSON(json['title']),
    text: !exists(json, 'text')
      ? undefined
      : GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentTextFromJSON(json['text']),
  }
}

export function GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentToJSON(
  value?: GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContent | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentTitleToJSON(value.title),
    text: GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsContentTextToJSON(value.text),
  }
}
