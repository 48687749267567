// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalytics } from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalytics'
import {
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsFromJSON,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsFromJSONTyped,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsToJSON,
} from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalytics'
import type { GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesBidRecs } from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesBidRecs'
import {
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesBidRecsFromJSON,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesBidRecsFromJSONTyped,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesBidRecsToJSON,
} from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesBidRecs'
import type { GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesMeta } from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesMeta'
import {
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesMetaFromJSON,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesMetaFromJSONTyped,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesMetaToJSON,
} from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesMeta'
import type { GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesSupplementaryStatuses } from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesSupplementaryStatuses'
import {
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesSupplementaryStatusesFromJSON,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesSupplementaryStatusesFromJSONTyped,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesSupplementaryStatusesToJSON,
} from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesSupplementaryStatuses'
import type { GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesUserTargeting } from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesUserTargeting'
import {
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesUserTargetingFromJSON,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesUserTargetingFromJSONTyped,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesUserTargetingToJSON,
} from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesUserTargeting'

/**
 *
 * @export
 * @interface GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes
 */
export interface GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes {
  /**
   * A descriptive name for the ad group.
   * @type {string}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes
   */
  name: string
  /**
   * Set to true to create an active ad group. Ads are only run for active ad groups with at least one product which belong to active, running campaigns. Set to false to create an inactive ad group.
   * @type {boolean}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes
   */
  enabled: boolean
  /**
   * The ad group's status. Can be either campaign_ended, campaign_out_of_budget, campaign_paused, campaign_draft, paused, scheduled, active, or unknown.
   * @type {string}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes
   */
  adGroupStatus: GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAdGroupStatusEnum
  /**
   * The placement targeting type for this ad group. Currently supported values: ['default', 'impulse'].
   * @type {string}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes
   */
  targetingType?: GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesTargetingTypeEnum
  /**
   * The maximum amount, in dollars, you want to pay per click for advertised products in this ad group.
   * May be `null` for campaigns with `optimized_bidding_enabled = true` and decimal otherwise
   * @type {number}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes
   */
  defaultBid?: number
  /**
   *
   * @type {GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalytics}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes
   */
  analytics?: GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalytics
  /**
   *
   * @type {GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesMeta}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes
   */
  meta?: GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesMeta
  /**
   *
   * @type {GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesBidRecs}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes
   */
  bidRecs?: GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesBidRecs
  /**
   * Total number of product count for the ad group.
   * @type {number}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes
   */
  productCount?: number
  /**
   * Set to true to enable branded keywords opt-out
   * @type {boolean}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes
   */
  brandedKeywordsOptOutEnabled?: boolean
  /**
   *
   * @type {GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesUserTargeting}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes
   */
  userTargeting?: GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesUserTargeting
  /**
   *
   * @type {GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesSupplementaryStatuses}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes
   */
  supplementaryStatuses?: GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesSupplementaryStatuses
}

/**
 * @export
 */
export const GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAdGroupStatusEnum =
  {
    IncompleteAdGroupProducts: 'incomplete_ad_group_products',
    CampaignEnded: 'campaign_ended',
    CampaignOutOfBudget: 'campaign_out_of_budget',
    CampaignPaused: 'campaign_paused',
    CampaignDraft: 'campaign_draft',
    Paused: 'paused',
    Scheduled: 'scheduled',
    Active: 'active',
    Unknown: 'unknown',
  } as const
export type GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAdGroupStatusEnum =
  typeof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAdGroupStatusEnum[keyof typeof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAdGroupStatusEnum]

/**
 * @export
 */
export const GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesTargetingTypeEnum =
  {
    Impulse: 'impulse',
    Default: 'default',
  } as const
export type GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesTargetingTypeEnum =
  typeof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesTargetingTypeEnum[keyof typeof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesTargetingTypeEnum]

/**
 * Check if a given object implements the GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes interface.
 */
export function instanceOfGetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'adGroupStatus' in value

  return isInstance
}

export function GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesFromJSON(
  json: any
): GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes {
  return GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesFromJSONTyped(
    json,
    false
  )
}

export function GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    enabled: json['enabled'],
    adGroupStatus: json['ad_group_status'],
    targetingType: !exists(json, 'targeting_type') ? undefined : json['targeting_type'],
    defaultBid: !exists(json, 'default_bid') ? undefined : json['default_bid'],
    analytics: !exists(json, 'analytics')
      ? undefined
      : GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsFromJSON(
          json['analytics']
        ),
    meta: !exists(json, 'meta')
      ? undefined
      : GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesMetaFromJSON(
          json['meta']
        ),
    bidRecs: !exists(json, 'bid_recs')
      ? undefined
      : GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesBidRecsFromJSON(
          json['bid_recs']
        ),
    productCount: !exists(json, 'product_count') ? undefined : json['product_count'],
    brandedKeywordsOptOutEnabled: !exists(json, 'branded_keywords_opt_out_enabled')
      ? undefined
      : json['branded_keywords_opt_out_enabled'],
    userTargeting: !exists(json, 'user_targeting')
      ? undefined
      : GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesUserTargetingFromJSON(
          json['user_targeting']
        ),
    supplementaryStatuses: !exists(json, 'supplementary_statuses')
      ? undefined
      : GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesSupplementaryStatusesFromJSON(
          json['supplementary_statuses']
        ),
  }
}

export function GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesToJSON(
  value?: GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    enabled: value.enabled,
    ad_group_status: value.adGroupStatus,
    targeting_type: value.targetingType,
    default_bid: value.defaultBid,
    analytics:
      GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsToJSON(
        value.analytics
      ),
    meta: GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesMetaToJSON(
      value.meta
    ),
    bid_recs:
      GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesBidRecsToJSON(
        value.bidRecs
      ),
    product_count: value.productCount,
    branded_keywords_opt_out_enabled: value.brandedKeywordsOptOutEnabled,
    user_targeting:
      GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesUserTargetingToJSON(
        value.userTargeting
      ),
    supplementary_statuses:
      GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesSupplementaryStatusesToJSON(
        value.supplementaryStatuses
      ),
  }
}
