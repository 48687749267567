// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataBudgetExhaustionTime } from './GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataBudgetExhaustionTime'
import {
  GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataBudgetExhaustionTimeFromJSON,
  GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataBudgetExhaustionTimeFromJSONTyped,
  GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataBudgetExhaustionTimeToJSON,
} from './GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataBudgetExhaustionTime'
import type { GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressions } from './GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressions'
import {
  GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressionsFromJSON,
  GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressionsFromJSONTyped,
  GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressionsToJSON,
} from './GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressions'
import type { GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostSales } from './GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostSales'
import {
  GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostSalesFromJSON,
  GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostSalesFromJSONTyped,
  GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostSalesToJSON,
} from './GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostSales'

/**
 *
 * @export
 * @interface GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartData
 */
export interface GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartData {
  /**
   *
   * @type {Array<GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressions>}
   * @memberof GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartData
   */
  estimatedLostImpressions: Array<GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressions>
  /**
   *
   * @type {Array<GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataBudgetExhaustionTime>}
   * @memberof GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartData
   */
  budgetExhaustionTime: Array<GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataBudgetExhaustionTime>
  /**
   *
   * @type {Array<GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostSales>}
   * @memberof GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartData
   */
  estimatedLostSales?: Array<GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostSales>
}

/**
 * Check if a given object implements the GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartData interface.
 */
export function instanceOfGetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'estimatedLostImpressions' in value
  isInstance = isInstance && 'budgetExhaustionTime' in value

  return isInstance
}

export function GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataFromJSON(
  json: any
): GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartData {
  return GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataFromJSONTyped(
    json,
    false
  )
}

export function GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    estimatedLostImpressions: (json['estimated_lost_impressions'] as Array<any>).map(
      GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressionsFromJSON
    ),
    budgetExhaustionTime: (json['budget_exhaustion_time'] as Array<any>).map(
      GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataBudgetExhaustionTimeFromJSON
    ),
    estimatedLostSales: !exists(json, 'estimated_lost_sales')
      ? undefined
      : (json['estimated_lost_sales'] as Array<any>).map(
          GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostSalesFromJSON
        ),
  }
}

export function GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataToJSON(
  value?: GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    estimated_lost_impressions: (value.estimatedLostImpressions as Array<any>).map(
      GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressionsToJSON
    ),
    budget_exhaustion_time: (value.budgetExhaustionTime as Array<any>).map(
      GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataBudgetExhaustionTimeToJSON
    ),
    estimated_lost_sales:
      value.estimatedLostSales === undefined
        ? undefined
        : (value.estimatedLostSales as Array<any>).map(
            GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostSalesToJSON
          ),
  }
}
