// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroup } from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroup'
import {
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupFromJSON,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupFromJSONTyped,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupToJSON,
} from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroup'

/**
 *
 * @export
 * @interface GetCampaignsIdActionsParamDataDefaultBidActionsAttributes
 */
export interface GetCampaignsIdActionsParamDataDefaultBidActionsAttributes {
  /**
   * Notifiable Event Status
   * @type {string}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributes
   */
  status: string
  /**
   *
   * @type {GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroup}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributes
   */
  adGroup: GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroup
}

/**
 * Check if a given object implements the GetCampaignsIdActionsParamDataDefaultBidActionsAttributes interface.
 */
export function instanceOfGetCampaignsIdActionsParamDataDefaultBidActionsAttributes(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'adGroup' in value

  return isInstance
}

export function GetCampaignsIdActionsParamDataDefaultBidActionsAttributesFromJSON(
  json: any
): GetCampaignsIdActionsParamDataDefaultBidActionsAttributes {
  return GetCampaignsIdActionsParamDataDefaultBidActionsAttributesFromJSONTyped(json, false)
}

export function GetCampaignsIdActionsParamDataDefaultBidActionsAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignsIdActionsParamDataDefaultBidActionsAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    adGroup: GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupFromJSON(
      json['ad_group']
    ),
  }
}

export function GetCampaignsIdActionsParamDataDefaultBidActionsAttributesToJSON(
  value?: GetCampaignsIdActionsParamDataDefaultBidActionsAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    ad_group: GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupToJSON(value.adGroup),
  }
}
