// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetCampaignsResponseData } from './GetCampaignsResponseData'
import {
  GetCampaignsResponseDataFromJSON,
  GetCampaignsResponseDataFromJSONTyped,
  GetCampaignsResponseDataToJSON,
} from './GetCampaignsResponseData'
import type { GetCampaignsResponseMeta } from './GetCampaignsResponseMeta'
import {
  GetCampaignsResponseMetaFromJSON,
  GetCampaignsResponseMetaFromJSONTyped,
  GetCampaignsResponseMetaToJSON,
} from './GetCampaignsResponseMeta'

/**
 *
 * @export
 * @interface GetCampaignsResponse
 */
export interface GetCampaignsResponse {
  /**
   *
   * @type {Array<GetCampaignsResponseData>}
   * @memberof GetCampaignsResponse
   */
  data: Array<GetCampaignsResponseData>
  /**
   *
   * @type {GetCampaignsResponseMeta}
   * @memberof GetCampaignsResponse
   */
  meta: GetCampaignsResponseMeta
}

/**
 * Check if a given object implements the GetCampaignsResponse interface.
 */
export function instanceOfGetCampaignsResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function GetCampaignsResponseFromJSON(json: any): GetCampaignsResponse {
  return GetCampaignsResponseFromJSONTyped(json, false)
}

export function GetCampaignsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(GetCampaignsResponseDataFromJSON),
    meta: GetCampaignsResponseMetaFromJSON(json['meta']),
  }
}

export function GetCampaignsResponseToJSON(value?: GetCampaignsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(GetCampaignsResponseDataToJSON),
    meta: GetCampaignsResponseMetaToJSON(value.meta),
  }
}
