import { MutableRefObject } from 'react'
import { boldSpanFormattingHelper } from 'common/intlUtils'
import { Keyword } from 'service/types'
import { ConfirmBidModalData } from './types'
import { getEnv } from './utils'

export const getThreshold = (envVariable: string) => {
  const val = getEnv(envVariable)
  if (!val) return undefined

  const threshold = Number(val)
  return Number.isNaN(threshold) ? undefined : threshold
}

export const getKeywordBidRecThreshold = (isBidRecommendationsThresholdEnabled: boolean) =>
  isBidRecommendationsThresholdEnabled ? getThreshold('ADS_BID_REC_KW_THRESHOLD') : undefined

export const getDefaultBidRecThreshold = (isBidRecommendationsThresholdEnabled: boolean) =>
  isBidRecommendationsThresholdEnabled ? getThreshold('ADS_BID_REC_DEFAULT_THRESHOLD') : undefined

export const getBidMaxThreshold = () => getEnv('MAX_CPC_BID')

export const getConfirmBidThresholdPrimary = () =>
  getThreshold('ADS_BID_REC_CONFIRM_BID_THRESHOLD_PRIMARY') || 20
export const getConfirmBidThresholdSecondary = () =>
  getThreshold('ADS_BID_REC_CONFIRM_BID_THRESHOLD_SECONDARY') || 40

/**
 * Checks if suggested value is over the Bid Recommendation Threshold
 *
 * @param value
 */
export const isOverKeywordBidRecTreshold = (
  value: number,
  isBidRecommendationsThresholdEnabled: boolean
) => {
  const threshold = getKeywordBidRecThreshold(isBidRecommendationsThresholdEnabled)
  return value && threshold && value >= threshold
}

export const isOverDefaultBidRecTreshold = (
  value: number,
  isBidRecommendationsThresholdEnabled: boolean
) => {
  const threshold = getDefaultBidRecThreshold(isBidRecommendationsThresholdEnabled)
  return value && threshold && value >= threshold
}

/**
 * Returns the suggested value if under the Bid Recommendation Threshold
 * Otherwise, it returns the Bid Recommendation Threshold value
 *
 * @param value
 */
export const getSuggestedKeywordBidValue = (
  value: number,
  isBidRecommendationsThresholdEnabled: boolean
) => {
  const threshold = getKeywordBidRecThreshold(isBidRecommendationsThresholdEnabled)
  return threshold && isOverKeywordBidRecTreshold(value, isBidRecommendationsThresholdEnabled)
    ? threshold
    : value
}

export const getSuggestedDefaultBidValue = (
  value: number,
  isBidRecommendationsThresholdEnabled: boolean
) => {
  const threshold = getDefaultBidRecThreshold(isBidRecommendationsThresholdEnabled)
  return threshold && isOverDefaultBidRecTreshold(value, isBidRecommendationsThresholdEnabled)
    ? threshold
    : value
}

export interface BidThresholdCheckResult {
  isThresholdHit: boolean
  threshold?: number
}

/**
 * Checks a bid against various thresholds and returns
 * whether or not thresholds are hit.
 * Thresholds to validate:
 * 1. Previous bid < PRIMARY_THRESHOLD && updated bid < PRIMARY_THRESHOLD
 *      -> not hit = no confirmation required
 * 2. Previous bid < PRIMARY_THRESHOLD && updated bid >= PRIMARY_THRESHOLD
 *      -> hit = confirmation required
 * 3. PRIMARY_THRESHOLD < Previous bid < SECONDARY_THRESHOLD && updated bid < SECONDARY_THRESHOLD
 *      -> not hit = no confirmation required
 * 4. Previous bid < SECONDARY_THRESHOLD && updated bid >= SECONDARY_THRESHOLD
 *      -> hit = confirmation required
 */
export const checkBidThresholds = (
  previousBid?: number,
  updatedBid?: number
): BidThresholdCheckResult => {
  const PRIMARY_THRESHOLD = getConfirmBidThresholdPrimary()
  const SECONDARY_THRESHOLD = getConfirmBidThresholdSecondary()

  if (!previousBid || !updatedBid) {
    return {
      isThresholdHit: false,
    }
  }

  if (previousBid < SECONDARY_THRESHOLD && updatedBid >= SECONDARY_THRESHOLD) {
    return { isThresholdHit: true, threshold: SECONDARY_THRESHOLD }
  }

  if (previousBid < PRIMARY_THRESHOLD && updatedBid >= PRIMARY_THRESHOLD) {
    return { isThresholdHit: true, threshold: PRIMARY_THRESHOLD }
  }

  return { isThresholdHit: false }
}

export interface ValidateKeywordBidsOptions {
  isBidThresholdsConfirmationModalEnabled: boolean
  resolveBidsValidatedPromise: MutableRefObject<(validated: boolean) => void>
  setConfirmBidModalData(modalData: ConfirmBidModalData): void
  setIsConfirmingBid(isConfirmingBid: boolean): void
}

export interface ValidateCpcBidOptions extends ValidateKeywordBidsOptions {
  previousBidValue?: number
  updatedBidValue?: number
  adGroupName?: string
  adGroupId?: string
  isCampaignLevel?: boolean
}

// Performs validations on keyword bid values
// Returning a promise which is to be resolved when bids have been confirmed.
export const validateKeywordBids = (
  keywords: Keyword[],
  updatedBidValue: number,
  options: ValidateKeywordBidsOptions
): Promise<boolean> => {
  const {
    isBidThresholdsConfirmationModalEnabled,
    resolveBidsValidatedPromise,
    setConfirmBidModalData,
    setIsConfirmingBid,
  } = options
  if (!isBidThresholdsConfirmationModalEnabled) {
    return Promise.resolve(true)
  }

  const bidsValidatedPromise: Promise<boolean> = new Promise(resolve => {
    resolveBidsValidatedPromise.current = resolve
  })

  let modalData: ConfirmBidModalData

  if (keywords.length === 0) {
    return Promise.resolve(true)
  }

  if (keywords.length === 1) {
    const { isThresholdHit, threshold } = checkBidThresholds(
      keywords[0].attributes.cpcBid,
      updatedBidValue
    )

    if (!isThresholdHit) {
      return Promise.resolve(true)
    }

    modalData = {
      keywords,
      titleId: 'components.bidThresholdConfirmationModal.changeKeywordBid.title',
      confirmButtonMessageId: 'common.applyBid',
      messageId: 'components.bidThresholdConfirmationModal.singleBid.body',
      updatedBidValue,
      messageValues: {
        ...boldSpanFormattingHelper,
        keyword: keywords[0].attributes.keyword,
        threshold: `${threshold?.toFixed(2)}`,
      },
    }
  } else {
    // Find the max threshold that was hit (if any)
    const thresholdHitResults = keywords.map(keyword => {
      return checkBidThresholds(keyword.attributes.cpcBid, updatedBidValue)
    })
    const maxThresholdHit = thresholdHitResults
      .filter(result => result.isThresholdHit)
      .reduce((maxThreshold, result) => {
        if (result.threshold && result.threshold > maxThreshold) {
          // eslint-disable-next-line no-param-reassign
          maxThreshold = result.threshold
        }
        return maxThreshold
      }, 0)

    if (maxThresholdHit === 0) {
      return Promise.resolve(true)
    }

    modalData = {
      keywords,
      updatedBidValue,
      titleId: 'components.bidThresholdConfirmationModal.changeKeywordBid.title',
      confirmButtonMessageId: 'common.applyBid',
      messageId: 'components.bidThresholdConfirmationModal.multipleBids.body',
      messageValues: {
        ...boldSpanFormattingHelper,
        numKeywords: keywords.length,
        threshold: `${maxThresholdHit?.toFixed(2)}`,
      },
    }
  }

  setConfirmBidModalData(modalData)
  setIsConfirmingBid(true)

  return bidsValidatedPromise
}

// Performs validations on cpc bid values
// Returning a promise which is to be resolved when bids have been confirmed.
export const validateCpcBid = (options: ValidateCpcBidOptions): Promise<boolean> => {
  const {
    previousBidValue,
    updatedBidValue,
    isBidThresholdsConfirmationModalEnabled,
    resolveBidsValidatedPromise,
    setConfirmBidModalData,
    setIsConfirmingBid,
    adGroupName,
    adGroupId,
    isCampaignLevel,
  } = options
  if (!isBidThresholdsConfirmationModalEnabled) {
    return Promise.resolve(true)
  }

  if (!previousBidValue || !updatedBidValue) {
    return Promise.resolve(true)
  }

  const bidsValidatedPromise: Promise<boolean> = new Promise(resolve => {
    resolveBidsValidatedPromise.current = resolve
  })

  let modalData: ConfirmBidModalData

  const { isThresholdHit, threshold } = checkBidThresholds(previousBidValue, updatedBidValue)

  if (!isThresholdHit) {
    return Promise.resolve(true)
  }

  if (isCampaignLevel) {
    modalData = {
      keywords: [],
      titleId: 'components.bidThresholdConfirmationModal.changeDefaultCpcBid.title',
      confirmButtonMessageId: 'common.updateBid',
      messageId: 'components.bidThresholdConfirmationModal.campaignLevelCpcBid.body',
      updatedBidValue,
      adGroupId,
      messageValues: {
        ...boldSpanFormattingHelper,
        adGroupName,
        threshold: `${threshold?.toFixed(2)}`,
      },
    }
  } else {
    modalData = {
      keywords: [],
      updatedBidValue,
      adGroupId,
      titleId: 'components.bidThresholdConfirmationModal.changeDefaultCpcBid.title',
      confirmButtonMessageId: 'common.updateBid',
      messageId: 'components.bidThresholdConfirmationModal.adGroupLevelCpcBid.body',
      messageValues: {
        ...boldSpanFormattingHelper,
        threshold: `${threshold?.toFixed(2)}`,
      },
    }
  }

  setConfirmBidModalData(modalData)
  setIsConfirmingBid(true)

  return bidsValidatedPromise
}
