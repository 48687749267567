// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiCampaignsControllerCreateInputCampaignAutomatedBiddingParameters } from './ApiCampaignsControllerCreateInputCampaignAutomatedBiddingParameters'
import {
  ApiCampaignsControllerCreateInputCampaignAutomatedBiddingParametersFromJSON,
  ApiCampaignsControllerCreateInputCampaignAutomatedBiddingParametersFromJSONTyped,
  ApiCampaignsControllerCreateInputCampaignAutomatedBiddingParametersToJSON,
} from './ApiCampaignsControllerCreateInputCampaignAutomatedBiddingParameters'
import type { ApiCampaignsControllerCreateInputCampaignOptimizedBiddingParameters } from './ApiCampaignsControllerCreateInputCampaignOptimizedBiddingParameters'
import {
  ApiCampaignsControllerCreateInputCampaignOptimizedBiddingParametersFromJSON,
  ApiCampaignsControllerCreateInputCampaignOptimizedBiddingParametersFromJSONTyped,
  ApiCampaignsControllerCreateInputCampaignOptimizedBiddingParametersToJSON,
} from './ApiCampaignsControllerCreateInputCampaignOptimizedBiddingParameters'

/**
 *
 * @export
 * @interface ApiCampaignsControllerCreateInputCampaign
 */
export interface ApiCampaignsControllerCreateInputCampaign {
  /**
   * Set to true to activate the campaign. You will bid according to the details of the ad groups contained within this campaign. Set to false and the campaign is not active.
   * @type {boolean}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  enabled?: boolean
  /**
   * A descriptive name for the campaign.
   * @type {string}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  name: string
  /**
   * The total budget for the ads campaign, expressed in dollars. When the campaign has spent this budget, it automatically ends.
   * @type {number}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  budget?: number
  /**
   * Send "featured_product" for this field. We currently only support one type of ad campaign.
   * @type {string}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  campaignType: ApiCampaignsControllerCreateInputCampaignCampaignTypeEnum
  /**
   * For reference only. You can specify the objective of the campaign for future reference. Send one of the following values: "sales", "market_share", "consideration", "awareness", "other".
   * @type {string}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  objective?: ApiCampaignsControllerCreateInputCampaignObjectiveEnum
  /**
   * If you sent "other" for the objective field, send a brief phrase describing the campaign’s objective. Do not send this parameter if you are not sending a value for the objective field or if you sent a value that is not "other".
   * @type {string}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  objectiveCustom?: string
  /**
   * If you want to include a memo line on the invoices Instacart generates for this ad campaign, specify it in this field.
   * @type {string}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  memo?: string
  /**
   * If you want to include a purchase order number on the invoices Instacart generates for this ad campaign, specify it in this field.
   * @type {string}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  purchaseOrder?: string
  /**
   * For reference only. Specifies how you want Instacart to bill you for the campaign. If you want Instacart to bill on a per purchase order, specify "purchase_order". If you want us to bill to your account, specify "account".
   * @type {string}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  paymentType?: ApiCampaignsControllerCreateInputCampaignPaymentTypeEnum
  /**
   * The budgeting strategy for the campaign. One of "daily_budget", "max_impressions".
   * @type {string}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  budgetType: ApiCampaignsControllerCreateInputCampaignBudgetTypeEnum
  /**
   * The daily budget for a v2 campaign, expressed in dollars. If `optimized_bidding_enabled` set to `true`, the minimum budget required is $20.
   * @type {number}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  targetDailyBudget?: number
  /**
   * Override for default account-level bill payer. Agency, advertiser or retailer to be billed for the campaign.
   * @type {string}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  billPayer?: ApiCampaignsControllerCreateInputCampaignBillPayerEnum
  /**
   * Enables automated bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  automatedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  automatedBiddingGoal?: ApiCampaignsControllerCreateInputCampaignAutomatedBiddingGoalEnum
  /**
   *
   * @type {ApiCampaignsControllerCreateInputCampaignAutomatedBiddingParameters}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  automatedBiddingParameters?: ApiCampaignsControllerCreateInputCampaignAutomatedBiddingParameters
  /**
   * The campaign’s goal
   * @type {string}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  goal?: string
  /**
   * The campaign’s goal format
   * @type {string}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  goalFormat?: string
  /**
   * Enables optimized bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  optimizedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  optimizedBiddingGoal?: ApiCampaignsControllerCreateInputCampaignOptimizedBiddingGoalEnum
  /**
   *
   * @type {ApiCampaignsControllerCreateInputCampaignOptimizedBiddingParameters}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  optimizedBiddingParameters?: ApiCampaignsControllerCreateInputCampaignOptimizedBiddingParameters
  /**
   * The campaign’s start date in the ‘YYYY-MM-DD’ format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  startsAt?: string
  /**
   * The campaign’s effective end date in the ‘YYYY-MM-DD’ format. Campaigns end at midnight, PST, on the specified date. Do not send and the campaign only stops when the campaign’s budget has been spent.
   * @type {string}
   * @memberof ApiCampaignsControllerCreateInputCampaign
   */
  endsAt?: string
}

/**
 * @export
 */
export const ApiCampaignsControllerCreateInputCampaignCampaignTypeEnum = {
  FeaturedProduct: 'featured_product',
} as const
export type ApiCampaignsControllerCreateInputCampaignCampaignTypeEnum =
  typeof ApiCampaignsControllerCreateInputCampaignCampaignTypeEnum[keyof typeof ApiCampaignsControllerCreateInputCampaignCampaignTypeEnum]

/**
 * @export
 */
export const ApiCampaignsControllerCreateInputCampaignObjectiveEnum = {
  Sales: 'sales',
  MarketShare: 'market_share',
  Consideration: 'consideration',
  Awareness: 'awareness',
  Other: 'other',
} as const
export type ApiCampaignsControllerCreateInputCampaignObjectiveEnum =
  typeof ApiCampaignsControllerCreateInputCampaignObjectiveEnum[keyof typeof ApiCampaignsControllerCreateInputCampaignObjectiveEnum]

/**
 * @export
 */
export const ApiCampaignsControllerCreateInputCampaignPaymentTypeEnum = {
  Account: 'account',
  PurchaseOrder: 'purchase_order',
} as const
export type ApiCampaignsControllerCreateInputCampaignPaymentTypeEnum =
  typeof ApiCampaignsControllerCreateInputCampaignPaymentTypeEnum[keyof typeof ApiCampaignsControllerCreateInputCampaignPaymentTypeEnum]

/**
 * @export
 */
export const ApiCampaignsControllerCreateInputCampaignBudgetTypeEnum = {
  DailyBudget: 'daily_budget',
  MaxImpressions: 'max_impressions',
} as const
export type ApiCampaignsControllerCreateInputCampaignBudgetTypeEnum =
  typeof ApiCampaignsControllerCreateInputCampaignBudgetTypeEnum[keyof typeof ApiCampaignsControllerCreateInputCampaignBudgetTypeEnum]

/**
 * @export
 */
export const ApiCampaignsControllerCreateInputCampaignBillPayerEnum = {
  Agency: 'agency',
  Advertiser: 'advertiser',
  Retailer: 'retailer',
} as const
export type ApiCampaignsControllerCreateInputCampaignBillPayerEnum =
  typeof ApiCampaignsControllerCreateInputCampaignBillPayerEnum[keyof typeof ApiCampaignsControllerCreateInputCampaignBillPayerEnum]

/**
 * @export
 */
export const ApiCampaignsControllerCreateInputCampaignAutomatedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type ApiCampaignsControllerCreateInputCampaignAutomatedBiddingGoalEnum =
  typeof ApiCampaignsControllerCreateInputCampaignAutomatedBiddingGoalEnum[keyof typeof ApiCampaignsControllerCreateInputCampaignAutomatedBiddingGoalEnum]

/**
 * @export
 */
export const ApiCampaignsControllerCreateInputCampaignOptimizedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type ApiCampaignsControllerCreateInputCampaignOptimizedBiddingGoalEnum =
  typeof ApiCampaignsControllerCreateInputCampaignOptimizedBiddingGoalEnum[keyof typeof ApiCampaignsControllerCreateInputCampaignOptimizedBiddingGoalEnum]

/**
 * Check if a given object implements the ApiCampaignsControllerCreateInputCampaign interface.
 */
export function instanceOfApiCampaignsControllerCreateInputCampaign(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'campaignType' in value
  isInstance = isInstance && 'budgetType' in value

  return isInstance
}

export function ApiCampaignsControllerCreateInputCampaignFromJSON(
  json: any
): ApiCampaignsControllerCreateInputCampaign {
  return ApiCampaignsControllerCreateInputCampaignFromJSONTyped(json, false)
}

export function ApiCampaignsControllerCreateInputCampaignFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiCampaignsControllerCreateInputCampaign {
  if (json === undefined || json === null) {
    return json
  }
  return {
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
    name: json['name'],
    budget: !exists(json, 'budget') ? undefined : json['budget'],
    campaignType: json['campaign_type'],
    objective: !exists(json, 'objective') ? undefined : json['objective'],
    objectiveCustom: !exists(json, 'objective_custom') ? undefined : json['objective_custom'],
    memo: !exists(json, 'memo') ? undefined : json['memo'],
    purchaseOrder: !exists(json, 'purchase_order') ? undefined : json['purchase_order'],
    paymentType: !exists(json, 'payment_type') ? undefined : json['payment_type'],
    budgetType: json['budget_type'],
    targetDailyBudget: !exists(json, 'target_daily_budget')
      ? undefined
      : json['target_daily_budget'],
    billPayer: !exists(json, 'bill_payer') ? undefined : json['bill_payer'],
    automatedBiddingEnabled: !exists(json, 'automated_bidding_enabled')
      ? undefined
      : json['automated_bidding_enabled'],
    automatedBiddingGoal: !exists(json, 'automated_bidding_goal')
      ? undefined
      : json['automated_bidding_goal'],
    automatedBiddingParameters: !exists(json, 'automated_bidding_parameters')
      ? undefined
      : ApiCampaignsControllerCreateInputCampaignAutomatedBiddingParametersFromJSON(
          json['automated_bidding_parameters']
        ),
    goal: !exists(json, 'goal') ? undefined : json['goal'],
    goalFormat: !exists(json, 'goal_format') ? undefined : json['goal_format'],
    optimizedBiddingEnabled: !exists(json, 'optimized_bidding_enabled')
      ? undefined
      : json['optimized_bidding_enabled'],
    optimizedBiddingGoal: !exists(json, 'optimized_bidding_goal')
      ? undefined
      : json['optimized_bidding_goal'],
    optimizedBiddingParameters: !exists(json, 'optimized_bidding_parameters')
      ? undefined
      : ApiCampaignsControllerCreateInputCampaignOptimizedBiddingParametersFromJSON(
          json['optimized_bidding_parameters']
        ),
    startsAt: !exists(json, 'starts_at') ? undefined : json['starts_at'],
    endsAt: !exists(json, 'ends_at') ? undefined : json['ends_at'],
  }
}

export function ApiCampaignsControllerCreateInputCampaignToJSON(
  value?: ApiCampaignsControllerCreateInputCampaign | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    enabled: value.enabled,
    name: value.name,
    budget: value.budget,
    campaign_type: value.campaignType,
    objective: value.objective,
    objective_custom: value.objectiveCustom,
    memo: value.memo,
    purchase_order: value.purchaseOrder,
    payment_type: value.paymentType,
    budget_type: value.budgetType,
    target_daily_budget: value.targetDailyBudget,
    bill_payer: value.billPayer,
    automated_bidding_enabled: value.automatedBiddingEnabled,
    automated_bidding_goal: value.automatedBiddingGoal,
    automated_bidding_parameters:
      ApiCampaignsControllerCreateInputCampaignAutomatedBiddingParametersToJSON(
        value.automatedBiddingParameters
      ),
    goal: value.goal,
    goal_format: value.goalFormat,
    optimized_bidding_enabled: value.optimizedBiddingEnabled,
    optimized_bidding_goal: value.optimizedBiddingGoal,
    optimized_bidding_parameters:
      ApiCampaignsControllerCreateInputCampaignOptimizedBiddingParametersToJSON(
        value.optimizedBiddingParameters
      ),
    starts_at: value.startsAt,
    ends_at: value.endsAt,
  }
}
