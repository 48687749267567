import { ADS_API_PATH } from 'common/constants'
import { AdGroupsApi } from 'service/apis/AdGroupsApi'
import Client from 'service/client'
import { getEnumValueFromString } from 'service/mappings'
import {
  GetAdGroupsSortByEnum,
  GetAdGroupsIdAnalyticsMetricEnum,
} from 'service/openapi/__codegen__/apis/AdGroupsApi'
import { GetAnalyticsOptions } from 'service/shared'
import {
  GetAdGroupsOptions,
  CreateAdGroupOptions,
  UpdateAdGroupOptions,
  GetAdGroupResponse,
  AdGroupClientInterface,
  GetAdGroupOptions,
} from 'service/types'
import { ApiAdGroupsControllerCreateInputAdGroupTargetingTypeEnum } from '../openapi/__codegen__/models/ApiAdGroupsControllerCreateInputAdGroup'
import { ApiAdGroupsControllerCreateInputAdGroupUserTargeting } from '../openapi/__codegen__/models/ApiAdGroupsControllerCreateInputAdGroupUserTargeting'

export default class OpenAPIAdGroupClient extends Client implements AdGroupClientInterface {
  constructor() {
    super()
    this.ADS_API_PATH = `${ADS_API_PATH}/ad_groups`
  }

  getAdGroups = async (campaignId: string, options: GetAdGroupsOptions) => {
    return AdGroupsApi.getAdGroups({
      campaignId,
      query: options?.query,
      dateRangeStartDate: options?.dateRange?.startDate,
      dateRangeEndDate: options?.dateRange?.endDate,
      sortBy:
        (options?.sortBy && getEnumValueFromString(GetAdGroupsSortByEnum, options.sortBy)) ||
        undefined,
      page: (options?.page && String(options.page)) || undefined,
      excludeBidRecs: options?.excludeBidRecs === undefined ? false : options.excludeBidRecs,
    })
  }

  getAdGroup = (adGroupId: string, options?: GetAdGroupOptions) => {
    return AdGroupsApi.getAdGroupsId({
      id: adGroupId,
      dateRangeStartDate: options?.dateRange?.startDate,
      dateRangeEndDate: options?.dateRange?.endDate,
      excludeBidRecs: options?.excludeBidRecs === undefined ? false : options.excludeBidRecs,
    })
  }

  getAdGroupAnalytics = (adGroupId: string, options: GetAnalyticsOptions) => {
    return AdGroupsApi.getAdGroupsIdAnalytics({
      id: adGroupId,
      dateRangeStartDate: options.dateRange?.startDate,
      dateRangeEndDate: options.dateRange?.endDate,
      metric: getEnumValueFromString(GetAdGroupsIdAnalyticsMetricEnum, options.metric),
    })
  }

  getSuggestedBid = (adGroupId: string) => {
    return AdGroupsApi.getAdGroupsIdSuggestedBid({ id: adGroupId })
  }

  createAdGroup = (campaignId: string, options: CreateAdGroupOptions) => {
    return AdGroupsApi.postAdGroups({
      body: {
        campaignId,
        adGroup: {
          defaultBid: options.defaultBid,
          enabled: true,
          name: options.name,
          targetingType: options.isImpulse
            ? ApiAdGroupsControllerCreateInputAdGroupTargetingTypeEnum.Impulse
            : ApiAdGroupsControllerCreateInputAdGroupTargetingTypeEnum.Default,
          brandedKeywordsOptOutEnabled: options.brandedKeywordsOptOutEnabled,
          userTargeting: this.userTargetingFromAdGroupOptions(options),
        },
      },
    })
  }

  updateAdGroup = (
    adGroupId: string,
    options: UpdateAdGroupOptions
  ): Promise<GetAdGroupResponse> => {
    return AdGroupsApi.putAdGroupsId({
      id: adGroupId,
      body: {
        adGroup: {
          name: options?.name,
          defaultBid: options?.defaultBid,
          enabled: options?.enabled,
          brandedKeywordsOptOutEnabled: options?.brandedKeywordsOptOutEnabled,
          userTargeting: this.userTargetingFromAdGroupOptions(options),
        },
        excludeBidRecs: options?.excludeBidRecs === undefined ? false : options.excludeBidRecs,
      },
    })
  }

  private userTargetingFromAdGroupOptions(
    options: CreateAdGroupOptions | UpdateAdGroupOptions
  ): ApiAdGroupsControllerCreateInputAdGroupUserTargeting | undefined {
    const geographicTargetingPresent =
      options?.geographicTargeting && options?.geographicTargeting.length > 0
    const buyerLicenseTargetingPresent =
      options?.buyerLicenseTargeting && options?.buyerLicenseTargeting.length > 0

    if (!geographicTargetingPresent && !buyerLicenseTargetingPresent) {
      return undefined
    }

    return {
      geography: geographicTargetingPresent ? options.geographicTargeting : undefined,
      buyerLicense: buyerLicenseTargetingPresent ? options.buyerLicenseTargeting : undefined,
    }
  }
}
