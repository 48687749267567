// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayAdGroupsParamDataAttributesAnalytics } from './GetDisplayAdGroupsParamDataAttributesAnalytics'
import {
  GetDisplayAdGroupsParamDataAttributesAnalyticsFromJSON,
  GetDisplayAdGroupsParamDataAttributesAnalyticsFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesAnalyticsToJSON,
} from './GetDisplayAdGroupsParamDataAttributesAnalytics'
import type { GetDisplayAdGroupsParamDataAttributesCreative } from './GetDisplayAdGroupsParamDataAttributesCreative'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreative'
import type { GetDisplayAdGroupsParamDataAttributesEditorialStates } from './GetDisplayAdGroupsParamDataAttributesEditorialStates'
import {
  GetDisplayAdGroupsParamDataAttributesEditorialStatesFromJSON,
  GetDisplayAdGroupsParamDataAttributesEditorialStatesFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesEditorialStatesToJSON,
} from './GetDisplayAdGroupsParamDataAttributesEditorialStates'
import type { GetDisplayAdGroupsParamDataAttributesTargetingStrategy } from './GetDisplayAdGroupsParamDataAttributesTargetingStrategy'
import {
  GetDisplayAdGroupsParamDataAttributesTargetingStrategyFromJSON,
  GetDisplayAdGroupsParamDataAttributesTargetingStrategyFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesTargetingStrategyToJSON,
} from './GetDisplayAdGroupsParamDataAttributesTargetingStrategy'

/**
 *
 * @export
 * @interface GetDisplayAdGroupsParamDataAttributes
 */
export interface GetDisplayAdGroupsParamDataAttributes {
  /**
   * A descriptive name for the ad group.
   * @type {string}
   * @memberof GetDisplayAdGroupsParamDataAttributes
   */
  name: string
  /**
   * The maximum amount, in dollars, you want to pay per thousands views for advertised creatives in this ad group.
   * @type {number}
   * @memberof GetDisplayAdGroupsParamDataAttributes
   */
  defaultBid?: number
  /**
   * Id of the campaign the ad group belongs to. Required for creation
   * @type {string}
   * @memberof GetDisplayAdGroupsParamDataAttributes
   */
  campaignId: string
  /**
   * Format of the ad group, ex: search banner, category banner
   * @type {string}
   * @memberof GetDisplayAdGroupsParamDataAttributes
   */
  placementType: string
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreative}
   * @memberof GetDisplayAdGroupsParamDataAttributes
   */
  creative: GetDisplayAdGroupsParamDataAttributesCreative
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesTargetingStrategy}
   * @memberof GetDisplayAdGroupsParamDataAttributes
   */
  targetingStrategy: GetDisplayAdGroupsParamDataAttributesTargetingStrategy
  /**
   * Lock key for optimistic locking. Lock key retrieved in the response should be passed in the request. Only for updates
   * @type {string}
   * @memberof GetDisplayAdGroupsParamDataAttributes
   */
  lockKey?: string
  /**
   * Enabled flag for the ad group. Ad group is disabled/paused if set to false.
   * @type {boolean}
   * @memberof GetDisplayAdGroupsParamDataAttributes
   */
  enabled?: boolean
  /**
   * Ad group status
   * @type {string}
   * @memberof GetDisplayAdGroupsParamDataAttributes
   */
  status?: GetDisplayAdGroupsParamDataAttributesStatusEnum
  /**
   * Timestamp of when the ad group was created
   * @type {string}
   * @memberof GetDisplayAdGroupsParamDataAttributes
   */
  createdAt?: string
  /**
   * Timestamp of when the ad group was updated
   * @type {string}
   * @memberof GetDisplayAdGroupsParamDataAttributes
   */
  updatedAt?: string
  /**
   * Editorial states
   * @type {Array<GetDisplayAdGroupsParamDataAttributesEditorialStates>}
   * @memberof GetDisplayAdGroupsParamDataAttributes
   */
  editorialStates?: Array<GetDisplayAdGroupsParamDataAttributesEditorialStates>
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesAnalytics}
   * @memberof GetDisplayAdGroupsParamDataAttributes
   */
  analytics?: GetDisplayAdGroupsParamDataAttributesAnalytics
}

/**
 * @export
 */
export const GetDisplayAdGroupsParamDataAttributesStatusEnum = {
  Active: 'ACTIVE',
  Appealed: 'APPEALED',
  Approved: 'APPROVED',
  Closed: 'CLOSED',
  Completed: 'COMPLETED',
  Draft: 'DRAFT',
  Editable: 'EDITABLE',
  Ended: 'ENDED',
  Escalated: 'ESCALATED',
  InReview: 'IN_REVIEW',
  OutOfBudget: 'OUT_OF_BUDGET',
  Paused: 'PAUSED',
  Pending: 'PENDING',
  Rejected: 'REJECTED',
  Scheduled: 'SCHEDULED',
  Unknown: 'UNKNOWN',
} as const
export type GetDisplayAdGroupsParamDataAttributesStatusEnum =
  typeof GetDisplayAdGroupsParamDataAttributesStatusEnum[keyof typeof GetDisplayAdGroupsParamDataAttributesStatusEnum]

/**
 * Check if a given object implements the GetDisplayAdGroupsParamDataAttributes interface.
 */
export function instanceOfGetDisplayAdGroupsParamDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'campaignId' in value
  isInstance = isInstance && 'placementType' in value
  isInstance = isInstance && 'creative' in value
  isInstance = isInstance && 'targetingStrategy' in value

  return isInstance
}

export function GetDisplayAdGroupsParamDataAttributesFromJSON(
  json: any
): GetDisplayAdGroupsParamDataAttributes {
  return GetDisplayAdGroupsParamDataAttributesFromJSONTyped(json, false)
}

export function GetDisplayAdGroupsParamDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupsParamDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    defaultBid: !exists(json, 'default_bid') ? undefined : json['default_bid'],
    campaignId: json['campaign_id'],
    placementType: json['placement_type'],
    creative: GetDisplayAdGroupsParamDataAttributesCreativeFromJSON(json['creative']),
    targetingStrategy: GetDisplayAdGroupsParamDataAttributesTargetingStrategyFromJSON(
      json['targeting_strategy']
    ),
    lockKey: !exists(json, 'lock_key') ? undefined : json['lock_key'],
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
    status: !exists(json, 'status') ? undefined : json['status'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    editorialStates: !exists(json, 'editorial_states')
      ? undefined
      : (json['editorial_states'] as Array<any>).map(
          GetDisplayAdGroupsParamDataAttributesEditorialStatesFromJSON
        ),
    analytics: !exists(json, 'analytics')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesAnalyticsFromJSON(json['analytics']),
  }
}

export function GetDisplayAdGroupsParamDataAttributesToJSON(
  value?: GetDisplayAdGroupsParamDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    default_bid: value.defaultBid,
    campaign_id: value.campaignId,
    placement_type: value.placementType,
    creative: GetDisplayAdGroupsParamDataAttributesCreativeToJSON(value.creative),
    targeting_strategy: GetDisplayAdGroupsParamDataAttributesTargetingStrategyToJSON(
      value.targetingStrategy
    ),
    lock_key: value.lockKey,
    enabled: value.enabled,
    status: value.status,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    editorial_states:
      value.editorialStates === undefined
        ? undefined
        : (value.editorialStates as Array<any>).map(
            GetDisplayAdGroupsParamDataAttributesEditorialStatesToJSON
          ),
    analytics: GetDisplayAdGroupsParamDataAttributesAnalyticsToJSON(value.analytics),
  }
}
