// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayAdGroupsParamDataAttributesEditorialStatesAppeals } from './GetDisplayAdGroupsParamDataAttributesEditorialStatesAppeals'
import {
  GetDisplayAdGroupsParamDataAttributesEditorialStatesAppealsFromJSON,
  GetDisplayAdGroupsParamDataAttributesEditorialStatesAppealsFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesEditorialStatesAppealsToJSON,
} from './GetDisplayAdGroupsParamDataAttributesEditorialStatesAppeals'
import type { GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshot } from './GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshot'
import {
  GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFromJSON,
  GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotToJSON,
} from './GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshot'
import type { GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshot } from './GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshot'
import {
  GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshotFromJSON,
  GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshotToJSON,
} from './GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshot'
import type { GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewState } from './GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewState'
import {
  GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFromJSON,
  GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateToJSON,
} from './GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewState'

/**
 *
 * @export
 * @interface GetDisplayAdGroupsParamDataAttributesEditorialStates
 */
export interface GetDisplayAdGroupsParamDataAttributesEditorialStates {
  /**
   * The entity id
   * @type {string}
   * @memberof GetDisplayAdGroupsParamDataAttributesEditorialStates
   */
  id: string
  /**
   * The entity version
   * @type {number}
   * @memberof GetDisplayAdGroupsParamDataAttributesEditorialStates
   */
  version?: number
  /**
   * The entity type
   * @type {string}
   * @memberof GetDisplayAdGroupsParamDataAttributesEditorialStates
   */
  type: GetDisplayAdGroupsParamDataAttributesEditorialStatesTypeEnum
  /**
   * The state of the editorialization
   * @type {string}
   * @memberof GetDisplayAdGroupsParamDataAttributesEditorialStates
   */
  state: string
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewState}
   * @memberof GetDisplayAdGroupsParamDataAttributesEditorialStates
   */
  reviewState?: GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewState
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshot}
   * @memberof GetDisplayAdGroupsParamDataAttributesEditorialStates
   */
  entitySnapshot: GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshot
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshot}
   * @memberof GetDisplayAdGroupsParamDataAttributesEditorialStates
   */
  decisionSnapshot: GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshot
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesEditorialStatesAppeals}
   * @memberof GetDisplayAdGroupsParamDataAttributesEditorialStates
   */
  appeals: GetDisplayAdGroupsParamDataAttributesEditorialStatesAppeals
}

/**
 * @export
 */
export const GetDisplayAdGroupsParamDataAttributesEditorialStatesTypeEnum = {
  Asset: 'ASSET',
  BrandPage: 'BRAND_PAGE',
  BrandPageBlock: 'BRAND_PAGE_BLOCK',
  Creative: 'CREATIVE',
  DisplayAdGroup: 'DISPLAY_AD_GROUP',
  EmailAdGroup: 'EMAIL_AD_GROUP',
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type GetDisplayAdGroupsParamDataAttributesEditorialStatesTypeEnum =
  typeof GetDisplayAdGroupsParamDataAttributesEditorialStatesTypeEnum[keyof typeof GetDisplayAdGroupsParamDataAttributesEditorialStatesTypeEnum]

/**
 * Check if a given object implements the GetDisplayAdGroupsParamDataAttributesEditorialStates interface.
 */
export function instanceOfGetDisplayAdGroupsParamDataAttributesEditorialStates(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'state' in value
  isInstance = isInstance && 'entitySnapshot' in value
  isInstance = isInstance && 'decisionSnapshot' in value
  isInstance = isInstance && 'appeals' in value

  return isInstance
}

export function GetDisplayAdGroupsParamDataAttributesEditorialStatesFromJSON(
  json: any
): GetDisplayAdGroupsParamDataAttributesEditorialStates {
  return GetDisplayAdGroupsParamDataAttributesEditorialStatesFromJSONTyped(json, false)
}

export function GetDisplayAdGroupsParamDataAttributesEditorialStatesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupsParamDataAttributesEditorialStates {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    version: !exists(json, 'version') ? undefined : json['version'],
    type: json['type'],
    state: json['state'],
    reviewState: !exists(json, 'review_state')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateFromJSON(
          json['review_state']
        ),
    entitySnapshot: GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshotFromJSON(
      json['entity_snapshot']
    ),
    decisionSnapshot: GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFromJSON(
      json['decision_snapshot']
    ),
    appeals: GetDisplayAdGroupsParamDataAttributesEditorialStatesAppealsFromJSON(json['appeals']),
  }
}

export function GetDisplayAdGroupsParamDataAttributesEditorialStatesToJSON(
  value?: GetDisplayAdGroupsParamDataAttributesEditorialStates | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    version: value.version,
    type: value.type,
    state: value.state,
    review_state: GetDisplayAdGroupsParamDataAttributesEditorialStatesReviewStateToJSON(
      value.reviewState
    ),
    entity_snapshot: GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshotToJSON(
      value.entitySnapshot
    ),
    decision_snapshot: GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotToJSON(
      value.decisionSnapshot
    ),
    appeals: GetDisplayAdGroupsParamDataAttributesEditorialStatesAppealsToJSON(value.appeals),
  }
}
