// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface BulkAdGroupResponseMetaErrors
 */
export interface BulkAdGroupResponseMetaErrors {
  /**
   * HTTP Status Code
   * @type {number}
   * @memberof BulkAdGroupResponseMetaErrors
   */
  status: number
  /**
   * Error Message
   * @type {string}
   * @memberof BulkAdGroupResponseMetaErrors
   */
  message: string
  /**
   * The Given Id. Nil for unsuccessful creates.
   * @type {string}
   * @memberof BulkAdGroupResponseMetaErrors
   */
  id: string
  /**
   * Parameters from the request that resulted in failure.
   * @type {{ [key: string]: any; }}
   * @memberof BulkAdGroupResponseMetaErrors
   */
  requestParams?: { [key: string]: any }
}

/**
 * Check if a given object implements the BulkAdGroupResponseMetaErrors interface.
 */
export function instanceOfBulkAdGroupResponseMetaErrors(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'message' in value
  isInstance = isInstance && 'id' in value

  return isInstance
}

export function BulkAdGroupResponseMetaErrorsFromJSON(json: any): BulkAdGroupResponseMetaErrors {
  return BulkAdGroupResponseMetaErrorsFromJSONTyped(json, false)
}

export function BulkAdGroupResponseMetaErrorsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BulkAdGroupResponseMetaErrors {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    message: json['message'],
    id: json['id'],
    requestParams: !exists(json, 'request_params') ? undefined : json['request_params'],
  }
}

export function BulkAdGroupResponseMetaErrorsToJSON(
  value?: BulkAdGroupResponseMetaErrors | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    message: value.message,
    id: value.id,
    request_params: value.requestParams,
  }
}
