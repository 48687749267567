// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetCampaignsIdActionsParamDataDailyBudgetAtRisk } from './GetCampaignsIdActionsParamDataDailyBudgetAtRisk'
import {
  GetCampaignsIdActionsParamDataDailyBudgetAtRiskFromJSON,
  GetCampaignsIdActionsParamDataDailyBudgetAtRiskFromJSONTyped,
  GetCampaignsIdActionsParamDataDailyBudgetAtRiskToJSON,
} from './GetCampaignsIdActionsParamDataDailyBudgetAtRisk'
import type { GetCampaignsIdActionsParamDataDefaultBidActions } from './GetCampaignsIdActionsParamDataDefaultBidActions'
import {
  GetCampaignsIdActionsParamDataDefaultBidActionsFromJSON,
  GetCampaignsIdActionsParamDataDefaultBidActionsFromJSONTyped,
  GetCampaignsIdActionsParamDataDefaultBidActionsToJSON,
} from './GetCampaignsIdActionsParamDataDefaultBidActions'
import type { GetCampaignsIdActionsParamDataLifetimeBudgetAtRisk } from './GetCampaignsIdActionsParamDataLifetimeBudgetAtRisk'
import {
  GetCampaignsIdActionsParamDataLifetimeBudgetAtRiskFromJSON,
  GetCampaignsIdActionsParamDataLifetimeBudgetAtRiskFromJSONTyped,
  GetCampaignsIdActionsParamDataLifetimeBudgetAtRiskToJSON,
} from './GetCampaignsIdActionsParamDataLifetimeBudgetAtRisk'

/**
 *
 * @export
 * @interface GetCampaignActionsResponseData
 */
export interface GetCampaignActionsResponseData {
  /**
   *
   * @type {Array<GetCampaignsIdActionsParamDataDailyBudgetAtRisk>}
   * @memberof GetCampaignActionsResponseData
   */
  dailyBudgetAtRisk?: Array<GetCampaignsIdActionsParamDataDailyBudgetAtRisk>
  /**
   *
   * @type {Array<GetCampaignsIdActionsParamDataLifetimeBudgetAtRisk>}
   * @memberof GetCampaignActionsResponseData
   */
  lifetimeBudgetAtRisk?: Array<GetCampaignsIdActionsParamDataLifetimeBudgetAtRisk>
  /**
   *
   * @type {Array<GetCampaignsIdActionsParamDataDefaultBidActions>}
   * @memberof GetCampaignActionsResponseData
   */
  defaultBidActions?: Array<GetCampaignsIdActionsParamDataDefaultBidActions>
}

/**
 * Check if a given object implements the GetCampaignActionsResponseData interface.
 */
export function instanceOfGetCampaignActionsResponseData(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function GetCampaignActionsResponseDataFromJSON(json: any): GetCampaignActionsResponseData {
  return GetCampaignActionsResponseDataFromJSONTyped(json, false)
}

export function GetCampaignActionsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignActionsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    dailyBudgetAtRisk: !exists(json, 'daily_budget_at_risk')
      ? undefined
      : (json['daily_budget_at_risk'] as Array<any>).map(
          GetCampaignsIdActionsParamDataDailyBudgetAtRiskFromJSON
        ),
    lifetimeBudgetAtRisk: !exists(json, 'lifetime_budget_at_risk')
      ? undefined
      : (json['lifetime_budget_at_risk'] as Array<any>).map(
          GetCampaignsIdActionsParamDataLifetimeBudgetAtRiskFromJSON
        ),
    defaultBidActions: !exists(json, 'default_bid_actions')
      ? undefined
      : (json['default_bid_actions'] as Array<any>).map(
          GetCampaignsIdActionsParamDataDefaultBidActionsFromJSON
        ),
  }
}

export function GetCampaignActionsResponseDataToJSON(
  value?: GetCampaignActionsResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    daily_budget_at_risk:
      value.dailyBudgetAtRisk === undefined
        ? undefined
        : (value.dailyBudgetAtRisk as Array<any>).map(
            GetCampaignsIdActionsParamDataDailyBudgetAtRiskToJSON
          ),
    lifetime_budget_at_risk:
      value.lifetimeBudgetAtRisk === undefined
        ? undefined
        : (value.lifetimeBudgetAtRisk as Array<any>).map(
            GetCampaignsIdActionsParamDataLifetimeBudgetAtRiskToJSON
          ),
    default_bid_actions:
      value.defaultBidActions === undefined
        ? undefined
        : (value.defaultBidActions as Array<any>).map(
            GetCampaignsIdActionsParamDataDefaultBidActionsToJSON
          ),
  }
}
