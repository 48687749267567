// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdGroupsResponseDataAttributesAnalytics } from './GetAdGroupsResponseDataAttributesAnalytics'
import {
  GetAdGroupsResponseDataAttributesAnalyticsFromJSON,
  GetAdGroupsResponseDataAttributesAnalyticsFromJSONTyped,
  GetAdGroupsResponseDataAttributesAnalyticsToJSON,
} from './GetAdGroupsResponseDataAttributesAnalytics'
import type { GetAdGroupsResponseDataAttributesBidRecs } from './GetAdGroupsResponseDataAttributesBidRecs'
import {
  GetAdGroupsResponseDataAttributesBidRecsFromJSON,
  GetAdGroupsResponseDataAttributesBidRecsFromJSONTyped,
  GetAdGroupsResponseDataAttributesBidRecsToJSON,
} from './GetAdGroupsResponseDataAttributesBidRecs'
import type { GetAdGroupsResponseDataAttributesMeta } from './GetAdGroupsResponseDataAttributesMeta'
import {
  GetAdGroupsResponseDataAttributesMetaFromJSON,
  GetAdGroupsResponseDataAttributesMetaFromJSONTyped,
  GetAdGroupsResponseDataAttributesMetaToJSON,
} from './GetAdGroupsResponseDataAttributesMeta'
import type { GetAdGroupsResponseDataAttributesSupplementaryStatuses } from './GetAdGroupsResponseDataAttributesSupplementaryStatuses'
import {
  GetAdGroupsResponseDataAttributesSupplementaryStatusesFromJSON,
  GetAdGroupsResponseDataAttributesSupplementaryStatusesFromJSONTyped,
  GetAdGroupsResponseDataAttributesSupplementaryStatusesToJSON,
} from './GetAdGroupsResponseDataAttributesSupplementaryStatuses'
import type { GetAdGroupsResponseDataAttributesUserTargeting } from './GetAdGroupsResponseDataAttributesUserTargeting'
import {
  GetAdGroupsResponseDataAttributesUserTargetingFromJSON,
  GetAdGroupsResponseDataAttributesUserTargetingFromJSONTyped,
  GetAdGroupsResponseDataAttributesUserTargetingToJSON,
} from './GetAdGroupsResponseDataAttributesUserTargeting'

/**
 *
 * @export
 * @interface GetAdGroupsResponseDataAttributes
 */
export interface GetAdGroupsResponseDataAttributes {
  /**
   * A descriptive name for the ad group.
   * @type {string}
   * @memberof GetAdGroupsResponseDataAttributes
   */
  name: string
  /**
   * Set to true to create an active ad group. Ads are only run for active ad groups with at least one product which belong to active, running campaigns. Set to false to create an inactive ad group.
   * @type {boolean}
   * @memberof GetAdGroupsResponseDataAttributes
   */
  enabled: boolean
  /**
   * The ad group's status. Can be either campaign_ended, campaign_out_of_budget, campaign_paused, campaign_draft, paused, scheduled, active, or unknown.
   * @type {string}
   * @memberof GetAdGroupsResponseDataAttributes
   */
  adGroupStatus: GetAdGroupsResponseDataAttributesAdGroupStatusEnum
  /**
   * The placement targeting type for this ad group. Currently supported values: ['default', 'impulse'].
   * @type {string}
   * @memberof GetAdGroupsResponseDataAttributes
   */
  targetingType?: GetAdGroupsResponseDataAttributesTargetingTypeEnum
  /**
   * The maximum amount, in dollars, you want to pay per click for advertised products in this ad group.
   * May be `null` for campaigns with `optimized_bidding_enabled = true` and decimal otherwise
   * @type {number}
   * @memberof GetAdGroupsResponseDataAttributes
   */
  defaultBid?: number
  /**
   *
   * @type {GetAdGroupsResponseDataAttributesAnalytics}
   * @memberof GetAdGroupsResponseDataAttributes
   */
  analytics?: GetAdGroupsResponseDataAttributesAnalytics
  /**
   *
   * @type {GetAdGroupsResponseDataAttributesMeta}
   * @memberof GetAdGroupsResponseDataAttributes
   */
  meta?: GetAdGroupsResponseDataAttributesMeta
  /**
   *
   * @type {GetAdGroupsResponseDataAttributesBidRecs}
   * @memberof GetAdGroupsResponseDataAttributes
   */
  bidRecs?: GetAdGroupsResponseDataAttributesBidRecs
  /**
   * Total number of product count for the ad group.
   * @type {number}
   * @memberof GetAdGroupsResponseDataAttributes
   */
  productCount?: number
  /**
   * Set to true to enable branded keywords opt-out
   * @type {boolean}
   * @memberof GetAdGroupsResponseDataAttributes
   */
  brandedKeywordsOptOutEnabled?: boolean
  /**
   *
   * @type {GetAdGroupsResponseDataAttributesUserTargeting}
   * @memberof GetAdGroupsResponseDataAttributes
   */
  userTargeting?: GetAdGroupsResponseDataAttributesUserTargeting
  /**
   *
   * @type {GetAdGroupsResponseDataAttributesSupplementaryStatuses}
   * @memberof GetAdGroupsResponseDataAttributes
   */
  supplementaryStatuses?: GetAdGroupsResponseDataAttributesSupplementaryStatuses
}

/**
 * @export
 */
export const GetAdGroupsResponseDataAttributesAdGroupStatusEnum = {
  IncompleteAdGroupProducts: 'incomplete_ad_group_products',
  CampaignEnded: 'campaign_ended',
  CampaignOutOfBudget: 'campaign_out_of_budget',
  CampaignPaused: 'campaign_paused',
  CampaignDraft: 'campaign_draft',
  Paused: 'paused',
  Scheduled: 'scheduled',
  Active: 'active',
  Unknown: 'unknown',
} as const
export type GetAdGroupsResponseDataAttributesAdGroupStatusEnum =
  typeof GetAdGroupsResponseDataAttributesAdGroupStatusEnum[keyof typeof GetAdGroupsResponseDataAttributesAdGroupStatusEnum]

/**
 * @export
 */
export const GetAdGroupsResponseDataAttributesTargetingTypeEnum = {
  Impulse: 'impulse',
  Default: 'default',
} as const
export type GetAdGroupsResponseDataAttributesTargetingTypeEnum =
  typeof GetAdGroupsResponseDataAttributesTargetingTypeEnum[keyof typeof GetAdGroupsResponseDataAttributesTargetingTypeEnum]

/**
 * Check if a given object implements the GetAdGroupsResponseDataAttributes interface.
 */
export function instanceOfGetAdGroupsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'adGroupStatus' in value

  return isInstance
}

export function GetAdGroupsResponseDataAttributesFromJSON(
  json: any
): GetAdGroupsResponseDataAttributes {
  return GetAdGroupsResponseDataAttributesFromJSONTyped(json, false)
}

export function GetAdGroupsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdGroupsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    enabled: json['enabled'],
    adGroupStatus: json['ad_group_status'],
    targetingType: !exists(json, 'targeting_type') ? undefined : json['targeting_type'],
    defaultBid: !exists(json, 'default_bid') ? undefined : json['default_bid'],
    analytics: !exists(json, 'analytics')
      ? undefined
      : GetAdGroupsResponseDataAttributesAnalyticsFromJSON(json['analytics']),
    meta: !exists(json, 'meta')
      ? undefined
      : GetAdGroupsResponseDataAttributesMetaFromJSON(json['meta']),
    bidRecs: !exists(json, 'bid_recs')
      ? undefined
      : GetAdGroupsResponseDataAttributesBidRecsFromJSON(json['bid_recs']),
    productCount: !exists(json, 'product_count') ? undefined : json['product_count'],
    brandedKeywordsOptOutEnabled: !exists(json, 'branded_keywords_opt_out_enabled')
      ? undefined
      : json['branded_keywords_opt_out_enabled'],
    userTargeting: !exists(json, 'user_targeting')
      ? undefined
      : GetAdGroupsResponseDataAttributesUserTargetingFromJSON(json['user_targeting']),
    supplementaryStatuses: !exists(json, 'supplementary_statuses')
      ? undefined
      : GetAdGroupsResponseDataAttributesSupplementaryStatusesFromJSON(
          json['supplementary_statuses']
        ),
  }
}

export function GetAdGroupsResponseDataAttributesToJSON(
  value?: GetAdGroupsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    enabled: value.enabled,
    ad_group_status: value.adGroupStatus,
    targeting_type: value.targetingType,
    default_bid: value.defaultBid,
    analytics: GetAdGroupsResponseDataAttributesAnalyticsToJSON(value.analytics),
    meta: GetAdGroupsResponseDataAttributesMetaToJSON(value.meta),
    bid_recs: GetAdGroupsResponseDataAttributesBidRecsToJSON(value.bidRecs),
    product_count: value.productCount,
    branded_keywords_opt_out_enabled: value.brandedKeywordsOptOutEnabled,
    user_targeting: GetAdGroupsResponseDataAttributesUserTargetingToJSON(value.userTargeting),
    supplementary_statuses: GetAdGroupsResponseDataAttributesSupplementaryStatusesToJSON(
      value.supplementaryStatuses
    ),
  }
}
