// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AdGroupResponseDataAttributesAnalytics } from './AdGroupResponseDataAttributesAnalytics'
import {
  AdGroupResponseDataAttributesAnalyticsFromJSON,
  AdGroupResponseDataAttributesAnalyticsFromJSONTyped,
  AdGroupResponseDataAttributesAnalyticsToJSON,
} from './AdGroupResponseDataAttributesAnalytics'
import type { AdGroupResponseDataAttributesBidRecs } from './AdGroupResponseDataAttributesBidRecs'
import {
  AdGroupResponseDataAttributesBidRecsFromJSON,
  AdGroupResponseDataAttributesBidRecsFromJSONTyped,
  AdGroupResponseDataAttributesBidRecsToJSON,
} from './AdGroupResponseDataAttributesBidRecs'
import type { AdGroupResponseDataAttributesMeta } from './AdGroupResponseDataAttributesMeta'
import {
  AdGroupResponseDataAttributesMetaFromJSON,
  AdGroupResponseDataAttributesMetaFromJSONTyped,
  AdGroupResponseDataAttributesMetaToJSON,
} from './AdGroupResponseDataAttributesMeta'
import type { AdGroupResponseDataAttributesSupplementaryStatuses } from './AdGroupResponseDataAttributesSupplementaryStatuses'
import {
  AdGroupResponseDataAttributesSupplementaryStatusesFromJSON,
  AdGroupResponseDataAttributesSupplementaryStatusesFromJSONTyped,
  AdGroupResponseDataAttributesSupplementaryStatusesToJSON,
} from './AdGroupResponseDataAttributesSupplementaryStatuses'
import type { AdGroupResponseDataAttributesUserTargeting } from './AdGroupResponseDataAttributesUserTargeting'
import {
  AdGroupResponseDataAttributesUserTargetingFromJSON,
  AdGroupResponseDataAttributesUserTargetingFromJSONTyped,
  AdGroupResponseDataAttributesUserTargetingToJSON,
} from './AdGroupResponseDataAttributesUserTargeting'

/**
 *
 * @export
 * @interface AdGroupResponseDataAttributes
 */
export interface AdGroupResponseDataAttributes {
  /**
   * A descriptive name for the ad group.
   * @type {string}
   * @memberof AdGroupResponseDataAttributes
   */
  name: string
  /**
   * Set to true to create an active ad group. Ads are only run for active ad groups with at least one product which belong to active, running campaigns. Set to false to create an inactive ad group.
   * @type {boolean}
   * @memberof AdGroupResponseDataAttributes
   */
  enabled: boolean
  /**
   * The ad group's status. Can be either campaign_ended, campaign_out_of_budget, campaign_paused, campaign_draft, paused, scheduled, active, or unknown.
   * @type {string}
   * @memberof AdGroupResponseDataAttributes
   */
  adGroupStatus: AdGroupResponseDataAttributesAdGroupStatusEnum
  /**
   * The placement targeting type for this ad group. Currently supported values: ['default', 'impulse'].
   * @type {string}
   * @memberof AdGroupResponseDataAttributes
   */
  targetingType?: AdGroupResponseDataAttributesTargetingTypeEnum
  /**
   * The maximum amount, in dollars, you want to pay per click for advertised products in this ad group.
   * May be `null` for campaigns with `optimized_bidding_enabled = true` and decimal otherwise
   * @type {number}
   * @memberof AdGroupResponseDataAttributes
   */
  defaultBid?: number
  /**
   *
   * @type {AdGroupResponseDataAttributesAnalytics}
   * @memberof AdGroupResponseDataAttributes
   */
  analytics?: AdGroupResponseDataAttributesAnalytics
  /**
   *
   * @type {AdGroupResponseDataAttributesMeta}
   * @memberof AdGroupResponseDataAttributes
   */
  meta?: AdGroupResponseDataAttributesMeta
  /**
   *
   * @type {AdGroupResponseDataAttributesBidRecs}
   * @memberof AdGroupResponseDataAttributes
   */
  bidRecs?: AdGroupResponseDataAttributesBidRecs
  /**
   * Total number of product count for the ad group.
   * @type {number}
   * @memberof AdGroupResponseDataAttributes
   */
  productCount?: number
  /**
   * Set to true to enable branded keywords opt-out
   * @type {boolean}
   * @memberof AdGroupResponseDataAttributes
   */
  brandedKeywordsOptOutEnabled?: boolean
  /**
   *
   * @type {AdGroupResponseDataAttributesUserTargeting}
   * @memberof AdGroupResponseDataAttributes
   */
  userTargeting?: AdGroupResponseDataAttributesUserTargeting
  /**
   *
   * @type {AdGroupResponseDataAttributesSupplementaryStatuses}
   * @memberof AdGroupResponseDataAttributes
   */
  supplementaryStatuses?: AdGroupResponseDataAttributesSupplementaryStatuses
}

/**
 * @export
 */
export const AdGroupResponseDataAttributesAdGroupStatusEnum = {
  IncompleteAdGroupProducts: 'incomplete_ad_group_products',
  CampaignEnded: 'campaign_ended',
  CampaignOutOfBudget: 'campaign_out_of_budget',
  CampaignPaused: 'campaign_paused',
  CampaignDraft: 'campaign_draft',
  Paused: 'paused',
  Scheduled: 'scheduled',
  Active: 'active',
  Unknown: 'unknown',
} as const
export type AdGroupResponseDataAttributesAdGroupStatusEnum =
  typeof AdGroupResponseDataAttributesAdGroupStatusEnum[keyof typeof AdGroupResponseDataAttributesAdGroupStatusEnum]

/**
 * @export
 */
export const AdGroupResponseDataAttributesTargetingTypeEnum = {
  Impulse: 'impulse',
  Default: 'default',
} as const
export type AdGroupResponseDataAttributesTargetingTypeEnum =
  typeof AdGroupResponseDataAttributesTargetingTypeEnum[keyof typeof AdGroupResponseDataAttributesTargetingTypeEnum]

/**
 * Check if a given object implements the AdGroupResponseDataAttributes interface.
 */
export function instanceOfAdGroupResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'adGroupStatus' in value

  return isInstance
}

export function AdGroupResponseDataAttributesFromJSON(json: any): AdGroupResponseDataAttributes {
  return AdGroupResponseDataAttributesFromJSONTyped(json, false)
}

export function AdGroupResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdGroupResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    enabled: json['enabled'],
    adGroupStatus: json['ad_group_status'],
    targetingType: !exists(json, 'targeting_type') ? undefined : json['targeting_type'],
    defaultBid: !exists(json, 'default_bid') ? undefined : json['default_bid'],
    analytics: !exists(json, 'analytics')
      ? undefined
      : AdGroupResponseDataAttributesAnalyticsFromJSON(json['analytics']),
    meta: !exists(json, 'meta')
      ? undefined
      : AdGroupResponseDataAttributesMetaFromJSON(json['meta']),
    bidRecs: !exists(json, 'bid_recs')
      ? undefined
      : AdGroupResponseDataAttributesBidRecsFromJSON(json['bid_recs']),
    productCount: !exists(json, 'product_count') ? undefined : json['product_count'],
    brandedKeywordsOptOutEnabled: !exists(json, 'branded_keywords_opt_out_enabled')
      ? undefined
      : json['branded_keywords_opt_out_enabled'],
    userTargeting: !exists(json, 'user_targeting')
      ? undefined
      : AdGroupResponseDataAttributesUserTargetingFromJSON(json['user_targeting']),
    supplementaryStatuses: !exists(json, 'supplementary_statuses')
      ? undefined
      : AdGroupResponseDataAttributesSupplementaryStatusesFromJSON(json['supplementary_statuses']),
  }
}

export function AdGroupResponseDataAttributesToJSON(
  value?: AdGroupResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    enabled: value.enabled,
    ad_group_status: value.adGroupStatus,
    targeting_type: value.targetingType,
    default_bid: value.defaultBid,
    analytics: AdGroupResponseDataAttributesAnalyticsToJSON(value.analytics),
    meta: AdGroupResponseDataAttributesMetaToJSON(value.meta),
    bid_recs: AdGroupResponseDataAttributesBidRecsToJSON(value.bidRecs),
    product_count: value.productCount,
    branded_keywords_opt_out_enabled: value.brandedKeywordsOptOutEnabled,
    user_targeting: AdGroupResponseDataAttributesUserTargetingToJSON(value.userTargeting),
    supplementary_statuses: AdGroupResponseDataAttributesSupplementaryStatusesToJSON(
      value.supplementaryStatuses
    ),
  }
}
