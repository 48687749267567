// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetDisplayAdGroupsParamProductCollection,
  instanceOfGetDisplayAdGroupsParamProductCollection,
  GetDisplayAdGroupsParamProductCollectionFromJSON,
  GetDisplayAdGroupsParamProductCollectionFromJSONTyped,
  GetDisplayAdGroupsParamProductCollectionToJSON,
} from './GetDisplayAdGroupsParamProductCollection'
import {
  GetDisplayAdGroupsParamSearchTermCollection,
  instanceOfGetDisplayAdGroupsParamSearchTermCollection,
  GetDisplayAdGroupsParamSearchTermCollectionFromJSON,
  GetDisplayAdGroupsParamSearchTermCollectionFromJSONTyped,
  GetDisplayAdGroupsParamSearchTermCollectionToJSON,
} from './GetDisplayAdGroupsParamSearchTermCollection'

/**
 * @type GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsProperties
 *
 * @export
 */
export type GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsProperties =

    | ({ type: 'product_collection' } & GetDisplayAdGroupsParamProductCollection)
    | ({ type: 'search_term_collection' } & GetDisplayAdGroupsParamSearchTermCollection)

export function GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsPropertiesFromJSON(
  json: any
): GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsProperties {
  return GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsPropertiesFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsPropertiesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsProperties {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'product_collection':
      return {
        ...GetDisplayAdGroupsParamProductCollectionFromJSONTyped(json, true),
        type: 'product_collection',
      }
    case 'search_term_collection':
      return {
        ...GetDisplayAdGroupsParamSearchTermCollectionFromJSONTyped(json, true),
        type: 'search_term_collection',
      }
    default:
      throw new Error(
        `No variant of GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsProperties exists with 'type=${json['type']}'`
      )
  }
}

export function GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsPropertiesToJSON(
  value?: GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsProperties | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'product_collection':
      return GetDisplayAdGroupsParamProductCollectionToJSON(value)
    case 'search_term_collection':
      return GetDisplayAdGroupsParamSearchTermCollectionToJSON(value)
    default:
      throw new Error(
        `No variant of GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsProperties exists with 'type=${value['type']}'`
      )
  }
}
