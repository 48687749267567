// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface DeleteCampaignsResponseDataAttributesAutomatedBiddingParameters
 */
export interface DeleteCampaignsResponseDataAttributesAutomatedBiddingParameters {
  /**
   * The algorithm will attempt to keep ROAS above this number
   * @type {number}
   * @memberof DeleteCampaignsResponseDataAttributesAutomatedBiddingParameters
   */
  roasConstraint?: number
}

/**
 * Check if a given object implements the DeleteCampaignsResponseDataAttributesAutomatedBiddingParameters interface.
 */
export function instanceOfDeleteCampaignsResponseDataAttributesAutomatedBiddingParameters(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function DeleteCampaignsResponseDataAttributesAutomatedBiddingParametersFromJSON(
  json: any
): DeleteCampaignsResponseDataAttributesAutomatedBiddingParameters {
  return DeleteCampaignsResponseDataAttributesAutomatedBiddingParametersFromJSONTyped(json, false)
}

export function DeleteCampaignsResponseDataAttributesAutomatedBiddingParametersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteCampaignsResponseDataAttributesAutomatedBiddingParameters {
  if (json === undefined || json === null) {
    return json
  }
  return {
    roasConstraint: !exists(json, 'roas_constraint') ? undefined : json['roas_constraint'],
  }
}

export function DeleteCampaignsResponseDataAttributesAutomatedBiddingParametersToJSON(
  value?: DeleteCampaignsResponseDataAttributesAutomatedBiddingParameters | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    roas_constraint: value.roasConstraint,
  }
}
