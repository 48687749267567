// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetCampaignsResponseDataAttributesAnalyticsAttributionDataLastTouch } from './GetCampaignsResponseDataAttributesAnalyticsAttributionDataLastTouch'
import {
  GetCampaignsResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON,
  GetCampaignsResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  GetCampaignsResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './GetCampaignsResponseDataAttributesAnalyticsAttributionDataLastTouch'
import type { GetCampaignsResponseDataAttributesAnalyticsAttributionDataLinear } from './GetCampaignsResponseDataAttributesAnalyticsAttributionDataLinear'
import {
  GetCampaignsResponseDataAttributesAnalyticsAttributionDataLinearFromJSON,
  GetCampaignsResponseDataAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  GetCampaignsResponseDataAttributesAnalyticsAttributionDataLinearToJSON,
} from './GetCampaignsResponseDataAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface GetCampaignsResponseDataAttributesAnalyticsAttributionData
 */
export interface GetCampaignsResponseDataAttributesAnalyticsAttributionData {
  /**
   *
   * @type {GetCampaignsResponseDataAttributesAnalyticsAttributionDataLinear}
   * @memberof GetCampaignsResponseDataAttributesAnalyticsAttributionData
   */
  linear: GetCampaignsResponseDataAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {GetCampaignsResponseDataAttributesAnalyticsAttributionDataLastTouch}
   * @memberof GetCampaignsResponseDataAttributesAnalyticsAttributionData
   */
  lastTouch?: GetCampaignsResponseDataAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the GetCampaignsResponseDataAttributesAnalyticsAttributionData interface.
 */
export function instanceOfGetCampaignsResponseDataAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function GetCampaignsResponseDataAttributesAnalyticsAttributionDataFromJSON(
  json: any
): GetCampaignsResponseDataAttributesAnalyticsAttributionData {
  return GetCampaignsResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(json, false)
}

export function GetCampaignsResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignsResponseDataAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear: GetCampaignsResponseDataAttributesAnalyticsAttributionDataLinearFromJSON(
      json['linear']
    ),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : GetCampaignsResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function GetCampaignsResponseDataAttributesAnalyticsAttributionDataToJSON(
  value?: GetCampaignsResponseDataAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear: GetCampaignsResponseDataAttributesAnalyticsAttributionDataLinearToJSON(value.linear),
    last_touch: GetCampaignsResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON(
      value.lastTouch
    ),
  }
}
