// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayAdGroupsParamDataAttributes } from './GetDisplayAdGroupsParamDataAttributes'
import {
  GetDisplayAdGroupsParamDataAttributesFromJSON,
  GetDisplayAdGroupsParamDataAttributesFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesToJSON,
} from './GetDisplayAdGroupsParamDataAttributes'

/**
 *
 * @export
 * @interface GetDisplayAdGroupsParamData
 */
export interface GetDisplayAdGroupsParamData {
  /**
   * DisplayAdGroup ID
   * @type {string}
   * @memberof GetDisplayAdGroupsParamData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetDisplayAdGroupsParamData
   */
  type: GetDisplayAdGroupsParamDataTypeEnum
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributes}
   * @memberof GetDisplayAdGroupsParamData
   */
  attributes: GetDisplayAdGroupsParamDataAttributes
}

/**
 * @export
 */
export const GetDisplayAdGroupsParamDataTypeEnum = {
  DisplayAdGroup: 'display_ad_group',
} as const
export type GetDisplayAdGroupsParamDataTypeEnum =
  typeof GetDisplayAdGroupsParamDataTypeEnum[keyof typeof GetDisplayAdGroupsParamDataTypeEnum]

/**
 * Check if a given object implements the GetDisplayAdGroupsParamData interface.
 */
export function instanceOfGetDisplayAdGroupsParamData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function GetDisplayAdGroupsParamDataFromJSON(json: any): GetDisplayAdGroupsParamData {
  return GetDisplayAdGroupsParamDataFromJSONTyped(json, false)
}

export function GetDisplayAdGroupsParamDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupsParamData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: GetDisplayAdGroupsParamDataAttributesFromJSON(json['attributes']),
  }
}

export function GetDisplayAdGroupsParamDataToJSON(value?: GetDisplayAdGroupsParamData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: GetDisplayAdGroupsParamDataAttributesToJSON(value.attributes),
  }
}
