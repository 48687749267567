// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiCampaignsControllerNegativeKeywordsInput
 */
export interface ApiCampaignsControllerNegativeKeywordsInput {
  /**
   * The campaign’s negative keywords
   * @type {Array<string>}
   * @memberof ApiCampaignsControllerNegativeKeywordsInput
   */
  negativeKeywords?: Array<string>
}

/**
 * Check if a given object implements the ApiCampaignsControllerNegativeKeywordsInput interface.
 */
export function instanceOfApiCampaignsControllerNegativeKeywordsInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiCampaignsControllerNegativeKeywordsInputFromJSON(
  json: any
): ApiCampaignsControllerNegativeKeywordsInput {
  return ApiCampaignsControllerNegativeKeywordsInputFromJSONTyped(json, false)
}

export function ApiCampaignsControllerNegativeKeywordsInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiCampaignsControllerNegativeKeywordsInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    negativeKeywords: !exists(json, 'negative_keywords') ? undefined : json['negative_keywords'],
  }
}

export function ApiCampaignsControllerNegativeKeywordsInputToJSON(
  value?: ApiCampaignsControllerNegativeKeywordsInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    negative_keywords: value.negativeKeywords,
  }
}
