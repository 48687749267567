// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostCampaignsBulkParamCampaigns } from './PostCampaignsBulkParamCampaigns'
import {
  PostCampaignsBulkParamCampaignsFromJSON,
  PostCampaignsBulkParamCampaignsFromJSONTyped,
  PostCampaignsBulkParamCampaignsToJSON,
} from './PostCampaignsBulkParamCampaigns'

/**
 *
 * @export
 * @interface ApiCampaignsControllerBulkCreateInput
 */
export interface ApiCampaignsControllerBulkCreateInput {
  /**
   * List of campaigns to create
   * @type {Array<PostCampaignsBulkParamCampaigns>}
   * @memberof ApiCampaignsControllerBulkCreateInput
   */
  campaigns: Array<PostCampaignsBulkParamCampaigns>
}

/**
 * Check if a given object implements the ApiCampaignsControllerBulkCreateInput interface.
 */
export function instanceOfApiCampaignsControllerBulkCreateInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'campaigns' in value

  return isInstance
}

export function ApiCampaignsControllerBulkCreateInputFromJSON(
  json: any
): ApiCampaignsControllerBulkCreateInput {
  return ApiCampaignsControllerBulkCreateInputFromJSONTyped(json, false)
}

export function ApiCampaignsControllerBulkCreateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiCampaignsControllerBulkCreateInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    campaigns: (json['campaigns'] as Array<any>).map(PostCampaignsBulkParamCampaignsFromJSON),
  }
}

export function ApiCampaignsControllerBulkCreateInputToJSON(
  value?: ApiCampaignsControllerBulkCreateInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    campaigns: (value.campaigns as Array<any>).map(PostCampaignsBulkParamCampaignsToJSON),
  }
}
