// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartData } from './GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartData'
import {
  GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataFromJSON,
  GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataFromJSONTyped,
  GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataToJSON,
} from './GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartData'

/**
 *
 * @export
 * @interface GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributes
 */
export interface GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributes {
  /**
   *
   * @type {number}
   * @memberof GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributes
   */
  suggestion?: number
  /**
   *
   * @type {number}
   * @memberof GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributes
   */
  dailyBudgetSuggestion?: number
  /**
   *
   * @type {number}
   * @memberof GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributes
   */
  lostImpressionsPercentage: number
  /**
   *
   * @type {number}
   * @memberof GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributes
   */
  lostSalesDollars?: number
  /**
   *
   * @type {GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartData}
   * @memberof GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributes
   */
  chartData: GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartData
  /**
   *
   * @type {boolean}
   * @memberof GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributes
   */
  isActive?: boolean
}

/**
 * Check if a given object implements the GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributes interface.
 */
export function instanceOfGetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributes(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'lostImpressionsPercentage' in value
  isInstance = isInstance && 'chartData' in value

  return isInstance
}

export function GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesFromJSON(
  json: any
): GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributes {
  return GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesFromJSONTyped(json, false)
}

export function GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    suggestion: !exists(json, 'suggestion') ? undefined : json['suggestion'],
    dailyBudgetSuggestion: !exists(json, 'daily_budget_suggestion')
      ? undefined
      : json['daily_budget_suggestion'],
    lostImpressionsPercentage: json['lost_impressions_percentage'],
    lostSalesDollars: !exists(json, 'lost_sales_dollars') ? undefined : json['lost_sales_dollars'],
    chartData: GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataFromJSON(
      json['chart_data']
    ),
    isActive: !exists(json, 'is_active') ? undefined : json['is_active'],
  }
}

export function GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesToJSON(
  value?: GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    suggestion: value.suggestion,
    daily_budget_suggestion: value.dailyBudgetSuggestion,
    lost_impressions_percentage: value.lostImpressionsPercentage,
    lost_sales_dollars: value.lostSalesDollars,
    chart_data: GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataToJSON(
      value.chartData
    ),
    is_active: value.isActive,
  }
}
