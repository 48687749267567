// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutCampaignsBulkParamCampaigns } from './PutCampaignsBulkParamCampaigns'
import {
  PutCampaignsBulkParamCampaignsFromJSON,
  PutCampaignsBulkParamCampaignsFromJSONTyped,
  PutCampaignsBulkParamCampaignsToJSON,
} from './PutCampaignsBulkParamCampaigns'

/**
 *
 * @export
 * @interface ApiCampaignsControllerBulkUpdateInput
 */
export interface ApiCampaignsControllerBulkUpdateInput {
  /**
   * List of campaigns to update
   * @type {Array<PutCampaignsBulkParamCampaigns>}
   * @memberof ApiCampaignsControllerBulkUpdateInput
   */
  campaigns: Array<PutCampaignsBulkParamCampaigns>
}

/**
 * Check if a given object implements the ApiCampaignsControllerBulkUpdateInput interface.
 */
export function instanceOfApiCampaignsControllerBulkUpdateInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'campaigns' in value

  return isInstance
}

export function ApiCampaignsControllerBulkUpdateInputFromJSON(
  json: any
): ApiCampaignsControllerBulkUpdateInput {
  return ApiCampaignsControllerBulkUpdateInputFromJSONTyped(json, false)
}

export function ApiCampaignsControllerBulkUpdateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiCampaignsControllerBulkUpdateInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    campaigns: (json['campaigns'] as Array<any>).map(PutCampaignsBulkParamCampaignsFromJSON),
  }
}

export function ApiCampaignsControllerBulkUpdateInputToJSON(
  value?: ApiCampaignsControllerBulkUpdateInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    campaigns: (value.campaigns as Array<any>).map(PutCampaignsBulkParamCampaignsToJSON),
  }
}
