// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayAdGroupsParamAssetFieldDecisionsAltText } from './GetDisplayAdGroupsParamAssetFieldDecisionsAltText'
import {
  GetDisplayAdGroupsParamAssetFieldDecisionsAltTextFromJSON,
  GetDisplayAdGroupsParamAssetFieldDecisionsAltTextFromJSONTyped,
  GetDisplayAdGroupsParamAssetFieldDecisionsAltTextToJSON,
} from './GetDisplayAdGroupsParamAssetFieldDecisionsAltText'
import type { GetDisplayAdGroupsParamAssetFieldDecisionsImage } from './GetDisplayAdGroupsParamAssetFieldDecisionsImage'
import {
  GetDisplayAdGroupsParamAssetFieldDecisionsImageFromJSON,
  GetDisplayAdGroupsParamAssetFieldDecisionsImageFromJSONTyped,
  GetDisplayAdGroupsParamAssetFieldDecisionsImageToJSON,
} from './GetDisplayAdGroupsParamAssetFieldDecisionsImage'
import type { GetDisplayAdGroupsParamAssetFieldDecisionsVideo } from './GetDisplayAdGroupsParamAssetFieldDecisionsVideo'
import {
  GetDisplayAdGroupsParamAssetFieldDecisionsVideoFromJSON,
  GetDisplayAdGroupsParamAssetFieldDecisionsVideoFromJSONTyped,
  GetDisplayAdGroupsParamAssetFieldDecisionsVideoToJSON,
} from './GetDisplayAdGroupsParamAssetFieldDecisionsVideo'

/**
 *
 * @export
 * @interface GetDisplayAdGroupsParamAssetFieldDecisions
 */
export interface GetDisplayAdGroupsParamAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetDisplayAdGroupsParamAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {GetDisplayAdGroupsParamAssetFieldDecisionsAltText}
   * @memberof GetDisplayAdGroupsParamAssetFieldDecisions
   */
  altText?: GetDisplayAdGroupsParamAssetFieldDecisionsAltText
  /**
   *
   * @type {GetDisplayAdGroupsParamAssetFieldDecisionsImage}
   * @memberof GetDisplayAdGroupsParamAssetFieldDecisions
   */
  image?: GetDisplayAdGroupsParamAssetFieldDecisionsImage
  /**
   *
   * @type {GetDisplayAdGroupsParamAssetFieldDecisionsVideo}
   * @memberof GetDisplayAdGroupsParamAssetFieldDecisions
   */
  video?: GetDisplayAdGroupsParamAssetFieldDecisionsVideo
}

/**
 * Check if a given object implements the GetDisplayAdGroupsParamAssetFieldDecisions interface.
 */
export function instanceOfGetDisplayAdGroupsParamAssetFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function GetDisplayAdGroupsParamAssetFieldDecisionsFromJSON(
  json: any
): GetDisplayAdGroupsParamAssetFieldDecisions {
  return GetDisplayAdGroupsParamAssetFieldDecisionsFromJSONTyped(json, false)
}

export function GetDisplayAdGroupsParamAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupsParamAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : GetDisplayAdGroupsParamAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : GetDisplayAdGroupsParamAssetFieldDecisionsImageFromJSON(json['image']),
    video: !exists(json, 'video')
      ? undefined
      : GetDisplayAdGroupsParamAssetFieldDecisionsVideoFromJSON(json['video']),
  }
}

export function GetDisplayAdGroupsParamAssetFieldDecisionsToJSON(
  value?: GetDisplayAdGroupsParamAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    alt_text: GetDisplayAdGroupsParamAssetFieldDecisionsAltTextToJSON(value.altText),
    image: GetDisplayAdGroupsParamAssetFieldDecisionsImageToJSON(value.image),
    video: GetDisplayAdGroupsParamAssetFieldDecisionsVideoToJSON(value.video),
  }
}
