// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClick } from './GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClick'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClickFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClickFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClickToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClick'
import type { GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClick } from './GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClick'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClickFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClickFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClickToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClick'
import type { GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClick } from './GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClick'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClickFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClickFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClickToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClick'

/**
 *
 * @export
 * @interface GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClick
 */
export interface GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClick {
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClick}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClick
   */
  brandPageClick?: GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClick
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClick}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClick
   */
  urlClick?: GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClick
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClick}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClick
   */
  recipeClick?: GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClick
}

/**
 * Check if a given object implements the GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClick interface.
 */
export function instanceOfGetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClick(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickFromJSON(
  json: any
): GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClick {
  return GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickFromJSONTyped(json, false)
}

export function GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClick {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandPageClick: !exists(json, 'brand_page_click')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClickFromJSON(
          json['brand_page_click']
        ),
    urlClick: !exists(json, 'url_click')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClickFromJSON(
          json['url_click']
        ),
    recipeClick: !exists(json, 'recipe_click')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClickFromJSON(
          json['recipe_click']
        ),
  }
}

export function GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickToJSON(
  value?: GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClick | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_page_click:
      GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickBrandPageClickToJSON(
        value.brandPageClick
      ),
    url_click: GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickUrlClickToJSON(
      value.urlClick
    ),
    recipe_click: GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickRecipeClickToJSON(
      value.recipeClick
    ),
  }
}
