// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostCampaignResponseDataRelationshipsAdGroupBids } from './PostCampaignResponseDataRelationshipsAdGroupBids'
import {
  PostCampaignResponseDataRelationshipsAdGroupBidsFromJSON,
  PostCampaignResponseDataRelationshipsAdGroupBidsFromJSONTyped,
  PostCampaignResponseDataRelationshipsAdGroupBidsToJSON,
} from './PostCampaignResponseDataRelationshipsAdGroupBids'

/**
 *
 * @export
 * @interface PostCampaignResponseDataRelationships
 */
export interface PostCampaignResponseDataRelationships {
  /**
   *
   * @type {PostCampaignResponseDataRelationshipsAdGroupBids}
   * @memberof PostCampaignResponseDataRelationships
   */
  adGroupBids?: PostCampaignResponseDataRelationshipsAdGroupBids
}

/**
 * Check if a given object implements the PostCampaignResponseDataRelationships interface.
 */
export function instanceOfPostCampaignResponseDataRelationships(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PostCampaignResponseDataRelationshipsFromJSON(
  json: any
): PostCampaignResponseDataRelationships {
  return PostCampaignResponseDataRelationshipsFromJSONTyped(json, false)
}

export function PostCampaignResponseDataRelationshipsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostCampaignResponseDataRelationships {
  if (json === undefined || json === null) {
    return json
  }
  return {
    adGroupBids: !exists(json, 'ad_group_bids')
      ? undefined
      : PostCampaignResponseDataRelationshipsAdGroupBidsFromJSON(json['ad_group_bids']),
  }
}

export function PostCampaignResponseDataRelationshipsToJSON(
  value?: PostCampaignResponseDataRelationships | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    ad_group_bids: PostCampaignResponseDataRelationshipsAdGroupBidsToJSON(value.adGroupBids),
  }
}
