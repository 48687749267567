// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdGroupsSumResponseDataAttributesAnalytics } from './GetAdGroupsSumResponseDataAttributesAnalytics'
import {
  GetAdGroupsSumResponseDataAttributesAnalyticsFromJSON,
  GetAdGroupsSumResponseDataAttributesAnalyticsFromJSONTyped,
  GetAdGroupsSumResponseDataAttributesAnalyticsToJSON,
} from './GetAdGroupsSumResponseDataAttributesAnalytics'

/**
 *
 * @export
 * @interface GetAdGroupsSumResponseDataAttributes
 */
export interface GetAdGroupsSumResponseDataAttributes {
  /**
   *
   * @type {GetAdGroupsSumResponseDataAttributesAnalytics}
   * @memberof GetAdGroupsSumResponseDataAttributes
   */
  analytics?: GetAdGroupsSumResponseDataAttributesAnalytics
}

/**
 * Check if a given object implements the GetAdGroupsSumResponseDataAttributes interface.
 */
export function instanceOfGetAdGroupsSumResponseDataAttributes(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function GetAdGroupsSumResponseDataAttributesFromJSON(
  json: any
): GetAdGroupsSumResponseDataAttributes {
  return GetAdGroupsSumResponseDataAttributesFromJSONTyped(json, false)
}

export function GetAdGroupsSumResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdGroupsSumResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    analytics: !exists(json, 'analytics')
      ? undefined
      : GetAdGroupsSumResponseDataAttributesAnalyticsFromJSON(json['analytics']),
  }
}

export function GetAdGroupsSumResponseDataAttributesToJSON(
  value?: GetAdGroupsSumResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    analytics: GetAdGroupsSumResponseDataAttributesAnalyticsToJSON(value.analytics),
  }
}
