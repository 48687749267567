// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PostAdGroupsBulkParamAdGroups
 */
export interface PostAdGroupsBulkParamAdGroups {
  /**
   * Specify the ID of the campaign you want to create the ad group under. Ad groups must belong to a campaign
   * @type {string}
   * @memberof PostAdGroupsBulkParamAdGroups
   */
  campaignId: string
  /**
   * Specify a value to update the name of the ad group.
   * @type {string}
   * @memberof PostAdGroupsBulkParamAdGroups
   */
  name: string
  /**
   * Set to true to activate the ad group. Set to false to deactivate the ad group. Set to false to create an inactive ad group.
   * @type {boolean}
   * @memberof PostAdGroupsBulkParamAdGroups
   */
  enabled?: boolean
  /**
   * The maximum amount, in dollars, you want to pay per click for advertised products in this ad group.
   * @type {number}
   * @memberof PostAdGroupsBulkParamAdGroups
   */
  defaultBid?: number
  /**
   * Optionally, specify the placement targeting type for this ad group. Currently supported values: ['impulse', 'default']. Set to "impulse" if you want to create an impulse ad group.
   * @type {string}
   * @memberof PostAdGroupsBulkParamAdGroups
   */
  targetingType?: PostAdGroupsBulkParamAdGroupsTargetingTypeEnum
}

/**
 * @export
 */
export const PostAdGroupsBulkParamAdGroupsTargetingTypeEnum = {
  Impulse: 'impulse',
  Default: 'default',
} as const
export type PostAdGroupsBulkParamAdGroupsTargetingTypeEnum =
  typeof PostAdGroupsBulkParamAdGroupsTargetingTypeEnum[keyof typeof PostAdGroupsBulkParamAdGroupsTargetingTypeEnum]

/**
 * Check if a given object implements the PostAdGroupsBulkParamAdGroups interface.
 */
export function instanceOfPostAdGroupsBulkParamAdGroups(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'campaignId' in value
  isInstance = isInstance && 'name' in value

  return isInstance
}

export function PostAdGroupsBulkParamAdGroupsFromJSON(json: any): PostAdGroupsBulkParamAdGroups {
  return PostAdGroupsBulkParamAdGroupsFromJSONTyped(json, false)
}

export function PostAdGroupsBulkParamAdGroupsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAdGroupsBulkParamAdGroups {
  if (json === undefined || json === null) {
    return json
  }
  return {
    campaignId: json['campaign_id'],
    name: json['name'],
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
    defaultBid: !exists(json, 'default_bid') ? undefined : json['default_bid'],
    targetingType: !exists(json, 'targeting_type') ? undefined : json['targeting_type'],
  }
}

export function PostAdGroupsBulkParamAdGroupsToJSON(
  value?: PostAdGroupsBulkParamAdGroups | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    campaign_id: value.campaignId,
    name: value.name,
    enabled: value.enabled,
    default_bid: value.defaultBid,
    targeting_type: value.targetingType,
  }
}
