// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiAdGroupsControllerCreateInputAdGroupUserTargeting } from './ApiAdGroupsControllerCreateInputAdGroupUserTargeting'
import {
  ApiAdGroupsControllerCreateInputAdGroupUserTargetingFromJSON,
  ApiAdGroupsControllerCreateInputAdGroupUserTargetingFromJSONTyped,
  ApiAdGroupsControllerCreateInputAdGroupUserTargetingToJSON,
} from './ApiAdGroupsControllerCreateInputAdGroupUserTargeting'

/**
 *
 * @export
 * @interface ApiAdGroupsControllerCreateInputAdGroup
 */
export interface ApiAdGroupsControllerCreateInputAdGroup {
  /**
   * Specify a descriptive name for the ad group.
   * @type {string}
   * @memberof ApiAdGroupsControllerCreateInputAdGroup
   */
  name: string
  /**
   * Set to true to create an active ad group. Ads are only run for active ad groups with at least one product which belong to active, running campaigns. Set to false to create an inactive ad group.
   * @type {boolean}
   * @memberof ApiAdGroupsControllerCreateInputAdGroup
   */
  enabled?: boolean
  /**
   * Optionally, specify the placement targeting type for this ad group. Currently supported values: ['impulse', 'default']. Set to "impulse" if you want to create an impulse ad group.
   * @type {string}
   * @memberof ApiAdGroupsControllerCreateInputAdGroup
   */
  targetingType?: ApiAdGroupsControllerCreateInputAdGroupTargetingTypeEnum
  /**
   * Set to true to enable branded keywords opt-out
   * @type {boolean}
   * @memberof ApiAdGroupsControllerCreateInputAdGroup
   */
  brandedKeywordsOptOutEnabled?: boolean
  /**
   *
   * @type {ApiAdGroupsControllerCreateInputAdGroupUserTargeting}
   * @memberof ApiAdGroupsControllerCreateInputAdGroup
   */
  userTargeting?: ApiAdGroupsControllerCreateInputAdGroupUserTargeting
  /**
   * The maximum amount, in dollars, you want to pay per click for advertised products in this ad group.
   * May be `null` for campaigns with `optimized_bidding_enabled = true` and must not be null otherwise
   * @type {number}
   * @memberof ApiAdGroupsControllerCreateInputAdGroup
   */
  defaultBid?: number
}

/**
 * @export
 */
export const ApiAdGroupsControllerCreateInputAdGroupTargetingTypeEnum = {
  Impulse: 'impulse',
  Default: 'default',
} as const
export type ApiAdGroupsControllerCreateInputAdGroupTargetingTypeEnum =
  typeof ApiAdGroupsControllerCreateInputAdGroupTargetingTypeEnum[keyof typeof ApiAdGroupsControllerCreateInputAdGroupTargetingTypeEnum]

/**
 * Check if a given object implements the ApiAdGroupsControllerCreateInputAdGroup interface.
 */
export function instanceOfApiAdGroupsControllerCreateInputAdGroup(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value

  return isInstance
}

export function ApiAdGroupsControllerCreateInputAdGroupFromJSON(
  json: any
): ApiAdGroupsControllerCreateInputAdGroup {
  return ApiAdGroupsControllerCreateInputAdGroupFromJSONTyped(json, false)
}

export function ApiAdGroupsControllerCreateInputAdGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdGroupsControllerCreateInputAdGroup {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
    targetingType: !exists(json, 'targeting_type') ? undefined : json['targeting_type'],
    brandedKeywordsOptOutEnabled: !exists(json, 'branded_keywords_opt_out_enabled')
      ? undefined
      : json['branded_keywords_opt_out_enabled'],
    userTargeting: !exists(json, 'user_targeting')
      ? undefined
      : ApiAdGroupsControllerCreateInputAdGroupUserTargetingFromJSON(json['user_targeting']),
    defaultBid: !exists(json, 'default_bid') ? undefined : json['default_bid'],
  }
}

export function ApiAdGroupsControllerCreateInputAdGroupToJSON(
  value?: ApiAdGroupsControllerCreateInputAdGroup | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    enabled: value.enabled,
    targeting_type: value.targetingType,
    branded_keywords_opt_out_enabled: value.brandedKeywordsOptOutEnabled,
    user_targeting: ApiAdGroupsControllerCreateInputAdGroupUserTargetingToJSON(value.userTargeting),
    default_bid: value.defaultBid,
  }
}
