// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutCampaignResponseDataAttributesAnalytics } from './PutCampaignResponseDataAttributesAnalytics'
import {
  PutCampaignResponseDataAttributesAnalyticsFromJSON,
  PutCampaignResponseDataAttributesAnalyticsFromJSONTyped,
  PutCampaignResponseDataAttributesAnalyticsToJSON,
} from './PutCampaignResponseDataAttributesAnalytics'
import type { PutCampaignResponseDataAttributesAutomatedBiddingParameters } from './PutCampaignResponseDataAttributesAutomatedBiddingParameters'
import {
  PutCampaignResponseDataAttributesAutomatedBiddingParametersFromJSON,
  PutCampaignResponseDataAttributesAutomatedBiddingParametersFromJSONTyped,
  PutCampaignResponseDataAttributesAutomatedBiddingParametersToJSON,
} from './PutCampaignResponseDataAttributesAutomatedBiddingParameters'
import type { PutCampaignResponseDataAttributesOptimizedBiddingParameters } from './PutCampaignResponseDataAttributesOptimizedBiddingParameters'
import {
  PutCampaignResponseDataAttributesOptimizedBiddingParametersFromJSON,
  PutCampaignResponseDataAttributesOptimizedBiddingParametersFromJSONTyped,
  PutCampaignResponseDataAttributesOptimizedBiddingParametersToJSON,
} from './PutCampaignResponseDataAttributesOptimizedBiddingParameters'

/**
 *
 * @export
 * @interface PutCampaignResponseDataAttributes
 */
export interface PutCampaignResponseDataAttributes {
  /**
   * Campaign Spend
   * @type {number}
   * @memberof PutCampaignResponseDataAttributes
   */
  spend: number
  /**
   * A descriptive name for the campaign.
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  name: string
  /**
   * Set to true to activate the campaign. You will bid according to the details of the ad groups contained within this campaign. Set to false and the campaign is not active. This field cannot be set to true for a campaign in draft state. Draft campaign can only be launched from Instacart Ads Manager.
   * @type {boolean}
   * @memberof PutCampaignResponseDataAttributes
   */
  enabled: boolean
  /**
   * The campaign’s start date in iso8601 format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  scheduledStartAt: string
  /**
   * The campaign's effective end date in iso8601 format. Campaigns end at midnight, PST, on the specified date. The campaign only stops when the campaign's budget has been spent.
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  scheduledEndAt: string
  /**
   * The campaign’s start date in the 'YYYY-MM-DD' format. Campaigns start midnight, PST, on the specified date.
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  scheduledStartAtDate: string
  /**
   * The campaign's effective end date in the 'YYYY-MM-DD' format. Campaigns end at midnight, PST, on the specified date. The campaign only stops when the campaign’s budget has been spent.
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  scheduledEndAtDate: string
  /**
   * Send "featured_product" for this field. We currently only support one type of ad campaign.
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  campaignType: string
  /**
   * The total budget for the ads campaign, expressed in dollars. When the campaign has spent this budget, it automatically ends.
   * @type {number}
   * @memberof PutCampaignResponseDataAttributes
   */
  budget: number
  /**
   * Campaign Status
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  campaignStatus: string
  /**
   * Campaign risk status
   * @type {{ [key: string]: any; }}
   * @memberof PutCampaignResponseDataAttributes
   */
  atRiskMeta: { [key: string]: any }
  /**
   * The budgeting strategy for the campaign. One of "daily_budget", "max_impressions".
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  budgetType: PutCampaignResponseDataAttributesBudgetTypeEnum
  /**
   * The daily budget for a v2 campaign, expressed in dollars.
   * @type {number}
   * @memberof PutCampaignResponseDataAttributes
   */
  targetDailyBudget?: number
  /**
   * Puchase Order #
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  purchaseOrder: string
  /**
   * Objective of the campaign
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  objective: string
  /**
   * Custom objective of the campaign
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  objectiveCustom: string
  /**
   * Memo for the campaign
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  memo: string
  /**
   * How you pay for the campaign
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  paymentType: string
  /**
   * Enables optimized bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof PutCampaignResponseDataAttributes
   */
  optimizedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  optimizedBiddingGoal?: PutCampaignResponseDataAttributesOptimizedBiddingGoalEnum
  /**
   *
   * @type {PutCampaignResponseDataAttributesOptimizedBiddingParameters}
   * @memberof PutCampaignResponseDataAttributes
   */
  optimizedBiddingParameters?: PutCampaignResponseDataAttributesOptimizedBiddingParameters
  /**
   * The campaign’s goal.
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  goal?: string
  /**
   * The campaign’s goal format.
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  goalFormat?: string
  /**
   *
   * @type {PutCampaignResponseDataAttributesAnalytics}
   * @memberof PutCampaignResponseDataAttributes
   */
  analytics: PutCampaignResponseDataAttributesAnalytics
  /**
   * Override for default account-level bill payer. Agency, advertiser or retailer to be billed for the campaign.
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  billPayer?: PutCampaignResponseDataAttributesBillPayerEnum
  /**
   * The campaign’s negative keywords
   * @type {Array<string>}
   * @memberof PutCampaignResponseDataAttributes
   */
  negativeKeywords?: Array<string>
  /**
   * Enables automated bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof PutCampaignResponseDataAttributes
   */
  automatedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof PutCampaignResponseDataAttributes
   */
  automatedBiddingGoal?: PutCampaignResponseDataAttributesAutomatedBiddingGoalEnum
  /**
   *
   * @type {PutCampaignResponseDataAttributesAutomatedBiddingParameters}
   * @memberof PutCampaignResponseDataAttributes
   */
  automatedBiddingParameters?: PutCampaignResponseDataAttributesAutomatedBiddingParameters
}

/**
 * @export
 */
export const PutCampaignResponseDataAttributesBudgetTypeEnum = {
  DailyBudget: 'daily_budget',
  PacedSpending: 'paced_spending',
  MaxImpressions: 'max_impressions',
  Null: 'null',
} as const
export type PutCampaignResponseDataAttributesBudgetTypeEnum =
  typeof PutCampaignResponseDataAttributesBudgetTypeEnum[keyof typeof PutCampaignResponseDataAttributesBudgetTypeEnum]

/**
 * @export
 */
export const PutCampaignResponseDataAttributesOptimizedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type PutCampaignResponseDataAttributesOptimizedBiddingGoalEnum =
  typeof PutCampaignResponseDataAttributesOptimizedBiddingGoalEnum[keyof typeof PutCampaignResponseDataAttributesOptimizedBiddingGoalEnum]

/**
 * @export
 */
export const PutCampaignResponseDataAttributesBillPayerEnum = {
  Agency: 'agency',
  Advertiser: 'advertiser',
  Retailer: 'retailer',
} as const
export type PutCampaignResponseDataAttributesBillPayerEnum =
  typeof PutCampaignResponseDataAttributesBillPayerEnum[keyof typeof PutCampaignResponseDataAttributesBillPayerEnum]

/**
 * @export
 */
export const PutCampaignResponseDataAttributesAutomatedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type PutCampaignResponseDataAttributesAutomatedBiddingGoalEnum =
  typeof PutCampaignResponseDataAttributesAutomatedBiddingGoalEnum[keyof typeof PutCampaignResponseDataAttributesAutomatedBiddingGoalEnum]

/**
 * Check if a given object implements the PutCampaignResponseDataAttributes interface.
 */
export function instanceOfPutCampaignResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'spend' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'scheduledStartAt' in value
  isInstance = isInstance && 'scheduledEndAt' in value
  isInstance = isInstance && 'scheduledStartAtDate' in value
  isInstance = isInstance && 'scheduledEndAtDate' in value
  isInstance = isInstance && 'campaignType' in value
  isInstance = isInstance && 'budget' in value
  isInstance = isInstance && 'campaignStatus' in value
  isInstance = isInstance && 'atRiskMeta' in value
  isInstance = isInstance && 'budgetType' in value
  isInstance = isInstance && 'purchaseOrder' in value
  isInstance = isInstance && 'objective' in value
  isInstance = isInstance && 'objectiveCustom' in value
  isInstance = isInstance && 'memo' in value
  isInstance = isInstance && 'paymentType' in value
  isInstance = isInstance && 'analytics' in value

  return isInstance
}

export function PutCampaignResponseDataAttributesFromJSON(
  json: any
): PutCampaignResponseDataAttributes {
  return PutCampaignResponseDataAttributesFromJSONTyped(json, false)
}

export function PutCampaignResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutCampaignResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    spend: json['spend'],
    name: json['name'],
    enabled: json['enabled'],
    scheduledStartAt: json['scheduled_start_at'],
    scheduledEndAt: json['scheduled_end_at'],
    scheduledStartAtDate: json['scheduled_start_at_date'],
    scheduledEndAtDate: json['scheduled_end_at_date'],
    campaignType: json['campaign_type'],
    budget: json['budget'],
    campaignStatus: json['campaign_status'],
    atRiskMeta: json['at_risk_meta'],
    budgetType: json['budget_type'],
    targetDailyBudget: !exists(json, 'target_daily_budget')
      ? undefined
      : json['target_daily_budget'],
    purchaseOrder: json['purchase_order'],
    objective: json['objective'],
    objectiveCustom: json['objective_custom'],
    memo: json['memo'],
    paymentType: json['payment_type'],
    optimizedBiddingEnabled: !exists(json, 'optimized_bidding_enabled')
      ? undefined
      : json['optimized_bidding_enabled'],
    optimizedBiddingGoal: !exists(json, 'optimized_bidding_goal')
      ? undefined
      : json['optimized_bidding_goal'],
    optimizedBiddingParameters: !exists(json, 'optimized_bidding_parameters')
      ? undefined
      : PutCampaignResponseDataAttributesOptimizedBiddingParametersFromJSON(
          json['optimized_bidding_parameters']
        ),
    goal: !exists(json, 'goal') ? undefined : json['goal'],
    goalFormat: !exists(json, 'goal_format') ? undefined : json['goal_format'],
    analytics: PutCampaignResponseDataAttributesAnalyticsFromJSON(json['analytics']),
    billPayer: !exists(json, 'bill_payer') ? undefined : json['bill_payer'],
    negativeKeywords: !exists(json, 'negative_keywords') ? undefined : json['negative_keywords'],
    automatedBiddingEnabled: !exists(json, 'automated_bidding_enabled')
      ? undefined
      : json['automated_bidding_enabled'],
    automatedBiddingGoal: !exists(json, 'automated_bidding_goal')
      ? undefined
      : json['automated_bidding_goal'],
    automatedBiddingParameters: !exists(json, 'automated_bidding_parameters')
      ? undefined
      : PutCampaignResponseDataAttributesAutomatedBiddingParametersFromJSON(
          json['automated_bidding_parameters']
        ),
  }
}

export function PutCampaignResponseDataAttributesToJSON(
  value?: PutCampaignResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    spend: value.spend,
    name: value.name,
    enabled: value.enabled,
    scheduled_start_at: value.scheduledStartAt,
    scheduled_end_at: value.scheduledEndAt,
    scheduled_start_at_date: value.scheduledStartAtDate,
    scheduled_end_at_date: value.scheduledEndAtDate,
    campaign_type: value.campaignType,
    budget: value.budget,
    campaign_status: value.campaignStatus,
    at_risk_meta: value.atRiskMeta,
    budget_type: value.budgetType,
    target_daily_budget: value.targetDailyBudget,
    purchase_order: value.purchaseOrder,
    objective: value.objective,
    objective_custom: value.objectiveCustom,
    memo: value.memo,
    payment_type: value.paymentType,
    optimized_bidding_enabled: value.optimizedBiddingEnabled,
    optimized_bidding_goal: value.optimizedBiddingGoal,
    optimized_bidding_parameters: PutCampaignResponseDataAttributesOptimizedBiddingParametersToJSON(
      value.optimizedBiddingParameters
    ),
    goal: value.goal,
    goal_format: value.goalFormat,
    analytics: PutCampaignResponseDataAttributesAnalyticsToJSON(value.analytics),
    bill_payer: value.billPayer,
    negative_keywords: value.negativeKeywords,
    automated_bidding_enabled: value.automatedBiddingEnabled,
    automated_bidding_goal: value.automatedBiddingGoal,
    automated_bidding_parameters: PutCampaignResponseDataAttributesAutomatedBiddingParametersToJSON(
      value.automatedBiddingParameters
    ),
  }
}
