// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch } from './GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch'
import {
  GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouchFromJSON,
  GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch'
import type { GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLinear } from './GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLinear'
import {
  GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLinearFromJSON,
  GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLinearToJSON,
} from './GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionData
 */
export interface GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionData {
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLinear}
   * @memberof GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionData
   */
  linear: GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch}
   * @memberof GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionData
   */
  lastTouch?: GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionData interface.
 */
export function instanceOfGetDisplayAdGroupsParamDataAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataFromJSON(
  json: any
): GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionData {
  return GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataFromJSONTyped(json, false)
}

export function GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear: GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLinearFromJSON(
      json['linear']
    ),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataToJSON(
  value?: GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear: GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLinearToJSON(value.linear),
    last_touch: GetDisplayAdGroupsParamDataAttributesAnalyticsAttributionDataLastTouchToJSON(
      value.lastTouch
    ),
  }
}
