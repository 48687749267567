// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdGroupAnalyticsResponseDataAttributes } from './GetAdGroupAnalyticsResponseDataAttributes'
import {
  GetAdGroupAnalyticsResponseDataAttributesFromJSON,
  GetAdGroupAnalyticsResponseDataAttributesFromJSONTyped,
  GetAdGroupAnalyticsResponseDataAttributesToJSON,
} from './GetAdGroupAnalyticsResponseDataAttributes'

/**
 *
 * @export
 * @interface GetAdGroupAnalyticsResponseData
 */
export interface GetAdGroupAnalyticsResponseData {
  /**
   * Chart name
   * @type {string}
   * @memberof GetAdGroupAnalyticsResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetAdGroupAnalyticsResponseData
   */
  type: GetAdGroupAnalyticsResponseDataTypeEnum
  /**
   *
   * @type {GetAdGroupAnalyticsResponseDataAttributes}
   * @memberof GetAdGroupAnalyticsResponseData
   */
  attributes: GetAdGroupAnalyticsResponseDataAttributes
}

/**
 * @export
 */
export const GetAdGroupAnalyticsResponseDataTypeEnum = {
  LineChart: 'line_chart',
} as const
export type GetAdGroupAnalyticsResponseDataTypeEnum =
  typeof GetAdGroupAnalyticsResponseDataTypeEnum[keyof typeof GetAdGroupAnalyticsResponseDataTypeEnum]

/**
 * Check if a given object implements the GetAdGroupAnalyticsResponseData interface.
 */
export function instanceOfGetAdGroupAnalyticsResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function GetAdGroupAnalyticsResponseDataFromJSON(
  json: any
): GetAdGroupAnalyticsResponseData {
  return GetAdGroupAnalyticsResponseDataFromJSONTyped(json, false)
}

export function GetAdGroupAnalyticsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdGroupAnalyticsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: GetAdGroupAnalyticsResponseDataAttributesFromJSON(json['attributes']),
  }
}

export function GetAdGroupAnalyticsResponseDataToJSON(
  value?: GetAdGroupAnalyticsResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: GetAdGroupAnalyticsResponseDataAttributesToJSON(value.attributes),
  }
}
