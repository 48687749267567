// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetCampaignsResponseDataAttributesSupplementaryStatuses
 */
export interface GetCampaignsResponseDataAttributesSupplementaryStatuses {
  /**
   * Returns true if the campaign has any ad groups that have products with missing brand hierarchy.
   * Only available for Sponsored Product Optimized Bidding campaigns having an optimized_bidding_goal of `acquire_ntb`.
   * @type {boolean}
   * @memberof GetCampaignsResponseDataAttributesSupplementaryStatuses
   */
  hasProductsWithMissingBrandHierarchy?: boolean
}

/**
 * Check if a given object implements the GetCampaignsResponseDataAttributesSupplementaryStatuses interface.
 */
export function instanceOfGetCampaignsResponseDataAttributesSupplementaryStatuses(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetCampaignsResponseDataAttributesSupplementaryStatusesFromJSON(
  json: any
): GetCampaignsResponseDataAttributesSupplementaryStatuses {
  return GetCampaignsResponseDataAttributesSupplementaryStatusesFromJSONTyped(json, false)
}

export function GetCampaignsResponseDataAttributesSupplementaryStatusesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignsResponseDataAttributesSupplementaryStatuses {
  if (json === undefined || json === null) {
    return json
  }
  return {
    hasProductsWithMissingBrandHierarchy: !exists(json, 'has_products_with_missing_brand_hierarchy')
      ? undefined
      : json['has_products_with_missing_brand_hierarchy'],
  }
}

export function GetCampaignsResponseDataAttributesSupplementaryStatusesToJSON(
  value?: GetCampaignsResponseDataAttributesSupplementaryStatuses | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    has_products_with_missing_brand_hierarchy: value.hasProductsWithMissingBrandHierarchy,
  }
}
