// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClick } from './GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClick'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClick'
import type { GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid } from './GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid'
import type { GetDisplayAdGroupsParamDataAttributesCreativeActionsMainItemGrid } from './GetDisplayAdGroupsParamDataAttributesCreativeActionsMainItemGrid'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeActionsMainItemGridFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsMainItemGridFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsMainItemGridToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeActionsMainItemGrid'
import type { GetDisplayAdGroupsParamDataAttributesCreativeActionsMainProductGroupGrid } from './GetDisplayAdGroupsParamDataAttributesCreativeActionsMainProductGroupGrid'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeActionsMainProductGroupGridFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsMainProductGroupGridFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsMainProductGroupGridToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeActionsMainProductGroupGrid'

/**
 *
 * @export
 * @interface GetDisplayAdGroupsParamDataAttributesCreativeActions
 */
export interface GetDisplayAdGroupsParamDataAttributesCreativeActions {
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeActionsMainItemGrid}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeActions
   */
  mainItemGrid?: GetDisplayAdGroupsParamDataAttributesCreativeActionsMainItemGrid
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeActionsMainProductGroupGrid}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeActions
   */
  mainProductGroupGrid?: GetDisplayAdGroupsParamDataAttributesCreativeActionsMainProductGroupGrid
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeActions
   */
  mainCollectionGrid?: GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClick}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeActions
   */
  bannerClick?: GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClick
}

/**
 * Check if a given object implements the GetDisplayAdGroupsParamDataAttributesCreativeActions interface.
 */
export function instanceOfGetDisplayAdGroupsParamDataAttributesCreativeActions(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetDisplayAdGroupsParamDataAttributesCreativeActionsFromJSON(
  json: any
): GetDisplayAdGroupsParamDataAttributesCreativeActions {
  return GetDisplayAdGroupsParamDataAttributesCreativeActionsFromJSONTyped(json, false)
}

export function GetDisplayAdGroupsParamDataAttributesCreativeActionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupsParamDataAttributesCreativeActions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mainItemGrid: !exists(json, 'main_item_grid')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeActionsMainItemGridFromJSON(
          json['main_item_grid']
        ),
    mainProductGroupGrid: !exists(json, 'main_product_group_grid')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeActionsMainProductGroupGridFromJSON(
          json['main_product_group_grid']
        ),
    mainCollectionGrid: !exists(json, 'main_collection_grid')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridFromJSON(
          json['main_collection_grid']
        ),
    bannerClick: !exists(json, 'banner_click')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickFromJSON(
          json['banner_click']
        ),
  }
}

export function GetDisplayAdGroupsParamDataAttributesCreativeActionsToJSON(
  value?: GetDisplayAdGroupsParamDataAttributesCreativeActions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main_item_grid: GetDisplayAdGroupsParamDataAttributesCreativeActionsMainItemGridToJSON(
      value.mainItemGrid
    ),
    main_product_group_grid:
      GetDisplayAdGroupsParamDataAttributesCreativeActionsMainProductGroupGridToJSON(
        value.mainProductGroupGrid
      ),
    main_collection_grid:
      GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridToJSON(
        value.mainCollectionGrid
      ),
    banner_click: GetDisplayAdGroupsParamDataAttributesCreativeActionsBannerClickToJSON(
      value.bannerClick
    ),
  }
}
