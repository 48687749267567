// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdGroupAnalyticsResponseDataAttributesSeries } from './GetAdGroupAnalyticsResponseDataAttributesSeries'
import {
  GetAdGroupAnalyticsResponseDataAttributesSeriesFromJSON,
  GetAdGroupAnalyticsResponseDataAttributesSeriesFromJSONTyped,
  GetAdGroupAnalyticsResponseDataAttributesSeriesToJSON,
} from './GetAdGroupAnalyticsResponseDataAttributesSeries'
import type { GetAdGroupsIdAnalyticsParamDataPoint } from './GetAdGroupsIdAnalyticsParamDataPoint'
import {
  GetAdGroupsIdAnalyticsParamDataPointFromJSON,
  GetAdGroupsIdAnalyticsParamDataPointFromJSONTyped,
  GetAdGroupsIdAnalyticsParamDataPointToJSON,
} from './GetAdGroupsIdAnalyticsParamDataPoint'

/**
 *
 * @export
 * @interface GetAdGroupAnalyticsResponseDataAttributes
 */
export interface GetAdGroupAnalyticsResponseDataAttributes {
  /**
   * Chart metric
   * @type {string}
   * @memberof GetAdGroupAnalyticsResponseDataAttributes
   */
  metric: string
  /**
   *
   * @type {Array<GetAdGroupAnalyticsResponseDataAttributesSeries>}
   * @memberof GetAdGroupAnalyticsResponseDataAttributes
   */
  series: Array<GetAdGroupAnalyticsResponseDataAttributesSeries>
  /**
   * Chart series data
   * @type {Array<Array<GetAdGroupsIdAnalyticsParamDataPoint>>}
   * @memberof GetAdGroupAnalyticsResponseDataAttributes
   */
  data: Array<Array<GetAdGroupsIdAnalyticsParamDataPoint>>
  /**
   * Keys to obscure
   * @type {Array<string>}
   * @memberof GetAdGroupAnalyticsResponseDataAttributes
   */
  keysToObscure: Array<string>
}

/**
 * Check if a given object implements the GetAdGroupAnalyticsResponseDataAttributes interface.
 */
export function instanceOfGetAdGroupAnalyticsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'metric' in value
  isInstance = isInstance && 'series' in value
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'keysToObscure' in value

  return isInstance
}

export function GetAdGroupAnalyticsResponseDataAttributesFromJSON(
  json: any
): GetAdGroupAnalyticsResponseDataAttributes {
  return GetAdGroupAnalyticsResponseDataAttributesFromJSONTyped(json, false)
}

export function GetAdGroupAnalyticsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdGroupAnalyticsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    metric: json['metric'],
    series: (json['series'] as Array<any>).map(
      GetAdGroupAnalyticsResponseDataAttributesSeriesFromJSON
    ),
    data: json['data'],
    keysToObscure: json['keys_to_obscure'],
  }
}

export function GetAdGroupAnalyticsResponseDataAttributesToJSON(
  value?: GetAdGroupAnalyticsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    metric: value.metric,
    series: (value.series as Array<any>).map(GetAdGroupAnalyticsResponseDataAttributesSeriesToJSON),
    data: value.data,
    keys_to_obscure: value.keysToObscure,
  }
}
