// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { DeleteCampaignsResponseDataAttributesAutomatedBiddingParameters } from './DeleteCampaignsResponseDataAttributesAutomatedBiddingParameters'
import {
  DeleteCampaignsResponseDataAttributesAutomatedBiddingParametersFromJSON,
  DeleteCampaignsResponseDataAttributesAutomatedBiddingParametersFromJSONTyped,
  DeleteCampaignsResponseDataAttributesAutomatedBiddingParametersToJSON,
} from './DeleteCampaignsResponseDataAttributesAutomatedBiddingParameters'
import type { DeleteCampaignsResponseDataAttributesOptimizedBiddingParameters } from './DeleteCampaignsResponseDataAttributesOptimizedBiddingParameters'
import {
  DeleteCampaignsResponseDataAttributesOptimizedBiddingParametersFromJSON,
  DeleteCampaignsResponseDataAttributesOptimizedBiddingParametersFromJSONTyped,
  DeleteCampaignsResponseDataAttributesOptimizedBiddingParametersToJSON,
} from './DeleteCampaignsResponseDataAttributesOptimizedBiddingParameters'
import type { DeleteCampaignsResponseDataAttributesSupplementaryStatuses } from './DeleteCampaignsResponseDataAttributesSupplementaryStatuses'
import {
  DeleteCampaignsResponseDataAttributesSupplementaryStatusesFromJSON,
  DeleteCampaignsResponseDataAttributesSupplementaryStatusesFromJSONTyped,
  DeleteCampaignsResponseDataAttributesSupplementaryStatusesToJSON,
} from './DeleteCampaignsResponseDataAttributesSupplementaryStatuses'

/**
 *
 * @export
 * @interface DeleteCampaignsResponseDataAttributes
 */
export interface DeleteCampaignsResponseDataAttributes {
  /**
   * A descriptive name for the campaign.
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  name: string
  /**
   * Set to true to activate the campaign. You will bid according to the details of the ad groups contained within this campaign. Set to false and the campaign is not active. This field cannot be set to true for a campaign in draft state. Draft campaign can only be launched from Instacart Ads Manager.
   * @type {boolean}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  enabled: boolean
  /**
   * The campaign’s start date in iso8601 format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  scheduledStartAt: string
  /**
   * The campaign's effective end date in iso8601 format. Campaigns end at midnight, PST, on the specified date. The campaign only stops when the campaign’s budget has been spent.
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  scheduledEndAt: string
  /**
   * The campaign’s start date in the 'YYYY-MM-DD' format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  scheduledStartAtDate: string
  /**
   * The campaign's effective end date in the 'YYYY-MM-DD' format. Campaigns end at midnight, PST, on the specified date. The campaign only stops when the campaign’s budget has been spent.
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  scheduledEndAtDate: string
  /**
   * Send "featured_product" for this field. We currently only support one type of ad campaign.
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  campaignType: string
  /**
   * The total budget for the ads campaign, expressed in dollars. When the campaign has spent this budget, it automatically ends.
   * @type {number}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  budget: number
  /**
   * Campaign Status
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  campaignStatus: string
  /**
   * The budgeting strategy for the campaign. One of "daily_budget", "max_impressions".
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  budgetType: DeleteCampaignsResponseDataAttributesBudgetTypeEnum
  /**
   * The daily budget for a v2 campaign, expressed in dollars.
   * @type {number}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  targetDailyBudget?: number
  /**
   * The campaign’s goal.
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  goal?: string
  /**
   * The campaign’s goal format.
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  goalFormat?: string
  /**
   * Override for default account-level bill payer. Agency, advertiser or retailer to be billed for the campaign.
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  billPayer?: DeleteCampaignsResponseDataAttributesBillPayerEnum
  /**
   * Enables automated bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  automatedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  automatedBiddingGoal?: DeleteCampaignsResponseDataAttributesAutomatedBiddingGoalEnum
  /**
   *
   * @type {DeleteCampaignsResponseDataAttributesAutomatedBiddingParameters}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  automatedBiddingParameters?: DeleteCampaignsResponseDataAttributesAutomatedBiddingParameters
  /**
   * Enables optimized bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  optimizedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  optimizedBiddingGoal?: DeleteCampaignsResponseDataAttributesOptimizedBiddingGoalEnum
  /**
   *
   * @type {DeleteCampaignsResponseDataAttributesOptimizedBiddingParameters}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  optimizedBiddingParameters?: DeleteCampaignsResponseDataAttributesOptimizedBiddingParameters
  /**
   * Campaign risk status
   * @type {{ [key: string]: any; }}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  atRiskMeta: { [key: string]: any }
  /**
   * Puchase Order #
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  purchaseOrder: string
  /**
   * Objective of the campaign
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  objective: string
  /**
   * Custom objective of the campaign
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  objectiveCustom: string
  /**
   * Memo for the campaign
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  memo: string
  /**
   * How you pay for the campaign
   * @type {string}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  paymentType: string
  /**
   *
   * @type {DeleteCampaignsResponseDataAttributesSupplementaryStatuses}
   * @memberof DeleteCampaignsResponseDataAttributes
   */
  supplementaryStatuses?: DeleteCampaignsResponseDataAttributesSupplementaryStatuses
}

/**
 * @export
 */
export const DeleteCampaignsResponseDataAttributesBudgetTypeEnum = {
  DailyBudget: 'daily_budget',
  PacedSpending: 'paced_spending',
  MaxImpressions: 'max_impressions',
  Null: 'null',
} as const
export type DeleteCampaignsResponseDataAttributesBudgetTypeEnum =
  typeof DeleteCampaignsResponseDataAttributesBudgetTypeEnum[keyof typeof DeleteCampaignsResponseDataAttributesBudgetTypeEnum]

/**
 * @export
 */
export const DeleteCampaignsResponseDataAttributesBillPayerEnum = {
  Agency: 'agency',
  Advertiser: 'advertiser',
  Retailer: 'retailer',
} as const
export type DeleteCampaignsResponseDataAttributesBillPayerEnum =
  typeof DeleteCampaignsResponseDataAttributesBillPayerEnum[keyof typeof DeleteCampaignsResponseDataAttributesBillPayerEnum]

/**
 * @export
 */
export const DeleteCampaignsResponseDataAttributesAutomatedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type DeleteCampaignsResponseDataAttributesAutomatedBiddingGoalEnum =
  typeof DeleteCampaignsResponseDataAttributesAutomatedBiddingGoalEnum[keyof typeof DeleteCampaignsResponseDataAttributesAutomatedBiddingGoalEnum]

/**
 * @export
 */
export const DeleteCampaignsResponseDataAttributesOptimizedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type DeleteCampaignsResponseDataAttributesOptimizedBiddingGoalEnum =
  typeof DeleteCampaignsResponseDataAttributesOptimizedBiddingGoalEnum[keyof typeof DeleteCampaignsResponseDataAttributesOptimizedBiddingGoalEnum]

/**
 * Check if a given object implements the DeleteCampaignsResponseDataAttributes interface.
 */
export function instanceOfDeleteCampaignsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'scheduledStartAt' in value
  isInstance = isInstance && 'scheduledEndAt' in value
  isInstance = isInstance && 'scheduledStartAtDate' in value
  isInstance = isInstance && 'scheduledEndAtDate' in value
  isInstance = isInstance && 'campaignType' in value
  isInstance = isInstance && 'budget' in value
  isInstance = isInstance && 'campaignStatus' in value
  isInstance = isInstance && 'budgetType' in value
  isInstance = isInstance && 'atRiskMeta' in value
  isInstance = isInstance && 'purchaseOrder' in value
  isInstance = isInstance && 'objective' in value
  isInstance = isInstance && 'objectiveCustom' in value
  isInstance = isInstance && 'memo' in value
  isInstance = isInstance && 'paymentType' in value

  return isInstance
}

export function DeleteCampaignsResponseDataAttributesFromJSON(
  json: any
): DeleteCampaignsResponseDataAttributes {
  return DeleteCampaignsResponseDataAttributesFromJSONTyped(json, false)
}

export function DeleteCampaignsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteCampaignsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    enabled: json['enabled'],
    scheduledStartAt: json['scheduled_start_at'],
    scheduledEndAt: json['scheduled_end_at'],
    scheduledStartAtDate: json['scheduled_start_at_date'],
    scheduledEndAtDate: json['scheduled_end_at_date'],
    campaignType: json['campaign_type'],
    budget: json['budget'],
    campaignStatus: json['campaign_status'],
    budgetType: json['budget_type'],
    targetDailyBudget: !exists(json, 'target_daily_budget')
      ? undefined
      : json['target_daily_budget'],
    goal: !exists(json, 'goal') ? undefined : json['goal'],
    goalFormat: !exists(json, 'goal_format') ? undefined : json['goal_format'],
    billPayer: !exists(json, 'bill_payer') ? undefined : json['bill_payer'],
    automatedBiddingEnabled: !exists(json, 'automated_bidding_enabled')
      ? undefined
      : json['automated_bidding_enabled'],
    automatedBiddingGoal: !exists(json, 'automated_bidding_goal')
      ? undefined
      : json['automated_bidding_goal'],
    automatedBiddingParameters: !exists(json, 'automated_bidding_parameters')
      ? undefined
      : DeleteCampaignsResponseDataAttributesAutomatedBiddingParametersFromJSON(
          json['automated_bidding_parameters']
        ),
    optimizedBiddingEnabled: !exists(json, 'optimized_bidding_enabled')
      ? undefined
      : json['optimized_bidding_enabled'],
    optimizedBiddingGoal: !exists(json, 'optimized_bidding_goal')
      ? undefined
      : json['optimized_bidding_goal'],
    optimizedBiddingParameters: !exists(json, 'optimized_bidding_parameters')
      ? undefined
      : DeleteCampaignsResponseDataAttributesOptimizedBiddingParametersFromJSON(
          json['optimized_bidding_parameters']
        ),
    atRiskMeta: json['at_risk_meta'],
    purchaseOrder: json['purchase_order'],
    objective: json['objective'],
    objectiveCustom: json['objective_custom'],
    memo: json['memo'],
    paymentType: json['payment_type'],
    supplementaryStatuses: !exists(json, 'supplementary_statuses')
      ? undefined
      : DeleteCampaignsResponseDataAttributesSupplementaryStatusesFromJSON(
          json['supplementary_statuses']
        ),
  }
}

export function DeleteCampaignsResponseDataAttributesToJSON(
  value?: DeleteCampaignsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    enabled: value.enabled,
    scheduled_start_at: value.scheduledStartAt,
    scheduled_end_at: value.scheduledEndAt,
    scheduled_start_at_date: value.scheduledStartAtDate,
    scheduled_end_at_date: value.scheduledEndAtDate,
    campaign_type: value.campaignType,
    budget: value.budget,
    campaign_status: value.campaignStatus,
    budget_type: value.budgetType,
    target_daily_budget: value.targetDailyBudget,
    goal: value.goal,
    goal_format: value.goalFormat,
    bill_payer: value.billPayer,
    automated_bidding_enabled: value.automatedBiddingEnabled,
    automated_bidding_goal: value.automatedBiddingGoal,
    automated_bidding_parameters:
      DeleteCampaignsResponseDataAttributesAutomatedBiddingParametersToJSON(
        value.automatedBiddingParameters
      ),
    optimized_bidding_enabled: value.optimizedBiddingEnabled,
    optimized_bidding_goal: value.optimizedBiddingGoal,
    optimized_bidding_parameters:
      DeleteCampaignsResponseDataAttributesOptimizedBiddingParametersToJSON(
        value.optimizedBiddingParameters
      ),
    at_risk_meta: value.atRiskMeta,
    purchase_order: value.purchaseOrder,
    objective: value.objective,
    objective_custom: value.objectiveCustom,
    memo: value.memo,
    payment_type: value.paymentType,
    supplementary_statuses: DeleteCampaignsResponseDataAttributesSupplementaryStatusesToJSON(
      value.supplementaryStatuses
    ),
  }
}
