// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { BulkCampaignsResponseDataAttributesOptimizedBiddingParameters } from './BulkCampaignsResponseDataAttributesOptimizedBiddingParameters'
import {
  BulkCampaignsResponseDataAttributesOptimizedBiddingParametersFromJSON,
  BulkCampaignsResponseDataAttributesOptimizedBiddingParametersFromJSONTyped,
  BulkCampaignsResponseDataAttributesOptimizedBiddingParametersToJSON,
} from './BulkCampaignsResponseDataAttributesOptimizedBiddingParameters'

/**
 *
 * @export
 * @interface BulkCampaignsResponseDataAttributes
 */
export interface BulkCampaignsResponseDataAttributes {
  /**
   * UUID of Ad Group
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  id: string
  /**
   * A descriptive name for the campaign.
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  name: string
  /**
   * Set to true to activate the campaign. You will bid according to the details of the ad groups contained within this campaign. Set to false and the campaign is not active. This field cannot be set to true for a campaign in draft state. Draft campaign can only be launched from Instacart Ads Manager.
   * @type {boolean}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  enabled: boolean
  /**
   * The campaign’s start date in iso8601 format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  scheduledStartAt: string
  /**
   * The campaign's effective end date in iso8601 format. Campaigns end at midnight, PST, on the specified date. The campaign only stops when the campaign's budget has been spent.
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  scheduledEndAt: string
  /**
   * The campaign’s start date in the 'YYYY-MM-DD' format. Campaigns start midnight, PST, on the specified date.
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  scheduledStartAtDate: string
  /**
   * The campaign's effective end date in the 'YYYY-MM-DD' format. Campaigns end at midnight, PST, on the specified date. The campaign only stops when the campaign’s budget has been spent.
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  scheduledEndAtDate: string
  /**
   * Send "featured_product" for this field. We currently only support one type of ad campaign.
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  campaignType: string
  /**
   * The total budget for the ads campaign, expressed in dollars. When the campaign has spent this budget, it automatically ends.
   * @type {number}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  budget: number
  /**
   * Campaign Status
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  campaignStatus: string
  /**
   * Campaign risk status
   * @type {{ [key: string]: any; }}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  atRiskMeta: { [key: string]: any }
  /**
   * The budgeting strategy for the campaign. One of "daily_budget", "max_impressions".
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  budgetType: BulkCampaignsResponseDataAttributesBudgetTypeEnum
  /**
   * The daily budget for a v2 campaign, expressed in dollars.
   * @type {number}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  targetDailyBudget?: number
  /**
   * Puchase Order #
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  purchaseOrder: string
  /**
   * Objective of the campaign
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  objective: string
  /**
   * Custom objective of the campaign
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  objectiveCustom: string
  /**
   * Memo for the campaign
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  memo: string
  /**
   * How you pay for the campaign
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  paymentType: string
  /**
   * Enables optimized bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  optimizedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  optimizedBiddingGoal?: BulkCampaignsResponseDataAttributesOptimizedBiddingGoalEnum
  /**
   *
   * @type {BulkCampaignsResponseDataAttributesOptimizedBiddingParameters}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  optimizedBiddingParameters?: BulkCampaignsResponseDataAttributesOptimizedBiddingParameters
  /**
   * The campaign’s goal
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  goal?: string
  /**
   * The campaign’s goal format
   * @type {string}
   * @memberof BulkCampaignsResponseDataAttributes
   */
  goalFormat?: string
}

/**
 * @export
 */
export const BulkCampaignsResponseDataAttributesBudgetTypeEnum = {
  DailyBudget: 'daily_budget',
  PacedSpending: 'paced_spending',
  MaxImpressions: 'max_impressions',
  Null: 'null',
} as const
export type BulkCampaignsResponseDataAttributesBudgetTypeEnum =
  typeof BulkCampaignsResponseDataAttributesBudgetTypeEnum[keyof typeof BulkCampaignsResponseDataAttributesBudgetTypeEnum]

/**
 * @export
 */
export const BulkCampaignsResponseDataAttributesOptimizedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type BulkCampaignsResponseDataAttributesOptimizedBiddingGoalEnum =
  typeof BulkCampaignsResponseDataAttributesOptimizedBiddingGoalEnum[keyof typeof BulkCampaignsResponseDataAttributesOptimizedBiddingGoalEnum]

/**
 * Check if a given object implements the BulkCampaignsResponseDataAttributes interface.
 */
export function instanceOfBulkCampaignsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'scheduledStartAt' in value
  isInstance = isInstance && 'scheduledEndAt' in value
  isInstance = isInstance && 'scheduledStartAtDate' in value
  isInstance = isInstance && 'scheduledEndAtDate' in value
  isInstance = isInstance && 'campaignType' in value
  isInstance = isInstance && 'budget' in value
  isInstance = isInstance && 'campaignStatus' in value
  isInstance = isInstance && 'atRiskMeta' in value
  isInstance = isInstance && 'budgetType' in value
  isInstance = isInstance && 'purchaseOrder' in value
  isInstance = isInstance && 'objective' in value
  isInstance = isInstance && 'objectiveCustom' in value
  isInstance = isInstance && 'memo' in value
  isInstance = isInstance && 'paymentType' in value

  return isInstance
}

export function BulkCampaignsResponseDataAttributesFromJSON(
  json: any
): BulkCampaignsResponseDataAttributes {
  return BulkCampaignsResponseDataAttributesFromJSONTyped(json, false)
}

export function BulkCampaignsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BulkCampaignsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    enabled: json['enabled'],
    scheduledStartAt: json['scheduled_start_at'],
    scheduledEndAt: json['scheduled_end_at'],
    scheduledStartAtDate: json['scheduled_start_at_date'],
    scheduledEndAtDate: json['scheduled_end_at_date'],
    campaignType: json['campaign_type'],
    budget: json['budget'],
    campaignStatus: json['campaign_status'],
    atRiskMeta: json['at_risk_meta'],
    budgetType: json['budget_type'],
    targetDailyBudget: !exists(json, 'target_daily_budget')
      ? undefined
      : json['target_daily_budget'],
    purchaseOrder: json['purchase_order'],
    objective: json['objective'],
    objectiveCustom: json['objective_custom'],
    memo: json['memo'],
    paymentType: json['payment_type'],
    optimizedBiddingEnabled: !exists(json, 'optimized_bidding_enabled')
      ? undefined
      : json['optimized_bidding_enabled'],
    optimizedBiddingGoal: !exists(json, 'optimized_bidding_goal')
      ? undefined
      : json['optimized_bidding_goal'],
    optimizedBiddingParameters: !exists(json, 'optimized_bidding_parameters')
      ? undefined
      : BulkCampaignsResponseDataAttributesOptimizedBiddingParametersFromJSON(
          json['optimized_bidding_parameters']
        ),
    goal: !exists(json, 'goal') ? undefined : json['goal'],
    goalFormat: !exists(json, 'goal_format') ? undefined : json['goal_format'],
  }
}

export function BulkCampaignsResponseDataAttributesToJSON(
  value?: BulkCampaignsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    enabled: value.enabled,
    scheduled_start_at: value.scheduledStartAt,
    scheduled_end_at: value.scheduledEndAt,
    scheduled_start_at_date: value.scheduledStartAtDate,
    scheduled_end_at_date: value.scheduledEndAtDate,
    campaign_type: value.campaignType,
    budget: value.budget,
    campaign_status: value.campaignStatus,
    at_risk_meta: value.atRiskMeta,
    budget_type: value.budgetType,
    target_daily_budget: value.targetDailyBudget,
    purchase_order: value.purchaseOrder,
    objective: value.objective,
    objective_custom: value.objectiveCustom,
    memo: value.memo,
    payment_type: value.paymentType,
    optimized_bidding_enabled: value.optimizedBiddingEnabled,
    optimized_bidding_goal: value.optimizedBiddingGoal,
    optimized_bidding_parameters:
      BulkCampaignsResponseDataAttributesOptimizedBiddingParametersToJSON(
        value.optimizedBiddingParameters
      ),
    goal: value.goal,
    goal_format: value.goalFormat,
  }
}
