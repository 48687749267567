import { css } from '@emotion/react'
import { useTheme, spacing, layers, responsive } from '@instacart/ids-core'
import { FC, ReactNode } from 'react'
import { useFlipper, useLinkStyles } from 'common'
import { FLIPPER_PAGE_LAYOUT_REFRESH } from 'common/flippers'
import toPx from 'common/toPx'
import { FormError, FormErrorsWrapper } from 'components/FormHelper'
import { FooterButtons, FooterButtonsProps } from './components/FooterButtons'

interface PageFooterProps {
  terms?: ReactNode
  isFixed?: boolean
  renderButtons?: ReactNode
  buttonProps?: FooterButtonsProps
  serverErrors?: string
}

const useStyles = () => {
  const theme = useTheme()
  const pageLayoutRefreshEnabled = useFlipper(FLIPPER_PAGE_LAYOUT_REFRESH)
  return {
    footer: css({
      background: theme.colors.systemGrayscale00,
      padding: toPx`${spacing.s12} ${spacing.s16}`,
      display: 'flex',
      borderRadius: pageLayoutRefreshEnabled ? '0px 0px 12px 12px' : undefined,
      justifyContent: 'flex-end',
      alignItems: 'center',
      borderTop: `1px solid ${theme.colors.systemGrayscale20}`,

      [responsive.down('c')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    }),
    fixedFooter: css({
      position: 'sticky',
      bottom: 0,
      zIndex: layers.l1,
    }),
    buttons: css({
      marginLeft: spacing.s24,
      [responsive.down('c')]: {
        marginLeft: 0,
      },
    }),
  }
}

export const PageFooter: FC<React.PropsWithChildren<PageFooterProps>> = ({
  terms = null,
  isFixed = true,
  renderButtons = null,
  buttonProps = null,
  serverErrors = null,
}) => {
  const styles = useStyles()
  const linkCssStyle = useLinkStyles()
  const defaultButtons = buttonProps ? <FooterButtons {...buttonProps} /> : null
  const buttons = renderButtons || defaultButtons

  const leftContent = (
    <FormErrorsWrapper>
      {serverErrors && <FormError>{serverErrors}</FormError>}
      <div css={linkCssStyle}>{terms}</div>
    </FormErrorsWrapper>
  )

  const footerContent = (
    <div css={styles.footer}>
      {leftContent}
      <div css={styles.buttons}>{buttons}</div>
    </div>
  )

  return isFixed ? <div css={styles.fixedFooter}>{footerContent}</div> : footerContent
}
