// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollections } from './GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollections'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollections'

/**
 *
 * @export
 * @interface GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid
 */
export interface GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid {
  /**
   * Collections
   * @type {Array<GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollections>}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid
   */
  collections: Array<GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollections>
}

/**
 * Check if a given object implements the GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid interface.
 */
export function instanceOfGetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'collections' in value

  return isInstance
}

export function GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridFromJSON(
  json: any
): GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid {
  return GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid {
  if (json === undefined || json === null) {
    return json
  }
  return {
    collections: (json['collections'] as Array<any>).map(
      GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsFromJSON
    ),
  }
}

export function GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridToJSON(
  value?: GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGrid | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    collections: (value.collections as Array<any>).map(
      GetDisplayAdGroupsParamDataAttributesCreativeActionsMainCollectionGridCollectionsToJSON
    ),
  }
}
