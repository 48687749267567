// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetCampaignResponseDataAttributes } from './GetCampaignResponseDataAttributes'
import {
  GetCampaignResponseDataAttributesFromJSON,
  GetCampaignResponseDataAttributesFromJSONTyped,
  GetCampaignResponseDataAttributesToJSON,
} from './GetCampaignResponseDataAttributes'
import type { GetCampaignResponseDataRelationships } from './GetCampaignResponseDataRelationships'
import {
  GetCampaignResponseDataRelationshipsFromJSON,
  GetCampaignResponseDataRelationshipsFromJSONTyped,
  GetCampaignResponseDataRelationshipsToJSON,
} from './GetCampaignResponseDataRelationships'

/**
 *
 * @export
 * @interface GetCampaignResponseData
 */
export interface GetCampaignResponseData {
  /**
   * The campaign ID.
   * @type {string}
   * @memberof GetCampaignResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetCampaignResponseData
   */
  type: GetCampaignResponseDataTypeEnum
  /**
   *
   * @type {GetCampaignResponseDataAttributes}
   * @memberof GetCampaignResponseData
   */
  attributes: GetCampaignResponseDataAttributes
  /**
   *
   * @type {GetCampaignResponseDataRelationships}
   * @memberof GetCampaignResponseData
   */
  relationships: GetCampaignResponseDataRelationships
}

/**
 * @export
 */
export const GetCampaignResponseDataTypeEnum = {
  Campaign: 'campaign',
} as const
export type GetCampaignResponseDataTypeEnum =
  typeof GetCampaignResponseDataTypeEnum[keyof typeof GetCampaignResponseDataTypeEnum]

/**
 * Check if a given object implements the GetCampaignResponseData interface.
 */
export function instanceOfGetCampaignResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value
  isInstance = isInstance && 'relationships' in value

  return isInstance
}

export function GetCampaignResponseDataFromJSON(json: any): GetCampaignResponseData {
  return GetCampaignResponseDataFromJSONTyped(json, false)
}

export function GetCampaignResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: GetCampaignResponseDataAttributesFromJSON(json['attributes']),
    relationships: GetCampaignResponseDataRelationshipsFromJSON(json['relationships']),
  }
}

export function GetCampaignResponseDataToJSON(value?: GetCampaignResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: GetCampaignResponseDataAttributesToJSON(value.attributes),
    relationships: GetCampaignResponseDataRelationshipsToJSON(value.relationships),
  }
}
