// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiAdGroupsControllerUpdateInputAdGroup } from './ApiAdGroupsControllerUpdateInputAdGroup'
import {
  ApiAdGroupsControllerUpdateInputAdGroupFromJSON,
  ApiAdGroupsControllerUpdateInputAdGroupFromJSONTyped,
  ApiAdGroupsControllerUpdateInputAdGroupToJSON,
} from './ApiAdGroupsControllerUpdateInputAdGroup'

/**
 *
 * @export
 * @interface ApiAdGroupsControllerUpdateInput
 */
export interface ApiAdGroupsControllerUpdateInput {
  /**
   *
   * @type {ApiAdGroupsControllerUpdateInputAdGroup}
   * @memberof ApiAdGroupsControllerUpdateInput
   */
  adGroup: ApiAdGroupsControllerUpdateInputAdGroup
  /**
   * Controls whether or not PUT /ad_groups/{id} returns the bid_recs object, which contains the suggested bid amount and bid strength for the ad group.
   * @type {boolean}
   * @memberof ApiAdGroupsControllerUpdateInput
   */
  excludeBidRecs?: boolean
}

/**
 * Check if a given object implements the ApiAdGroupsControllerUpdateInput interface.
 */
export function instanceOfApiAdGroupsControllerUpdateInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'adGroup' in value

  return isInstance
}

export function ApiAdGroupsControllerUpdateInputFromJSON(
  json: any
): ApiAdGroupsControllerUpdateInput {
  return ApiAdGroupsControllerUpdateInputFromJSONTyped(json, false)
}

export function ApiAdGroupsControllerUpdateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdGroupsControllerUpdateInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    adGroup: ApiAdGroupsControllerUpdateInputAdGroupFromJSON(json['ad_group']),
    excludeBidRecs: !exists(json, 'exclude_bid_recs') ? undefined : json['exclude_bid_recs'],
  }
}

export function ApiAdGroupsControllerUpdateInputToJSON(
  value?: ApiAdGroupsControllerUpdateInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    ad_group: ApiAdGroupsControllerUpdateInputAdGroupToJSON(value.adGroup),
    exclude_bid_recs: value.excludeBidRecs,
  }
}
