// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PutCampaignsBulkParamCampaignsAdGroups
 */
export interface PutCampaignsBulkParamCampaignsAdGroups {
  /**
   * The ad group UUID
   * @type {string}
   * @memberof PutCampaignsBulkParamCampaignsAdGroups
   */
  id: string
  /**
   * The maximum amount, in dollars, you want to pay per click for advertised products in this ad group.
   * @type {number}
   * @memberof PutCampaignsBulkParamCampaignsAdGroups
   */
  defaultBid: number
}

/**
 * Check if a given object implements the PutCampaignsBulkParamCampaignsAdGroups interface.
 */
export function instanceOfPutCampaignsBulkParamCampaignsAdGroups(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'defaultBid' in value

  return isInstance
}

export function PutCampaignsBulkParamCampaignsAdGroupsFromJSON(
  json: any
): PutCampaignsBulkParamCampaignsAdGroups {
  return PutCampaignsBulkParamCampaignsAdGroupsFromJSONTyped(json, false)
}

export function PutCampaignsBulkParamCampaignsAdGroupsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutCampaignsBulkParamCampaignsAdGroups {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    defaultBid: json['default_bid'],
  }
}

export function PutCampaignsBulkParamCampaignsAdGroupsToJSON(
  value?: PutCampaignsBulkParamCampaignsAdGroups | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    default_bid: value.defaultBid,
  }
}
