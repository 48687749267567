// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutCampaignResponseData } from './PutCampaignResponseData'
import {
  PutCampaignResponseDataFromJSON,
  PutCampaignResponseDataFromJSONTyped,
  PutCampaignResponseDataToJSON,
} from './PutCampaignResponseData'
import type { PutCampaignResponseMeta } from './PutCampaignResponseMeta'
import {
  PutCampaignResponseMetaFromJSON,
  PutCampaignResponseMetaFromJSONTyped,
  PutCampaignResponseMetaToJSON,
} from './PutCampaignResponseMeta'

/**
 *
 * @export
 * @interface PutCampaignResponse
 */
export interface PutCampaignResponse {
  /**
   *
   * @type {PutCampaignResponseData}
   * @memberof PutCampaignResponse
   */
  data: PutCampaignResponseData
  /**
   *
   * @type {PutCampaignResponseMeta}
   * @memberof PutCampaignResponse
   */
  meta: PutCampaignResponseMeta
}

/**
 * Check if a given object implements the PutCampaignResponse interface.
 */
export function instanceOfPutCampaignResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function PutCampaignResponseFromJSON(json: any): PutCampaignResponse {
  return PutCampaignResponseFromJSONTyped(json, false)
}

export function PutCampaignResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutCampaignResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: PutCampaignResponseDataFromJSON(json['data']),
    meta: PutCampaignResponseMetaFromJSON(json['meta']),
  }
}

export function PutCampaignResponseToJSON(value?: PutCampaignResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: PutCampaignResponseDataToJSON(value.data),
    meta: PutCampaignResponseMetaToJSON(value.meta),
  }
}
