// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetCampaignsResponseDataAttributes } from './GetCampaignsResponseDataAttributes'
import {
  GetCampaignsResponseDataAttributesFromJSON,
  GetCampaignsResponseDataAttributesFromJSONTyped,
  GetCampaignsResponseDataAttributesToJSON,
} from './GetCampaignsResponseDataAttributes'

/**
 *
 * @export
 * @interface GetCampaignsResponseData
 */
export interface GetCampaignsResponseData {
  /**
   * The campaign ID.
   * @type {string}
   * @memberof GetCampaignsResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetCampaignsResponseData
   */
  type: GetCampaignsResponseDataTypeEnum
  /**
   *
   * @type {GetCampaignsResponseDataAttributes}
   * @memberof GetCampaignsResponseData
   */
  attributes: GetCampaignsResponseDataAttributes
}

/**
 * @export
 */
export const GetCampaignsResponseDataTypeEnum = {
  Campaign: 'campaign',
} as const
export type GetCampaignsResponseDataTypeEnum =
  typeof GetCampaignsResponseDataTypeEnum[keyof typeof GetCampaignsResponseDataTypeEnum]

/**
 * Check if a given object implements the GetCampaignsResponseData interface.
 */
export function instanceOfGetCampaignsResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function GetCampaignsResponseDataFromJSON(json: any): GetCampaignsResponseData {
  return GetCampaignsResponseDataFromJSONTyped(json, false)
}

export function GetCampaignsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: GetCampaignsResponseDataAttributesFromJSON(json['attributes']),
  }
}

export function GetCampaignsResponseDataToJSON(value?: GetCampaignsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: GetCampaignsResponseDataAttributesToJSON(value.attributes),
  }
}
