// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { BulkAdGroupResponseDataAttributes } from './BulkAdGroupResponseDataAttributes'
import {
  BulkAdGroupResponseDataAttributesFromJSON,
  BulkAdGroupResponseDataAttributesFromJSONTyped,
  BulkAdGroupResponseDataAttributesToJSON,
} from './BulkAdGroupResponseDataAttributes'

/**
 *
 * @export
 * @interface BulkAdGroupResponseData
 */
export interface BulkAdGroupResponseData {
  /**
   *
   * @type {string}
   * @memberof BulkAdGroupResponseData
   */
  type: BulkAdGroupResponseDataTypeEnum
  /**
   * HTTP Status Code
   * @type {number}
   * @memberof BulkAdGroupResponseData
   */
  status: number
  /**
   *
   * @type {Array<BulkAdGroupResponseDataAttributes>}
   * @memberof BulkAdGroupResponseData
   */
  attributes: Array<BulkAdGroupResponseDataAttributes>
}

/**
 * @export
 */
export const BulkAdGroupResponseDataTypeEnum = {
  AdGroups: 'ad_groups',
} as const
export type BulkAdGroupResponseDataTypeEnum =
  typeof BulkAdGroupResponseDataTypeEnum[keyof typeof BulkAdGroupResponseDataTypeEnum]

/**
 * Check if a given object implements the BulkAdGroupResponseData interface.
 */
export function instanceOfBulkAdGroupResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function BulkAdGroupResponseDataFromJSON(json: any): BulkAdGroupResponseData {
  return BulkAdGroupResponseDataFromJSONTyped(json, false)
}

export function BulkAdGroupResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BulkAdGroupResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    status: json['status'],
    attributes: (json['attributes'] as Array<any>).map(BulkAdGroupResponseDataAttributesFromJSON),
  }
}

export function BulkAdGroupResponseDataToJSON(value?: BulkAdGroupResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    status: value.status,
    attributes: (value.attributes as Array<any>).map(BulkAdGroupResponseDataAttributesToJSON),
  }
}
