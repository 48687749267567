// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AdGroupResponseDataAttributesAnalyticsAttributionDataLastTouch } from './AdGroupResponseDataAttributesAnalyticsAttributionDataLastTouch'
import {
  AdGroupResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON,
  AdGroupResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  AdGroupResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './AdGroupResponseDataAttributesAnalyticsAttributionDataLastTouch'
import type { AdGroupResponseDataAttributesAnalyticsAttributionDataLinear } from './AdGroupResponseDataAttributesAnalyticsAttributionDataLinear'
import {
  AdGroupResponseDataAttributesAnalyticsAttributionDataLinearFromJSON,
  AdGroupResponseDataAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  AdGroupResponseDataAttributesAnalyticsAttributionDataLinearToJSON,
} from './AdGroupResponseDataAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface AdGroupResponseDataAttributesAnalyticsAttributionData
 */
export interface AdGroupResponseDataAttributesAnalyticsAttributionData {
  /**
   *
   * @type {AdGroupResponseDataAttributesAnalyticsAttributionDataLinear}
   * @memberof AdGroupResponseDataAttributesAnalyticsAttributionData
   */
  linear: AdGroupResponseDataAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {AdGroupResponseDataAttributesAnalyticsAttributionDataLastTouch}
   * @memberof AdGroupResponseDataAttributesAnalyticsAttributionData
   */
  lastTouch?: AdGroupResponseDataAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the AdGroupResponseDataAttributesAnalyticsAttributionData interface.
 */
export function instanceOfAdGroupResponseDataAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function AdGroupResponseDataAttributesAnalyticsAttributionDataFromJSON(
  json: any
): AdGroupResponseDataAttributesAnalyticsAttributionData {
  return AdGroupResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(json, false)
}

export function AdGroupResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdGroupResponseDataAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear: AdGroupResponseDataAttributesAnalyticsAttributionDataLinearFromJSON(json['linear']),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : AdGroupResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON(json['last_touch']),
  }
}

export function AdGroupResponseDataAttributesAnalyticsAttributionDataToJSON(
  value?: AdGroupResponseDataAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear: AdGroupResponseDataAttributesAnalyticsAttributionDataLinearToJSON(value.linear),
    last_touch: AdGroupResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON(
      value.lastTouch
    ),
  }
}
