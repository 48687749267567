import { GridOrderEnums } from 'pages/DisplayProduct/DisplayAdGroup/constants'
import { AssetType, creatives } from './schemas'

// The public Type that represents a creative schema.
type GridProperties = {
  min: number
  max: number
  supportedGridOrders: typeof GridOrderEnums[]
}

export interface CreativeSchema {
  machineName: string
  humanName: string
  description: string
  actions: {
    hasItemGrid: () => boolean
    getItemGridProperties: () => GridProperties
    hasCollectionGrid: () => boolean
    hasGridOrderDynamic: () => boolean
    hasProductGroupGrid: () => boolean
    getProductGridProperties: () => GridProperties
    hasBrandPageClick: () => boolean
    hasUrlClick: () => boolean
    hasRecipeClick: () => boolean
  }
  assets: {
    hasBrandLogo: () => boolean
    hasHeroImage: () => boolean
    hasImageWeb: () => boolean
    hasImageMobile: () => boolean
    hasThumbnail: () => boolean
    hasTopImageWeb: () => boolean
    hasTopImageMobile: () => boolean
    hasUvcImageWeb: () => boolean
    hasUvcImageMobile: () => boolean
    hasVideo: () => boolean
    getAssetTypes: (all?: boolean) => AssetType[]
  }
  properties: {
    hasTagline: () => boolean
    getTaglineProperties: () => {
      maxLength: number
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const buildCreativeSchema = (schema: { [key: string]: any }): CreativeSchema => {
  return {
    machineName: schema.machine_name,
    humanName: schema.human_name,
    description: schema.description,
    actions: {
      hasItemGrid: () => !!schema.actions?.main_item_grid?.item_grid,
      getItemGridProperties: () => ({
        min: schema?.actions?.main_item_grid?.item_grid.min_items,
        max: schema?.actions?.main_item_grid?.item_grid.max_items,
        supportedGridOrders: schema?.actions?.main_item_grid?.item_grid.supported_grid_orders,
      }),
      hasCollectionGrid: () => !!schema.actions?.main_collection_grid?.collection_grid,
      hasGridOrderDynamic: () => {
        const orders =
          schema.actions?.main_item_grid?.item_grid?.supported_grid_orders ||
          schema.actions?.main_product_group_grid?.product_group_grid?.supported_grid_orders
        return orders?.includes(GridOrderEnums.Dynamic)
      },
      hasProductGroupGrid: () => !!schema.actions?.main_product_group_grid?.product_group_grid,
      getProductGridProperties: () => ({
        min: schema?.actions?.main_product_group_grid?.product_group_grid.min_product_groups,
        max: schema?.actions?.main_product_group_grid?.product_group_grid.max_product_groups,
        supportedGridOrders:
          schema?.actions?.main_product_group_grid?.product_group_grid.supported_grid_orders,
      }),
      hasBrandPageClick: () => !!schema.actions?.banner_click?.brand_page_click,
      hasUrlClick: () => !!schema.actions?.banner_click?.url_click,
      hasRecipeClick: () => !!schema.actions?.banner_click?.recipe_click,
    },
    assets: {
      hasBrandLogo: () => !!schema.assets.brand_logo,
      hasHeroImage: () => !!schema.assets.hero_image,
      hasImageWeb: () => !!schema.assets.image_web,
      hasImageMobile: () => !!schema.assets.image_mobile,
      hasThumbnail: () => !!schema.assets.thumbnail,
      hasTopImageWeb: () => !!schema.assets.top_image_web,
      hasTopImageMobile: () => !!schema.assets.top_image_mobile,
      hasUvcImageWeb: () => !!schema.assets.uvc_image_web,
      hasUvcImageMobile: () => !!schema.assets.uvc_image_mobile,
      hasVideo: () => !!schema.assets.video,
      getAssetTypes: (all?: boolean) => {
        const keys = Object.keys(schema.assets).reduce<AssetType[]>((acc, key) => {
          if (all || schema.assets[key].required) {
            acc.push(schema.assets[key].asset_type)
          }
          return acc
        }, [])

        return keys
      },
    },
    properties: {
      hasTagline: () => !!schema.properties_schema.properties.tagline,
      getTaglineProperties: () => schema.properties_schema.properties.tagline,
    },
  }
}

export const getCreativeSchema = (machineName: string) => {
  const schema = creatives[machineName]
  if (!schema) throw new Error(`No creative schema found for ${machineName}`)
  return buildCreativeSchema(schema)
}
