// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiCampaignsControllerUpdateInputCampaignAutomatedBiddingParameters } from './ApiCampaignsControllerUpdateInputCampaignAutomatedBiddingParameters'
import {
  ApiCampaignsControllerUpdateInputCampaignAutomatedBiddingParametersFromJSON,
  ApiCampaignsControllerUpdateInputCampaignAutomatedBiddingParametersFromJSONTyped,
  ApiCampaignsControllerUpdateInputCampaignAutomatedBiddingParametersToJSON,
} from './ApiCampaignsControllerUpdateInputCampaignAutomatedBiddingParameters'
import type { ApiCampaignsControllerUpdateInputCampaignOptimizedBiddingParameters } from './ApiCampaignsControllerUpdateInputCampaignOptimizedBiddingParameters'
import {
  ApiCampaignsControllerUpdateInputCampaignOptimizedBiddingParametersFromJSON,
  ApiCampaignsControllerUpdateInputCampaignOptimizedBiddingParametersFromJSONTyped,
  ApiCampaignsControllerUpdateInputCampaignOptimizedBiddingParametersToJSON,
} from './ApiCampaignsControllerUpdateInputCampaignOptimizedBiddingParameters'
import type { PutCampaignsIdParamCampaignAdGroups } from './PutCampaignsIdParamCampaignAdGroups'
import {
  PutCampaignsIdParamCampaignAdGroupsFromJSON,
  PutCampaignsIdParamCampaignAdGroupsFromJSONTyped,
  PutCampaignsIdParamCampaignAdGroupsToJSON,
} from './PutCampaignsIdParamCampaignAdGroups'

/**
 *
 * @export
 * @interface ApiCampaignsControllerUpdateInputCampaign
 */
export interface ApiCampaignsControllerUpdateInputCampaign {
  /**
   * Set to true to activate the campaign. Set to false to deactivate the campaign. Do not specify this field to make no update. This field cannot be set to true for a campaign in draft state. Draft campaign can only be launched from Instacart Ads Manager.
   * @type {boolean}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  enabled?: boolean
  /**
   * Specify a value to update the campaign’s name.
   * @type {string}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  name?: string
  /**
   * Specify a new total budget, in dollars, to update the total budget for the campaign. When the campaign has spent this budget, it automatically ends.
   * @type {number}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  budget?: number
  /**
   * You can update the campaign’s update by sending one of the values in the Enumerated Values section below.
   * @type {string}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  objective?: ApiCampaignsControllerUpdateInputCampaignObjectiveEnum
  /**
   * If you updated the objective to "other", send a brief phrase describing the campaign’s objective.
   * @type {string}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  objectiveCustom?: string
  /**
   * You can update the memo line Instacart includes on invoices for this campaign by specifying a new value in this field.
   * @type {string}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  memo?: string
  /**
   * You can update the purchase order number Instacart includes on the invoices generated for this ad campaign, by specifying a new value in this field.
   * @type {string}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  purchaseOrder?: string
  /**
   * You can update how you want Instacart to bill you for the campaign. If you want Instacart to bill on a per purchase order, specify "purchase_order". If you want us to bill to your account, specify "account".
   * @type {string}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  paymentType?: ApiCampaignsControllerUpdateInputCampaignPaymentTypeEnum
  /**
   * The budgeting strategy for the campaign. One of "daily_budget", "max_impressions".
   * @type {string}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  budgetType?: ApiCampaignsControllerUpdateInputCampaignBudgetTypeEnum
  /**
   * The daily budget for a v2 campaign, expressed in dollars. If `optimized_bidding_enabled` set to `true`, the minimum budget required is $20.
   * @type {number}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  targetDailyBudget?: number
  /**
   * Override for default account-level bill payer. Agency, advertiser or retailer to be billed for the campaign.
   * @type {string}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  billPayer?: ApiCampaignsControllerUpdateInputCampaignBillPayerEnum
  /**
   * Enables automated bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  automatedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  automatedBiddingGoal?: ApiCampaignsControllerUpdateInputCampaignAutomatedBiddingGoalEnum
  /**
   *
   * @type {ApiCampaignsControllerUpdateInputCampaignAutomatedBiddingParameters}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  automatedBiddingParameters?: ApiCampaignsControllerUpdateInputCampaignAutomatedBiddingParameters
  /**
   * Enables optimized bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  optimizedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  optimizedBiddingGoal?: ApiCampaignsControllerUpdateInputCampaignOptimizedBiddingGoalEnum
  /**
   *
   * @type {ApiCampaignsControllerUpdateInputCampaignOptimizedBiddingParameters}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  optimizedBiddingParameters?: ApiCampaignsControllerUpdateInputCampaignOptimizedBiddingParameters
  /**
   * The campaign’s goal, only updateable when campaign is in draft mode
   * @type {string}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  goal?: string
  /**
   * The default CPC bids to set for all ad groups
   * @type {Array<PutCampaignsIdParamCampaignAdGroups>}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  adGroups?: Array<PutCampaignsIdParamCampaignAdGroups>
  /**
   *
   * @type {boolean}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  optimizedBiddingClearKeywordsAsync?: boolean
  /**
   * Specify a value to update the campaign’s start date. Can only be updated if the start date has not been reached yet.
   * @type {string}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  startsAt?: string
  /**
   * Specify a value to update the campaign’s effective end date.
   * @type {string}
   * @memberof ApiCampaignsControllerUpdateInputCampaign
   */
  endsAt?: string
}

/**
 * @export
 */
export const ApiCampaignsControllerUpdateInputCampaignObjectiveEnum = {
  Sales: 'sales',
  MarketShare: 'market_share',
  Consideration: 'consideration',
  Awareness: 'awareness',
  Other: 'other',
} as const
export type ApiCampaignsControllerUpdateInputCampaignObjectiveEnum =
  typeof ApiCampaignsControllerUpdateInputCampaignObjectiveEnum[keyof typeof ApiCampaignsControllerUpdateInputCampaignObjectiveEnum]

/**
 * @export
 */
export const ApiCampaignsControllerUpdateInputCampaignPaymentTypeEnum = {
  Account: 'account',
  PurchaseOrder: 'purchase_order',
} as const
export type ApiCampaignsControllerUpdateInputCampaignPaymentTypeEnum =
  typeof ApiCampaignsControllerUpdateInputCampaignPaymentTypeEnum[keyof typeof ApiCampaignsControllerUpdateInputCampaignPaymentTypeEnum]

/**
 * @export
 */
export const ApiCampaignsControllerUpdateInputCampaignBudgetTypeEnum = {
  DailyBudget: 'daily_budget',
  MaxImpressions: 'max_impressions',
} as const
export type ApiCampaignsControllerUpdateInputCampaignBudgetTypeEnum =
  typeof ApiCampaignsControllerUpdateInputCampaignBudgetTypeEnum[keyof typeof ApiCampaignsControllerUpdateInputCampaignBudgetTypeEnum]

/**
 * @export
 */
export const ApiCampaignsControllerUpdateInputCampaignBillPayerEnum = {
  Agency: 'agency',
  Advertiser: 'advertiser',
  Retailer: 'retailer',
} as const
export type ApiCampaignsControllerUpdateInputCampaignBillPayerEnum =
  typeof ApiCampaignsControllerUpdateInputCampaignBillPayerEnum[keyof typeof ApiCampaignsControllerUpdateInputCampaignBillPayerEnum]

/**
 * @export
 */
export const ApiCampaignsControllerUpdateInputCampaignAutomatedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type ApiCampaignsControllerUpdateInputCampaignAutomatedBiddingGoalEnum =
  typeof ApiCampaignsControllerUpdateInputCampaignAutomatedBiddingGoalEnum[keyof typeof ApiCampaignsControllerUpdateInputCampaignAutomatedBiddingGoalEnum]

/**
 * @export
 */
export const ApiCampaignsControllerUpdateInputCampaignOptimizedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type ApiCampaignsControllerUpdateInputCampaignOptimizedBiddingGoalEnum =
  typeof ApiCampaignsControllerUpdateInputCampaignOptimizedBiddingGoalEnum[keyof typeof ApiCampaignsControllerUpdateInputCampaignOptimizedBiddingGoalEnum]

/**
 * Check if a given object implements the ApiCampaignsControllerUpdateInputCampaign interface.
 */
export function instanceOfApiCampaignsControllerUpdateInputCampaign(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiCampaignsControllerUpdateInputCampaignFromJSON(
  json: any
): ApiCampaignsControllerUpdateInputCampaign {
  return ApiCampaignsControllerUpdateInputCampaignFromJSONTyped(json, false)
}

export function ApiCampaignsControllerUpdateInputCampaignFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiCampaignsControllerUpdateInputCampaign {
  if (json === undefined || json === null) {
    return json
  }
  return {
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
    name: !exists(json, 'name') ? undefined : json['name'],
    budget: !exists(json, 'budget') ? undefined : json['budget'],
    objective: !exists(json, 'objective') ? undefined : json['objective'],
    objectiveCustom: !exists(json, 'objective_custom') ? undefined : json['objective_custom'],
    memo: !exists(json, 'memo') ? undefined : json['memo'],
    purchaseOrder: !exists(json, 'purchase_order') ? undefined : json['purchase_order'],
    paymentType: !exists(json, 'payment_type') ? undefined : json['payment_type'],
    budgetType: !exists(json, 'budget_type') ? undefined : json['budget_type'],
    targetDailyBudget: !exists(json, 'target_daily_budget')
      ? undefined
      : json['target_daily_budget'],
    billPayer: !exists(json, 'bill_payer') ? undefined : json['bill_payer'],
    automatedBiddingEnabled: !exists(json, 'automated_bidding_enabled')
      ? undefined
      : json['automated_bidding_enabled'],
    automatedBiddingGoal: !exists(json, 'automated_bidding_goal')
      ? undefined
      : json['automated_bidding_goal'],
    automatedBiddingParameters: !exists(json, 'automated_bidding_parameters')
      ? undefined
      : ApiCampaignsControllerUpdateInputCampaignAutomatedBiddingParametersFromJSON(
          json['automated_bidding_parameters']
        ),
    optimizedBiddingEnabled: !exists(json, 'optimized_bidding_enabled')
      ? undefined
      : json['optimized_bidding_enabled'],
    optimizedBiddingGoal: !exists(json, 'optimized_bidding_goal')
      ? undefined
      : json['optimized_bidding_goal'],
    optimizedBiddingParameters: !exists(json, 'optimized_bidding_parameters')
      ? undefined
      : ApiCampaignsControllerUpdateInputCampaignOptimizedBiddingParametersFromJSON(
          json['optimized_bidding_parameters']
        ),
    goal: !exists(json, 'goal') ? undefined : json['goal'],
    adGroups: !exists(json, 'ad_groups')
      ? undefined
      : (json['ad_groups'] as Array<any>).map(PutCampaignsIdParamCampaignAdGroupsFromJSON),
    optimizedBiddingClearKeywordsAsync: !exists(json, 'optimized_bidding_clear_keywords_async')
      ? undefined
      : json['optimized_bidding_clear_keywords_async'],
    startsAt: !exists(json, 'starts_at') ? undefined : json['starts_at'],
    endsAt: !exists(json, 'ends_at') ? undefined : json['ends_at'],
  }
}

export function ApiCampaignsControllerUpdateInputCampaignToJSON(
  value?: ApiCampaignsControllerUpdateInputCampaign | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    enabled: value.enabled,
    name: value.name,
    budget: value.budget,
    objective: value.objective,
    objective_custom: value.objectiveCustom,
    memo: value.memo,
    purchase_order: value.purchaseOrder,
    payment_type: value.paymentType,
    budget_type: value.budgetType,
    target_daily_budget: value.targetDailyBudget,
    bill_payer: value.billPayer,
    automated_bidding_enabled: value.automatedBiddingEnabled,
    automated_bidding_goal: value.automatedBiddingGoal,
    automated_bidding_parameters:
      ApiCampaignsControllerUpdateInputCampaignAutomatedBiddingParametersToJSON(
        value.automatedBiddingParameters
      ),
    optimized_bidding_enabled: value.optimizedBiddingEnabled,
    optimized_bidding_goal: value.optimizedBiddingGoal,
    optimized_bidding_parameters:
      ApiCampaignsControllerUpdateInputCampaignOptimizedBiddingParametersToJSON(
        value.optimizedBiddingParameters
      ),
    goal: value.goal,
    ad_groups:
      value.adGroups === undefined
        ? undefined
        : (value.adGroups as Array<any>).map(PutCampaignsIdParamCampaignAdGroupsToJSON),
    optimized_bidding_clear_keywords_async: value.optimizedBiddingClearKeywordsAsync,
    starts_at: value.startsAt,
    ends_at: value.endsAt,
  }
}
