// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface BulkAdGroupResponseDataAttributes
 */
export interface BulkAdGroupResponseDataAttributes {
  /**
   * UUID of Ad Group
   * @type {string}
   * @memberof BulkAdGroupResponseDataAttributes
   */
  id: string
  /**
   * A descriptive name for the ad group.
   * @type {string}
   * @memberof BulkAdGroupResponseDataAttributes
   */
  name: string
  /**
   * Set to true to create an active ad group. Ads are only run for active ad groups with at least one product which belong to active, running campaigns. Set to false to create an inactive ad group.
   * @type {boolean}
   * @memberof BulkAdGroupResponseDataAttributes
   */
  enabled: boolean
  /**
   * The maximum amount, in dollars, you want to pay per click for advertised products in this ad group.
   * @type {number}
   * @memberof BulkAdGroupResponseDataAttributes
   */
  defaultBid: number
  /**
   * The ad group's status. Can be either campaign_ended, campaign_out_of_budget, campaign_paused, campaign_draft, paused, scheduled, active, or unknown.
   * @type {string}
   * @memberof BulkAdGroupResponseDataAttributes
   */
  adGroupStatus: BulkAdGroupResponseDataAttributesAdGroupStatusEnum
  /**
   * The placement targeting type for this ad group. Currently supported values: ['default', 'impulse'].
   * @type {string}
   * @memberof BulkAdGroupResponseDataAttributes
   */
  targetingType?: BulkAdGroupResponseDataAttributesTargetingTypeEnum
}

/**
 * @export
 */
export const BulkAdGroupResponseDataAttributesAdGroupStatusEnum = {
  IncompleteAdGroupProducts: 'incomplete_ad_group_products',
  CampaignEnded: 'campaign_ended',
  CampaignOutOfBudget: 'campaign_out_of_budget',
  CampaignPaused: 'campaign_paused',
  CampaignDraft: 'campaign_draft',
  Paused: 'paused',
  Scheduled: 'scheduled',
  Active: 'active',
  Unknown: 'unknown',
} as const
export type BulkAdGroupResponseDataAttributesAdGroupStatusEnum =
  typeof BulkAdGroupResponseDataAttributesAdGroupStatusEnum[keyof typeof BulkAdGroupResponseDataAttributesAdGroupStatusEnum]

/**
 * @export
 */
export const BulkAdGroupResponseDataAttributesTargetingTypeEnum = {
  Impulse: 'impulse',
  Default: 'default',
} as const
export type BulkAdGroupResponseDataAttributesTargetingTypeEnum =
  typeof BulkAdGroupResponseDataAttributesTargetingTypeEnum[keyof typeof BulkAdGroupResponseDataAttributesTargetingTypeEnum]

/**
 * Check if a given object implements the BulkAdGroupResponseDataAttributes interface.
 */
export function instanceOfBulkAdGroupResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'defaultBid' in value
  isInstance = isInstance && 'adGroupStatus' in value

  return isInstance
}

export function BulkAdGroupResponseDataAttributesFromJSON(
  json: any
): BulkAdGroupResponseDataAttributes {
  return BulkAdGroupResponseDataAttributesFromJSONTyped(json, false)
}

export function BulkAdGroupResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BulkAdGroupResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    enabled: json['enabled'],
    defaultBid: json['default_bid'],
    adGroupStatus: json['ad_group_status'],
    targetingType: !exists(json, 'targeting_type') ? undefined : json['targeting_type'],
  }
}

export function BulkAdGroupResponseDataAttributesToJSON(
  value?: BulkAdGroupResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    enabled: value.enabled,
    default_bid: value.defaultBid,
    ad_group_status: value.adGroupStatus,
    targeting_type: value.targetingType,
  }
}
