// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutCampaignResponseDataAttributes } from './PutCampaignResponseDataAttributes'
import {
  PutCampaignResponseDataAttributesFromJSON,
  PutCampaignResponseDataAttributesFromJSONTyped,
  PutCampaignResponseDataAttributesToJSON,
} from './PutCampaignResponseDataAttributes'
import type { PutCampaignResponseDataRelationships } from './PutCampaignResponseDataRelationships'
import {
  PutCampaignResponseDataRelationshipsFromJSON,
  PutCampaignResponseDataRelationshipsFromJSONTyped,
  PutCampaignResponseDataRelationshipsToJSON,
} from './PutCampaignResponseDataRelationships'

/**
 *
 * @export
 * @interface PutCampaignResponseData
 */
export interface PutCampaignResponseData {
  /**
   * The campaign ID.
   * @type {string}
   * @memberof PutCampaignResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PutCampaignResponseData
   */
  type: PutCampaignResponseDataTypeEnum
  /**
   *
   * @type {PutCampaignResponseDataAttributes}
   * @memberof PutCampaignResponseData
   */
  attributes: PutCampaignResponseDataAttributes
  /**
   *
   * @type {PutCampaignResponseDataRelationships}
   * @memberof PutCampaignResponseData
   */
  relationships: PutCampaignResponseDataRelationships
}

/**
 * @export
 */
export const PutCampaignResponseDataTypeEnum = {
  Campaign: 'campaign',
} as const
export type PutCampaignResponseDataTypeEnum =
  typeof PutCampaignResponseDataTypeEnum[keyof typeof PutCampaignResponseDataTypeEnum]

/**
 * Check if a given object implements the PutCampaignResponseData interface.
 */
export function instanceOfPutCampaignResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value
  isInstance = isInstance && 'relationships' in value

  return isInstance
}

export function PutCampaignResponseDataFromJSON(json: any): PutCampaignResponseData {
  return PutCampaignResponseDataFromJSONTyped(json, false)
}

export function PutCampaignResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutCampaignResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: PutCampaignResponseDataAttributesFromJSON(json['attributes']),
    relationships: PutCampaignResponseDataRelationshipsFromJSON(json['relationships']),
  }
}

export function PutCampaignResponseDataToJSON(value?: PutCampaignResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: PutCampaignResponseDataAttributesToJSON(value.attributes),
    relationships: PutCampaignResponseDataRelationshipsToJSON(value.relationships),
  }
}
