import { css } from '@emotion/react'
import { spacing, useTheme } from '@instacart/ids-core'
import { FC } from 'react'
import { useFlipper } from 'common'
import { FLIPPER_PAGE_LAYOUT_REFRESH } from 'common/flippers'
import { useHeaderHeight } from 'components/Header/header.utils'

const useStyles = () => {
  const theme = useTheme()
  const headerHeight = useHeaderHeight()
  const pageLayoutRefreshEnabled = useFlipper(FLIPPER_PAGE_LAYOUT_REFRESH)

  return {
    root: css({
      minHeight: pageLayoutRefreshEnabled ? undefined : `calc(100vh - ${headerHeight}px)`,
      boxSizing: 'border-box',
      background: theme.colors.systemGrayscale00,
    }),
    wrapped: css({
      padding: `0 ${spacing.s16}px ${spacing.s16}px`,
      position: 'relative',
    }),
    wrappedNoPaddingBottom: {
      paddingBottom: 0,
    },
  }
}

type PageProps = {
  children: React.ReactNode
  fullBleed?: boolean
  noPaddingBottom?: boolean
}

const Page: FC<React.PropsWithChildren<PageProps>> = ({ children, fullBleed, noPaddingBottom }) => {
  const styles = useStyles()
  return (
    <main
      css={[
        styles.root,
        !fullBleed && styles.wrapped,
        noPaddingBottom && styles.wrappedNoPaddingBottom,
      ]}
    >
      {children}
    </main>
  )
}

export default Page
