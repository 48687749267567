// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { BulkAdGroupResponseData } from './BulkAdGroupResponseData'
import {
  BulkAdGroupResponseDataFromJSON,
  BulkAdGroupResponseDataFromJSONTyped,
  BulkAdGroupResponseDataToJSON,
} from './BulkAdGroupResponseData'
import type { BulkAdGroupResponseMeta } from './BulkAdGroupResponseMeta'
import {
  BulkAdGroupResponseMetaFromJSON,
  BulkAdGroupResponseMetaFromJSONTyped,
  BulkAdGroupResponseMetaToJSON,
} from './BulkAdGroupResponseMeta'

/**
 *
 * @export
 * @interface BulkAdGroupResponse
 */
export interface BulkAdGroupResponse {
  /**
   *
   * @type {BulkAdGroupResponseData}
   * @memberof BulkAdGroupResponse
   */
  data: BulkAdGroupResponseData
  /**
   *
   * @type {BulkAdGroupResponseMeta}
   * @memberof BulkAdGroupResponse
   */
  meta: BulkAdGroupResponseMeta
}

/**
 * Check if a given object implements the BulkAdGroupResponse interface.
 */
export function instanceOfBulkAdGroupResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function BulkAdGroupResponseFromJSON(json: any): BulkAdGroupResponse {
  return BulkAdGroupResponseFromJSONTyped(json, false)
}

export function BulkAdGroupResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BulkAdGroupResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: BulkAdGroupResponseDataFromJSON(json['data']),
    meta: BulkAdGroupResponseMetaFromJSON(json['meta']),
  }
}

export function BulkAdGroupResponseToJSON(value?: BulkAdGroupResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: BulkAdGroupResponseDataToJSON(value.data),
    meta: BulkAdGroupResponseMetaToJSON(value.meta),
  }
}
