// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostCampaignsBulkParamCampaignsAutomatedBiddingParameters } from './PostCampaignsBulkParamCampaignsAutomatedBiddingParameters'
import {
  PostCampaignsBulkParamCampaignsAutomatedBiddingParametersFromJSON,
  PostCampaignsBulkParamCampaignsAutomatedBiddingParametersFromJSONTyped,
  PostCampaignsBulkParamCampaignsAutomatedBiddingParametersToJSON,
} from './PostCampaignsBulkParamCampaignsAutomatedBiddingParameters'
import type { PostCampaignsBulkParamCampaignsOptimizedBiddingParameters } from './PostCampaignsBulkParamCampaignsOptimizedBiddingParameters'
import {
  PostCampaignsBulkParamCampaignsOptimizedBiddingParametersFromJSON,
  PostCampaignsBulkParamCampaignsOptimizedBiddingParametersFromJSONTyped,
  PostCampaignsBulkParamCampaignsOptimizedBiddingParametersToJSON,
} from './PostCampaignsBulkParamCampaignsOptimizedBiddingParameters'

/**
 *
 * @export
 * @interface PostCampaignsBulkParamCampaigns
 */
export interface PostCampaignsBulkParamCampaigns {
  /**
   * Set to true to activate the campaign. You will bid according to the details of the ad groups contained within this campaign. Set to false and the campaign is not active.
   * @type {boolean}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  enabled?: boolean
  /**
   * A descriptive name for the campaign.
   * @type {string}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  name: string
  /**
   * The total budget for the ads campaign, expressed in dollars. When the campaign has spent this budget, it automatically ends.
   * @type {number}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  budget?: number
  /**
   * Send "featured_product" for this field. We currently only support one type of ad campaign.
   * @type {string}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  campaignType: PostCampaignsBulkParamCampaignsCampaignTypeEnum
  /**
   * For reference only. You can specify the objective of the campaign for future reference. Send one of the following values: "sales", "market_share", "consideration", "awareness", "other".
   * @type {string}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  objective?: PostCampaignsBulkParamCampaignsObjectiveEnum
  /**
   * If you sent "other" for the objective field, send a brief phrase describing the campaign’s objective. Do not send this parameter if you are not sending a value for the objective field or if you sent a value that is not "other".
   * @type {string}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  objectiveCustom?: string
  /**
   * If you want to include a memo line on the invoices Instacart generates for this ad campaign, specify it in this field.
   * @type {string}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  memo?: string
  /**
   * If you want to include a purchase order number on the invoices Instacart generates for this ad campaign, specify it in this field.
   * @type {string}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  purchaseOrder?: string
  /**
   * For reference only. Specifies how you want Instacart to bill you for the campaign. If you want Instacart to bill on a per purchase order, specify "purchase_order". If you want us to bill to your account, specify "account".
   * @type {string}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  paymentType?: PostCampaignsBulkParamCampaignsPaymentTypeEnum
  /**
   * The budgeting strategy for the campaign. One of "daily_budget", "max_impressions".
   * @type {string}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  budgetType: PostCampaignsBulkParamCampaignsBudgetTypeEnum
  /**
   * The daily budget for a v2 campaign, expressed in dollars. If `optimized_bidding_enabled` set to `true`, the minimum budget required is $20.
   * @type {number}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  targetDailyBudget?: number
  /**
   * Override for default account-level bill payer. Agency, advertiser or retailer to be billed for the campaign.
   * @type {string}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  billPayer?: PostCampaignsBulkParamCampaignsBillPayerEnum
  /**
   * Enables automated bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  automatedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  automatedBiddingGoal?: PostCampaignsBulkParamCampaignsAutomatedBiddingGoalEnum
  /**
   *
   * @type {PostCampaignsBulkParamCampaignsAutomatedBiddingParameters}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  automatedBiddingParameters?: PostCampaignsBulkParamCampaignsAutomatedBiddingParameters
  /**
   * The campaign’s goal
   * @type {string}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  goal?: string
  /**
   * The campaign’s goal format
   * @type {string}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  goalFormat?: string
  /**
   * Enables optimized bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  optimizedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  optimizedBiddingGoal?: PostCampaignsBulkParamCampaignsOptimizedBiddingGoalEnum
  /**
   *
   * @type {PostCampaignsBulkParamCampaignsOptimizedBiddingParameters}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  optimizedBiddingParameters?: PostCampaignsBulkParamCampaignsOptimizedBiddingParameters
  /**
   * Set to true to create campaign as draft. Default is false if unset
   * @type {boolean}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  draft?: boolean
  /**
   * The campaign’s start date in the ‘YYYY-MM-DD’ format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  startsAt?: string
  /**
   * The campaign’s effective end date in the ‘YYYY-MM-DD’ format. Campaigns end at midnight, PST, on the specified date. Do not send and the campaign only stops when the campaign’s budget has been spent.
   * @type {string}
   * @memberof PostCampaignsBulkParamCampaigns
   */
  endsAt?: string
}

/**
 * @export
 */
export const PostCampaignsBulkParamCampaignsCampaignTypeEnum = {
  FeaturedProduct: 'featured_product',
} as const
export type PostCampaignsBulkParamCampaignsCampaignTypeEnum =
  typeof PostCampaignsBulkParamCampaignsCampaignTypeEnum[keyof typeof PostCampaignsBulkParamCampaignsCampaignTypeEnum]

/**
 * @export
 */
export const PostCampaignsBulkParamCampaignsObjectiveEnum = {
  Sales: 'sales',
  MarketShare: 'market_share',
  Consideration: 'consideration',
  Awareness: 'awareness',
  Other: 'other',
} as const
export type PostCampaignsBulkParamCampaignsObjectiveEnum =
  typeof PostCampaignsBulkParamCampaignsObjectiveEnum[keyof typeof PostCampaignsBulkParamCampaignsObjectiveEnum]

/**
 * @export
 */
export const PostCampaignsBulkParamCampaignsPaymentTypeEnum = {
  Account: 'account',
  PurchaseOrder: 'purchase_order',
} as const
export type PostCampaignsBulkParamCampaignsPaymentTypeEnum =
  typeof PostCampaignsBulkParamCampaignsPaymentTypeEnum[keyof typeof PostCampaignsBulkParamCampaignsPaymentTypeEnum]

/**
 * @export
 */
export const PostCampaignsBulkParamCampaignsBudgetTypeEnum = {
  DailyBudget: 'daily_budget',
  MaxImpressions: 'max_impressions',
} as const
export type PostCampaignsBulkParamCampaignsBudgetTypeEnum =
  typeof PostCampaignsBulkParamCampaignsBudgetTypeEnum[keyof typeof PostCampaignsBulkParamCampaignsBudgetTypeEnum]

/**
 * @export
 */
export const PostCampaignsBulkParamCampaignsBillPayerEnum = {
  Agency: 'agency',
  Advertiser: 'advertiser',
  Retailer: 'retailer',
} as const
export type PostCampaignsBulkParamCampaignsBillPayerEnum =
  typeof PostCampaignsBulkParamCampaignsBillPayerEnum[keyof typeof PostCampaignsBulkParamCampaignsBillPayerEnum]

/**
 * @export
 */
export const PostCampaignsBulkParamCampaignsAutomatedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type PostCampaignsBulkParamCampaignsAutomatedBiddingGoalEnum =
  typeof PostCampaignsBulkParamCampaignsAutomatedBiddingGoalEnum[keyof typeof PostCampaignsBulkParamCampaignsAutomatedBiddingGoalEnum]

/**
 * @export
 */
export const PostCampaignsBulkParamCampaignsOptimizedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type PostCampaignsBulkParamCampaignsOptimizedBiddingGoalEnum =
  typeof PostCampaignsBulkParamCampaignsOptimizedBiddingGoalEnum[keyof typeof PostCampaignsBulkParamCampaignsOptimizedBiddingGoalEnum]

/**
 * Check if a given object implements the PostCampaignsBulkParamCampaigns interface.
 */
export function instanceOfPostCampaignsBulkParamCampaigns(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'campaignType' in value
  isInstance = isInstance && 'budgetType' in value

  return isInstance
}

export function PostCampaignsBulkParamCampaignsFromJSON(
  json: any
): PostCampaignsBulkParamCampaigns {
  return PostCampaignsBulkParamCampaignsFromJSONTyped(json, false)
}

export function PostCampaignsBulkParamCampaignsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostCampaignsBulkParamCampaigns {
  if (json === undefined || json === null) {
    return json
  }
  return {
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
    name: json['name'],
    budget: !exists(json, 'budget') ? undefined : json['budget'],
    campaignType: json['campaign_type'],
    objective: !exists(json, 'objective') ? undefined : json['objective'],
    objectiveCustom: !exists(json, 'objective_custom') ? undefined : json['objective_custom'],
    memo: !exists(json, 'memo') ? undefined : json['memo'],
    purchaseOrder: !exists(json, 'purchase_order') ? undefined : json['purchase_order'],
    paymentType: !exists(json, 'payment_type') ? undefined : json['payment_type'],
    budgetType: json['budget_type'],
    targetDailyBudget: !exists(json, 'target_daily_budget')
      ? undefined
      : json['target_daily_budget'],
    billPayer: !exists(json, 'bill_payer') ? undefined : json['bill_payer'],
    automatedBiddingEnabled: !exists(json, 'automated_bidding_enabled')
      ? undefined
      : json['automated_bidding_enabled'],
    automatedBiddingGoal: !exists(json, 'automated_bidding_goal')
      ? undefined
      : json['automated_bidding_goal'],
    automatedBiddingParameters: !exists(json, 'automated_bidding_parameters')
      ? undefined
      : PostCampaignsBulkParamCampaignsAutomatedBiddingParametersFromJSON(
          json['automated_bidding_parameters']
        ),
    goal: !exists(json, 'goal') ? undefined : json['goal'],
    goalFormat: !exists(json, 'goal_format') ? undefined : json['goal_format'],
    optimizedBiddingEnabled: !exists(json, 'optimized_bidding_enabled')
      ? undefined
      : json['optimized_bidding_enabled'],
    optimizedBiddingGoal: !exists(json, 'optimized_bidding_goal')
      ? undefined
      : json['optimized_bidding_goal'],
    optimizedBiddingParameters: !exists(json, 'optimized_bidding_parameters')
      ? undefined
      : PostCampaignsBulkParamCampaignsOptimizedBiddingParametersFromJSON(
          json['optimized_bidding_parameters']
        ),
    draft: !exists(json, 'draft') ? undefined : json['draft'],
    startsAt: !exists(json, 'starts_at') ? undefined : json['starts_at'],
    endsAt: !exists(json, 'ends_at') ? undefined : json['ends_at'],
  }
}

export function PostCampaignsBulkParamCampaignsToJSON(
  value?: PostCampaignsBulkParamCampaigns | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    enabled: value.enabled,
    name: value.name,
    budget: value.budget,
    campaign_type: value.campaignType,
    objective: value.objective,
    objective_custom: value.objectiveCustom,
    memo: value.memo,
    purchase_order: value.purchaseOrder,
    payment_type: value.paymentType,
    budget_type: value.budgetType,
    target_daily_budget: value.targetDailyBudget,
    bill_payer: value.billPayer,
    automated_bidding_enabled: value.automatedBiddingEnabled,
    automated_bidding_goal: value.automatedBiddingGoal,
    automated_bidding_parameters: PostCampaignsBulkParamCampaignsAutomatedBiddingParametersToJSON(
      value.automatedBiddingParameters
    ),
    goal: value.goal,
    goal_format: value.goalFormat,
    optimized_bidding_enabled: value.optimizedBiddingEnabled,
    optimized_bidding_goal: value.optimizedBiddingGoal,
    optimized_bidding_parameters: PostCampaignsBulkParamCampaignsOptimizedBiddingParametersToJSON(
      value.optimizedBiddingParameters
    ),
    draft: value.draft,
    starts_at: value.startsAt,
    ends_at: value.endsAt,
  }
}
