import { IsUserAuthorizedParams, isUserAuthorized } from 'common/auth'
import { FlipperIdType } from 'common/types'
import { DropdownMenuItem, DropdownMenuLinkItem, DropdownMenuAnchorItem } from 'components/ids-ads'
import { useAuthContext, useCapabilitiesContext } from 'context'
import { useIsPaymentStatusActive } from '../common/accountPaymentStatus'
import { isInfraDebugButtonEnabled } from '../pages/Campaign/utils/flippers'
import { EllipsisDropdownMenu, EllipsisDropdownMenuProps } from './EllipsisDropdownMenu'
import FormattedMessage from './FormattedMessage'

export interface ActionsDropdownMenuProps
  extends Pick<EllipsisDropdownMenuProps, 'id' | 'inTableColumn'> {
  editLink?: string
  onEditClick?: () => void
  editCapability?: string[]
  onCopyClick?: () => void
  copyCapability?: string[]
  copyFlipper?: FlipperIdType
  onChangeHistoriesClick?: () => void
  changeHistoriesCapability?: string[]
  changeHistoriesFlipper?: FlipperIdType
  onAbTestClick?: () => void
  abTestCapability?: string[]
  abTestFlipper?: FlipperIdType
  onDeleteClick?: () => void
  deleteCapability?: string[]
  deleteFlipper?: FlipperIdType
  debugLink?: string
  debugCapability?: string[]
  debugFlipper?: FlipperIdType
  onPromotionCreationClick?: () => void
  promotionCreationAuth?: IsUserAuthorizedParams
}

export default function ActionsDropdownMenu({
  editLink,
  onEditClick,
  editCapability,
  onCopyClick,
  copyCapability,
  copyFlipper,
  onChangeHistoriesClick,
  changeHistoriesCapability,
  changeHistoriesFlipper,
  onAbTestClick,
  abTestCapability,
  abTestFlipper,
  onDeleteClick,
  deleteCapability,
  deleteFlipper,
  debugLink,
  debugCapability,
  debugFlipper,
  onPromotionCreationClick,
  promotionCreationAuth,
  ...props
}: ActionsDropdownMenuProps) {
  const context = useAuthContext()
  const capabilitiesContext = useCapabilitiesContext()
  const isPaymentStatusActive = useIsPaymentStatusActive()

  if (!isPaymentStatusActive) return null

  const isEditAuthorized =
    editLink &&
    isUserAuthorized(context, capabilitiesContext, {
      capabilities: editCapability,
    })

  const isCopyAuthorized =
    onCopyClick &&
    isUserAuthorized(context, capabilitiesContext, {
      flipper: copyFlipper,
      capabilities: copyCapability,
    })

  const isChangeHistoriesAuthorized =
    onChangeHistoriesClick &&
    isUserAuthorized(context, capabilitiesContext, {
      flipper: changeHistoriesFlipper,
      capabilities: changeHistoriesCapability,
    })

  const isAbTestAuthorized =
    onAbTestClick &&
    isUserAuthorized(context, capabilitiesContext, {
      flipper: abTestFlipper,
      capabilities: abTestCapability,
    })

  const isDeleteAuthorized =
    onDeleteClick &&
    isUserAuthorized(context, capabilitiesContext, {
      flipper: deleteFlipper,
      capabilities: deleteCapability,
    })

  const isDebugAuthorized =
    debugLink &&
    isInfraDebugButtonEnabled(context) &&
    isUserAuthorized(context, capabilitiesContext, {
      flipper: debugFlipper,
      capabilities: debugCapability,
    })

  const isPromotionCreationAuthorized =
    onPromotionCreationClick &&
    promotionCreationAuth &&
    isUserAuthorized(context, capabilitiesContext, promotionCreationAuth)

  if (
    !isEditAuthorized &&
    !isCopyAuthorized &&
    !isAbTestAuthorized &&
    !isDeleteAuthorized &&
    !isDebugAuthorized &&
    !isPromotionCreationAuthorized &&
    !isChangeHistoriesAuthorized
  )
    return null

  return (
    <EllipsisDropdownMenu {...props}>
      {isEditAuthorized && (
        <DropdownMenuLinkItem to={editLink} onClick={onEditClick} data-testid="edit-option">
          <FormattedMessage id="common.edit" />
        </DropdownMenuLinkItem>
      )}
      {isCopyAuthorized && (
        <DropdownMenuItem data-testid="copy-option" onClick={onCopyClick}>
          <FormattedMessage id="common.copy" />
        </DropdownMenuItem>
      )}
      {isChangeHistoriesAuthorized && (
        <DropdownMenuItem data-testid="change-histories-button" onClick={onChangeHistoriesClick}>
          <FormattedMessage id="components.actionDropdown.changeHistories.menuItem" />
        </DropdownMenuItem>
      )}
      {isAbTestAuthorized && (
        <DropdownMenuItem data-testid="ab-test-option" onClick={onAbTestClick}>
          <FormattedMessage id="abTesting.title" />
        </DropdownMenuItem>
      )}
      {isDeleteAuthorized && (
        <DropdownMenuItem data-testid="delete-option" onClick={onDeleteClick}>
          <FormattedMessage id="common.delete" />
        </DropdownMenuItem>
      )}
      {isDebugAuthorized && (
        <DropdownMenuAnchorItem data-testid="debug-button" href={debugLink} target="_blank">
          <FormattedMessage id="common.debug" />
        </DropdownMenuAnchorItem>
      )}
      {isPromotionCreationAuthorized && (
        <DropdownMenuItem data-testid="add-a-promo-button" onClick={onPromotionCreationClick}>
          <FormattedMessage id="components.actionDropdown.addAPromotion.menuItem" />
        </DropdownMenuItem>
      )}
    </EllipsisDropdownMenu>
  )
}
