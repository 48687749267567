// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { BulkCampaignsResponseMetaErrors } from './BulkCampaignsResponseMetaErrors'
import {
  BulkCampaignsResponseMetaErrorsFromJSON,
  BulkCampaignsResponseMetaErrorsFromJSONTyped,
  BulkCampaignsResponseMetaErrorsToJSON,
} from './BulkCampaignsResponseMetaErrors'

/**
 *
 * @export
 * @interface BulkCampaignsResponseMeta
 */
export interface BulkCampaignsResponseMeta {
  /**
   * Request UUID
   * @type {string}
   * @memberof BulkCampaignsResponseMeta
   */
  requestUuid: string
  /**
   *
   * @type {Array<BulkCampaignsResponseMetaErrors>}
   * @memberof BulkCampaignsResponseMeta
   */
  errors: Array<BulkCampaignsResponseMetaErrors>
  /**
   * Total Number Of Errors
   * @type {number}
   * @memberof BulkCampaignsResponseMeta
   */
  totalErrors: number
  /**
   * Total Number Of Success
   * @type {number}
   * @memberof BulkCampaignsResponseMeta
   */
  totalSuccess: number
  /**
   * Total Number Of Requests
   * @type {number}
   * @memberof BulkCampaignsResponseMeta
   */
  totalRequested: number
}

/**
 * Check if a given object implements the BulkCampaignsResponseMeta interface.
 */
export function instanceOfBulkCampaignsResponseMeta(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'requestUuid' in value
  isInstance = isInstance && 'errors' in value
  isInstance = isInstance && 'totalErrors' in value
  isInstance = isInstance && 'totalSuccess' in value
  isInstance = isInstance && 'totalRequested' in value

  return isInstance
}

export function BulkCampaignsResponseMetaFromJSON(json: any): BulkCampaignsResponseMeta {
  return BulkCampaignsResponseMetaFromJSONTyped(json, false)
}

export function BulkCampaignsResponseMetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BulkCampaignsResponseMeta {
  if (json === undefined || json === null) {
    return json
  }
  return {
    requestUuid: json['request_uuid'],
    errors: (json['errors'] as Array<any>).map(BulkCampaignsResponseMetaErrorsFromJSON),
    totalErrors: json['total_errors'],
    totalSuccess: json['total_success'],
    totalRequested: json['total_requested'],
  }
}

export function BulkCampaignsResponseMetaToJSON(value?: BulkCampaignsResponseMeta | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    request_uuid: value.requestUuid,
    errors: (value.errors as Array<any>).map(BulkCampaignsResponseMetaErrorsToJSON),
    total_errors: value.totalErrors,
    total_success: value.totalSuccess,
    total_requested: value.totalRequested,
  }
}
