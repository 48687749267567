// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiCampaignsControllerCreateInputCampaign } from './ApiCampaignsControllerCreateInputCampaign'
import {
  ApiCampaignsControllerCreateInputCampaignFromJSON,
  ApiCampaignsControllerCreateInputCampaignFromJSONTyped,
  ApiCampaignsControllerCreateInputCampaignToJSON,
} from './ApiCampaignsControllerCreateInputCampaign'

/**
 *
 * @export
 * @interface ApiCampaignsControllerCreateInput
 */
export interface ApiCampaignsControllerCreateInput {
  /**
   * Set to true to create campaign as draft. Default is false if unset
   * @type {boolean}
   * @memberof ApiCampaignsControllerCreateInput
   */
  draft?: boolean
  /**
   *
   * @type {ApiCampaignsControllerCreateInputCampaign}
   * @memberof ApiCampaignsControllerCreateInput
   */
  campaign: ApiCampaignsControllerCreateInputCampaign
}

/**
 * Check if a given object implements the ApiCampaignsControllerCreateInput interface.
 */
export function instanceOfApiCampaignsControllerCreateInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'campaign' in value

  return isInstance
}

export function ApiCampaignsControllerCreateInputFromJSON(
  json: any
): ApiCampaignsControllerCreateInput {
  return ApiCampaignsControllerCreateInputFromJSONTyped(json, false)
}

export function ApiCampaignsControllerCreateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiCampaignsControllerCreateInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    draft: !exists(json, 'draft') ? undefined : json['draft'],
    campaign: ApiCampaignsControllerCreateInputCampaignFromJSON(json['campaign']),
  }
}

export function ApiCampaignsControllerCreateInputToJSON(
  value?: ApiCampaignsControllerCreateInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    draft: value.draft,
    campaign: ApiCampaignsControllerCreateInputCampaignToJSON(value.campaign),
  }
}
