// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostAdGroupsBulkParamAdGroups } from './PostAdGroupsBulkParamAdGroups'
import {
  PostAdGroupsBulkParamAdGroupsFromJSON,
  PostAdGroupsBulkParamAdGroupsFromJSONTyped,
  PostAdGroupsBulkParamAdGroupsToJSON,
} from './PostAdGroupsBulkParamAdGroups'

/**
 *
 * @export
 * @interface ApiAdGroupsControllerBulkCreateInput
 */
export interface ApiAdGroupsControllerBulkCreateInput {
  /**
   * Ad Group params
   * @type {Array<PostAdGroupsBulkParamAdGroups>}
   * @memberof ApiAdGroupsControllerBulkCreateInput
   */
  adGroups: Array<PostAdGroupsBulkParamAdGroups>
}

/**
 * Check if a given object implements the ApiAdGroupsControllerBulkCreateInput interface.
 */
export function instanceOfApiAdGroupsControllerBulkCreateInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'adGroups' in value

  return isInstance
}

export function ApiAdGroupsControllerBulkCreateInputFromJSON(
  json: any
): ApiAdGroupsControllerBulkCreateInput {
  return ApiAdGroupsControllerBulkCreateInputFromJSONTyped(json, false)
}

export function ApiAdGroupsControllerBulkCreateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdGroupsControllerBulkCreateInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    adGroups: (json['ad_groups'] as Array<any>).map(PostAdGroupsBulkParamAdGroupsFromJSON),
  }
}

export function ApiAdGroupsControllerBulkCreateInputToJSON(
  value?: ApiAdGroupsControllerBulkCreateInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    ad_groups: (value.adGroups as Array<any>).map(PostAdGroupsBulkParamAdGroupsToJSON),
  }
}
