import { ClientCache } from 'service/client'
import OpenAPICampaignClient from 'service/openapi-client/campaign'
import { CampaignClientInterface } from 'service/types'

const clientCache: ClientCache<CampaignClientInterface> = {}

/* Return the existing client instance or instantiate the client and
 * assign it to be the existing instance.
 */
export const getCampaignClient = async () => {
  if (clientCache.current) {
    return clientCache.current
  }

  clientCache.current = new OpenAPICampaignClient()

  return clientCache.current
}
