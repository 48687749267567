// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutCampaignsBulkParamCampaignsAdGroups } from './PutCampaignsBulkParamCampaignsAdGroups'
import {
  PutCampaignsBulkParamCampaignsAdGroupsFromJSON,
  PutCampaignsBulkParamCampaignsAdGroupsFromJSONTyped,
  PutCampaignsBulkParamCampaignsAdGroupsToJSON,
} from './PutCampaignsBulkParamCampaignsAdGroups'
import type { PutCampaignsBulkParamCampaignsAutomatedBiddingParameters } from './PutCampaignsBulkParamCampaignsAutomatedBiddingParameters'
import {
  PutCampaignsBulkParamCampaignsAutomatedBiddingParametersFromJSON,
  PutCampaignsBulkParamCampaignsAutomatedBiddingParametersFromJSONTyped,
  PutCampaignsBulkParamCampaignsAutomatedBiddingParametersToJSON,
} from './PutCampaignsBulkParamCampaignsAutomatedBiddingParameters'
import type { PutCampaignsBulkParamCampaignsOptimizedBiddingParameters } from './PutCampaignsBulkParamCampaignsOptimizedBiddingParameters'
import {
  PutCampaignsBulkParamCampaignsOptimizedBiddingParametersFromJSON,
  PutCampaignsBulkParamCampaignsOptimizedBiddingParametersFromJSONTyped,
  PutCampaignsBulkParamCampaignsOptimizedBiddingParametersToJSON,
} from './PutCampaignsBulkParamCampaignsOptimizedBiddingParameters'

/**
 *
 * @export
 * @interface PutCampaignsBulkParamCampaigns
 */
export interface PutCampaignsBulkParamCampaigns {
  /**
   * The campaign ID
   * @type {string}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  id: string
  /**
   * Set to true to activate the campaign. Set to false to deactivate the campaign. Do not specify this field to make no update. This field cannot be set to true for a campaign in draft state. Draft campaign can only be launched from Instacart Ads Manager.
   * @type {boolean}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  enabled?: boolean
  /**
   * Specify a value to update the campaign’s name.
   * @type {string}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  name?: string
  /**
   * Specify a new total budget, in dollars, to update the total budget for the campaign. When the campaign has spent this budget, it automatically ends.
   * @type {number}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  budget?: number
  /**
   * You can update the campaign’s update by sending one of the values in the Enumerated Values section below.
   * @type {string}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  objective?: PutCampaignsBulkParamCampaignsObjectiveEnum
  /**
   * If you updated the objective to "other", send a brief phrase describing the campaign’s objective.
   * @type {string}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  objectiveCustom?: string
  /**
   * You can update the memo line Instacart includes on invoices for this campaign by specifying a new value in this field.
   * @type {string}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  memo?: string
  /**
   * You can update the purchase order number Instacart includes on the invoices generated for this ad campaign, by specifying a new value in this field.
   * @type {string}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  purchaseOrder?: string
  /**
   * You can update how you want Instacart to bill you for the campaign. If you want Instacart to bill on a per purchase order, specify "purchase_order". If you want us to bill to your account, specify "account".
   * @type {string}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  paymentType?: PutCampaignsBulkParamCampaignsPaymentTypeEnum
  /**
   * The budgeting strategy for the campaign. One of "daily_budget", "max_impressions".
   * @type {string}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  budgetType?: PutCampaignsBulkParamCampaignsBudgetTypeEnum
  /**
   * The daily budget for a v2 campaign, expressed in dollars. If `optimized_bidding_enabled` set to `true`, the minimum budget required is $20.
   * @type {number}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  targetDailyBudget?: number
  /**
   * Override for default account-level bill payer. Agency, advertiser or retailer to be billed for the campaign.
   * @type {string}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  billPayer?: PutCampaignsBulkParamCampaignsBillPayerEnum
  /**
   * Enables automated bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  automatedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  automatedBiddingGoal?: PutCampaignsBulkParamCampaignsAutomatedBiddingGoalEnum
  /**
   *
   * @type {PutCampaignsBulkParamCampaignsAutomatedBiddingParameters}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  automatedBiddingParameters?: PutCampaignsBulkParamCampaignsAutomatedBiddingParameters
  /**
   * Enables optimized bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  optimizedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  optimizedBiddingGoal?: PutCampaignsBulkParamCampaignsOptimizedBiddingGoalEnum
  /**
   *
   * @type {PutCampaignsBulkParamCampaignsOptimizedBiddingParameters}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  optimizedBiddingParameters?: PutCampaignsBulkParamCampaignsOptimizedBiddingParameters
  /**
   * The campaign’s goal, only updateable when campaign is in draft mode
   * @type {string}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  goal?: string
  /**
   * The default CPC bids to set for all ad groups
   * @type {Array<PutCampaignsBulkParamCampaignsAdGroups>}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  adGroups?: Array<PutCampaignsBulkParamCampaignsAdGroups>
  /**
   *
   * @type {boolean}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  optimizedBiddingClearKeywordsAsync?: boolean
  /**
   * The campaign’s start date in the ‘YYYY-MM-DD’ format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  startsAt?: string
  /**
   * The campaign’s effective end date in the ‘YYYY-MM-DD’ format. Campaigns end at midnight, PST, on the specified date. Do not send and the campaign only stops when the campaign’s budget has been spent.
   * @type {string}
   * @memberof PutCampaignsBulkParamCampaigns
   */
  endsAt?: string
}

/**
 * @export
 */
export const PutCampaignsBulkParamCampaignsObjectiveEnum = {
  Sales: 'sales',
  MarketShare: 'market_share',
  Consideration: 'consideration',
  Awareness: 'awareness',
  Other: 'other',
} as const
export type PutCampaignsBulkParamCampaignsObjectiveEnum =
  typeof PutCampaignsBulkParamCampaignsObjectiveEnum[keyof typeof PutCampaignsBulkParamCampaignsObjectiveEnum]

/**
 * @export
 */
export const PutCampaignsBulkParamCampaignsPaymentTypeEnum = {
  Account: 'account',
  PurchaseOrder: 'purchase_order',
} as const
export type PutCampaignsBulkParamCampaignsPaymentTypeEnum =
  typeof PutCampaignsBulkParamCampaignsPaymentTypeEnum[keyof typeof PutCampaignsBulkParamCampaignsPaymentTypeEnum]

/**
 * @export
 */
export const PutCampaignsBulkParamCampaignsBudgetTypeEnum = {
  DailyBudget: 'daily_budget',
  MaxImpressions: 'max_impressions',
} as const
export type PutCampaignsBulkParamCampaignsBudgetTypeEnum =
  typeof PutCampaignsBulkParamCampaignsBudgetTypeEnum[keyof typeof PutCampaignsBulkParamCampaignsBudgetTypeEnum]

/**
 * @export
 */
export const PutCampaignsBulkParamCampaignsBillPayerEnum = {
  Agency: 'agency',
  Advertiser: 'advertiser',
  Retailer: 'retailer',
} as const
export type PutCampaignsBulkParamCampaignsBillPayerEnum =
  typeof PutCampaignsBulkParamCampaignsBillPayerEnum[keyof typeof PutCampaignsBulkParamCampaignsBillPayerEnum]

/**
 * @export
 */
export const PutCampaignsBulkParamCampaignsAutomatedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type PutCampaignsBulkParamCampaignsAutomatedBiddingGoalEnum =
  typeof PutCampaignsBulkParamCampaignsAutomatedBiddingGoalEnum[keyof typeof PutCampaignsBulkParamCampaignsAutomatedBiddingGoalEnum]

/**
 * @export
 */
export const PutCampaignsBulkParamCampaignsOptimizedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type PutCampaignsBulkParamCampaignsOptimizedBiddingGoalEnum =
  typeof PutCampaignsBulkParamCampaignsOptimizedBiddingGoalEnum[keyof typeof PutCampaignsBulkParamCampaignsOptimizedBiddingGoalEnum]

/**
 * Check if a given object implements the PutCampaignsBulkParamCampaigns interface.
 */
export function instanceOfPutCampaignsBulkParamCampaigns(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value

  return isInstance
}

export function PutCampaignsBulkParamCampaignsFromJSON(json: any): PutCampaignsBulkParamCampaigns {
  return PutCampaignsBulkParamCampaignsFromJSONTyped(json, false)
}

export function PutCampaignsBulkParamCampaignsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutCampaignsBulkParamCampaigns {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
    name: !exists(json, 'name') ? undefined : json['name'],
    budget: !exists(json, 'budget') ? undefined : json['budget'],
    objective: !exists(json, 'objective') ? undefined : json['objective'],
    objectiveCustom: !exists(json, 'objective_custom') ? undefined : json['objective_custom'],
    memo: !exists(json, 'memo') ? undefined : json['memo'],
    purchaseOrder: !exists(json, 'purchase_order') ? undefined : json['purchase_order'],
    paymentType: !exists(json, 'payment_type') ? undefined : json['payment_type'],
    budgetType: !exists(json, 'budget_type') ? undefined : json['budget_type'],
    targetDailyBudget: !exists(json, 'target_daily_budget')
      ? undefined
      : json['target_daily_budget'],
    billPayer: !exists(json, 'bill_payer') ? undefined : json['bill_payer'],
    automatedBiddingEnabled: !exists(json, 'automated_bidding_enabled')
      ? undefined
      : json['automated_bidding_enabled'],
    automatedBiddingGoal: !exists(json, 'automated_bidding_goal')
      ? undefined
      : json['automated_bidding_goal'],
    automatedBiddingParameters: !exists(json, 'automated_bidding_parameters')
      ? undefined
      : PutCampaignsBulkParamCampaignsAutomatedBiddingParametersFromJSON(
          json['automated_bidding_parameters']
        ),
    optimizedBiddingEnabled: !exists(json, 'optimized_bidding_enabled')
      ? undefined
      : json['optimized_bidding_enabled'],
    optimizedBiddingGoal: !exists(json, 'optimized_bidding_goal')
      ? undefined
      : json['optimized_bidding_goal'],
    optimizedBiddingParameters: !exists(json, 'optimized_bidding_parameters')
      ? undefined
      : PutCampaignsBulkParamCampaignsOptimizedBiddingParametersFromJSON(
          json['optimized_bidding_parameters']
        ),
    goal: !exists(json, 'goal') ? undefined : json['goal'],
    adGroups: !exists(json, 'ad_groups')
      ? undefined
      : (json['ad_groups'] as Array<any>).map(PutCampaignsBulkParamCampaignsAdGroupsFromJSON),
    optimizedBiddingClearKeywordsAsync: !exists(json, 'optimized_bidding_clear_keywords_async')
      ? undefined
      : json['optimized_bidding_clear_keywords_async'],
    startsAt: !exists(json, 'starts_at') ? undefined : json['starts_at'],
    endsAt: !exists(json, 'ends_at') ? undefined : json['ends_at'],
  }
}

export function PutCampaignsBulkParamCampaignsToJSON(
  value?: PutCampaignsBulkParamCampaigns | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    enabled: value.enabled,
    name: value.name,
    budget: value.budget,
    objective: value.objective,
    objective_custom: value.objectiveCustom,
    memo: value.memo,
    purchase_order: value.purchaseOrder,
    payment_type: value.paymentType,
    budget_type: value.budgetType,
    target_daily_budget: value.targetDailyBudget,
    bill_payer: value.billPayer,
    automated_bidding_enabled: value.automatedBiddingEnabled,
    automated_bidding_goal: value.automatedBiddingGoal,
    automated_bidding_parameters: PutCampaignsBulkParamCampaignsAutomatedBiddingParametersToJSON(
      value.automatedBiddingParameters
    ),
    optimized_bidding_enabled: value.optimizedBiddingEnabled,
    optimized_bidding_goal: value.optimizedBiddingGoal,
    optimized_bidding_parameters: PutCampaignsBulkParamCampaignsOptimizedBiddingParametersToJSON(
      value.optimizedBiddingParameters
    ),
    goal: value.goal,
    ad_groups:
      value.adGroups === undefined
        ? undefined
        : (value.adGroups as Array<any>).map(PutCampaignsBulkParamCampaignsAdGroupsToJSON),
    optimized_bidding_clear_keywords_async: value.optimizedBiddingClearKeywordsAsync,
    starts_at: value.startsAt,
    ends_at: value.endsAt,
  }
}
