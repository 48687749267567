// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AdGroupResponseData } from './AdGroupResponseData'
import {
  AdGroupResponseDataFromJSON,
  AdGroupResponseDataFromJSONTyped,
  AdGroupResponseDataToJSON,
} from './AdGroupResponseData'
import type { AdGroupResponseMeta } from './AdGroupResponseMeta'
import {
  AdGroupResponseMetaFromJSON,
  AdGroupResponseMetaFromJSONTyped,
  AdGroupResponseMetaToJSON,
} from './AdGroupResponseMeta'

/**
 *
 * @export
 * @interface AdGroupResponse
 */
export interface AdGroupResponse {
  /**
   *
   * @type {AdGroupResponseData}
   * @memberof AdGroupResponse
   */
  data: AdGroupResponseData
  /**
   *
   * @type {AdGroupResponseMeta}
   * @memberof AdGroupResponse
   */
  meta: AdGroupResponseMeta
}

/**
 * Check if a given object implements the AdGroupResponse interface.
 */
export function instanceOfAdGroupResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function AdGroupResponseFromJSON(json: any): AdGroupResponse {
  return AdGroupResponseFromJSONTyped(json, false)
}

export function AdGroupResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdGroupResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: AdGroupResponseDataFromJSON(json['data']),
    meta: AdGroupResponseMetaFromJSON(json['meta']),
  }
}

export function AdGroupResponseToJSON(value?: AdGroupResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: AdGroupResponseDataToJSON(value.data),
    meta: AdGroupResponseMetaToJSON(value.meta),
  }
}
