// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutCampaignResponseDataRelationshipsAdGroupBidsData } from './PutCampaignResponseDataRelationshipsAdGroupBidsData'
import {
  PutCampaignResponseDataRelationshipsAdGroupBidsDataFromJSON,
  PutCampaignResponseDataRelationshipsAdGroupBidsDataFromJSONTyped,
  PutCampaignResponseDataRelationshipsAdGroupBidsDataToJSON,
} from './PutCampaignResponseDataRelationshipsAdGroupBidsData'

/**
 *
 * @export
 * @interface PutCampaignResponseDataRelationshipsAdGroupBids
 */
export interface PutCampaignResponseDataRelationshipsAdGroupBids {
  /**
   *
   * @type {Array<PutCampaignResponseDataRelationshipsAdGroupBidsData>}
   * @memberof PutCampaignResponseDataRelationshipsAdGroupBids
   */
  data: Array<PutCampaignResponseDataRelationshipsAdGroupBidsData>
}

/**
 * Check if a given object implements the PutCampaignResponseDataRelationshipsAdGroupBids interface.
 */
export function instanceOfPutCampaignResponseDataRelationshipsAdGroupBids(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value

  return isInstance
}

export function PutCampaignResponseDataRelationshipsAdGroupBidsFromJSON(
  json: any
): PutCampaignResponseDataRelationshipsAdGroupBids {
  return PutCampaignResponseDataRelationshipsAdGroupBidsFromJSONTyped(json, false)
}

export function PutCampaignResponseDataRelationshipsAdGroupBidsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutCampaignResponseDataRelationshipsAdGroupBids {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json['data'] as Array<any>).map(
      PutCampaignResponseDataRelationshipsAdGroupBidsDataFromJSON
    ),
  }
}

export function PutCampaignResponseDataRelationshipsAdGroupBidsToJSON(
  value?: PutCampaignResponseDataRelationshipsAdGroupBids | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(PutCampaignResponseDataRelationshipsAdGroupBidsDataToJSON),
  }
}
