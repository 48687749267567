import { ADS_API_PATH } from 'common/constants'
import { CampaignsApi } from 'service/apis/CampaignsApi'
import Client from 'service/client'
import { getEnumValueFromString } from 'service/mappings'
import {
  GetCampaignsIdAnalyticsMetricEnum,
  GetCampaignsTypeEnum,
} from 'service/openapi/__codegen__/apis/CampaignsApi'
import {
  ApiCampaignsControllerCreateInputCampaignBudgetTypeEnum,
  ApiCampaignsControllerCreateInputCampaignPaymentTypeEnum,
  ApiCampaignsControllerCreateInputCampaignObjectiveEnum,
  ApiCampaignsControllerCreateInputCampaignCampaignTypeEnum,
  ApiCampaignsControllerCreateInputCampaignBillPayerEnum,
} from 'service/openapi/__codegen__/models/ApiCampaignsControllerCreateInputCampaign'
import {
  ApiCampaignsControllerUpdateInputCampaignPaymentTypeEnum,
  ApiCampaignsControllerUpdateInputCampaignObjectiveEnum,
  ApiCampaignsControllerUpdateInputCampaignBillPayerEnum,
  ApiCampaignsControllerUpdateInputCampaignBudgetTypeEnum,
} from 'service/openapi/__codegen__/models/ApiCampaignsControllerUpdateInputCampaign'
import { GetAnalyticsOptions } from 'service/shared'
import {
  CampaignClientInterface,
  GetCampaignsOptions,
  GetCampaignOptions,
  CreateCampaignOptions,
  UpdateCampaignOptions,
} from 'service/types'

export default class OpenAPICampaignClient extends Client implements CampaignClientInterface {
  constructor() {
    super()
    this.ADS_API_PATH = `${ADS_API_PATH}/campaigns`
  }

  getCampaigns = (options?: GetCampaignsOptions) => {
    return CampaignsApi.getCampaigns({
      type: options?.type || GetCampaignsTypeEnum.FeaturedProduct,
      page: (options?.page && String(options.page)) || undefined,
      enabled: options?.enabled,
      query: options?.query,
      sortBy: options?.sortBy,
      status: options?.status,
      dateRangeStartDate: options?.dateRange?.startDate,
      dateRangeEndDate: options?.dateRange?.endDate,
    })
  }

  getCampaign = (campaignId: string, options?: GetCampaignOptions) => {
    return CampaignsApi.getCampaignsId({
      id: campaignId,
      dateRangeStartDate: options?.dateRange?.startDate,
      dateRangeEndDate: options?.dateRange?.endDate,
    })
  }

  createCampaign = (options: CreateCampaignOptions) => {
    return CampaignsApi.postCampaigns({
      body: {
        draft: options.draft,
        campaign: {
          name: options.name,
          campaignType: ApiCampaignsControllerCreateInputCampaignCampaignTypeEnum.FeaturedProduct,
          budget: options.budget,
          startsAt: options.startsAt,
          endsAt: options.endsAt,
          objective:
            (options.objective &&
              getEnumValueFromString(
                ApiCampaignsControllerCreateInputCampaignObjectiveEnum,
                options.objective
              )) ||
            undefined,
          objectiveCustom: options.objectiveCustom || undefined,
          memo: options.memo,
          purchaseOrder: options.purchaseOrder,
          billPayer:
            (options.billPayer &&
              getEnumValueFromString(
                ApiCampaignsControllerCreateInputCampaignBillPayerEnum,
                options.billPayer
              )) ||
            undefined,
          paymentType:
            (options.paymentType &&
              getEnumValueFromString(
                ApiCampaignsControllerCreateInputCampaignPaymentTypeEnum,
                options.paymentType
              )) ||
            undefined,
          budgetType: getEnumValueFromString(
            ApiCampaignsControllerCreateInputCampaignBudgetTypeEnum,
            options.budgetType
          ),
          ...(Object.prototype.hasOwnProperty.call(options, 'targetDailyBudget') && {
            targetDailyBudget: options.targetDailyBudget,
          }),
        },
      },
    })
  }

  updateCampaign = async (campaignId: string, options: UpdateCampaignOptions) => {
    return CampaignsApi.putCampaignsId({
      id: campaignId,
      body: {
        campaign: {
          name: options.name,
          enabled: options.enabled,
          objective:
            (options.objective &&
              getEnumValueFromString(
                ApiCampaignsControllerUpdateInputCampaignObjectiveEnum,
                options.objective
              )) ||
            undefined,
          objectiveCustom: options.objectiveCustom || '',
          startsAt: options.startsAt,
          endsAt: options.endsAt,
          budget: options.budget,
          memo: options.memo,
          purchaseOrder: options.purchaseOrder,
          billPayer:
            (options.billPayer &&
              getEnumValueFromString(
                ApiCampaignsControllerUpdateInputCampaignBillPayerEnum,
                options.billPayer
              )) ||
            undefined,
          paymentType:
            (options.paymentType &&
              getEnumValueFromString(
                ApiCampaignsControllerUpdateInputCampaignPaymentTypeEnum,
                options.paymentType
              )) ||
            undefined,
          ...(Object.prototype.hasOwnProperty.call(options, 'budgetType') && {
            budgetType:
              options.budgetType &&
              getEnumValueFromString(
                ApiCampaignsControllerUpdateInputCampaignBudgetTypeEnum,
                options.budgetType
              ),
          }),
          ...(Object.prototype.hasOwnProperty.call(options, 'targetDailyBudget') && {
            targetDailyBudget: options.targetDailyBudget,
          }),
          ...(Object.prototype.hasOwnProperty.call(options, 'adGroups') && {
            adGroups: options.adGroups,
          }),
        },
      },
    })
  }

  activateCampaign = (campaignId: string) => {
    return CampaignsApi.postCampaignsIdActivate({
      id: campaignId,
    })
  }

  activateCampaignAsync = (campaignId: string) => {
    return CampaignsApi.postCampaignsIdActivateAsync({
      id: campaignId,
    })
  }

  duplicateCampaignAsync = (campaignId: string) => {
    return CampaignsApi.postCampaignsIdDuplicateAsync({
      id: campaignId,
    })
  }

  getCampaignAnalytics = (campaignId: string, options: GetAnalyticsOptions) => {
    return CampaignsApi.getCampaignsIdAnalytics({
      id: campaignId,
      dateRangeStartDate: options.dateRange.startDate,
      dateRangeEndDate: options.dateRange.endDate,
      metric: getEnumValueFromString(GetCampaignsIdAnalyticsMetricEnum, options.metric),
    })
  }
}
