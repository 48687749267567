// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAccountsResponseDataAttributesAnalytics } from './GetAccountsResponseDataAttributesAnalytics'
import {
  GetAccountsResponseDataAttributesAnalyticsFromJSON,
  GetAccountsResponseDataAttributesAnalyticsFromJSONTyped,
  GetAccountsResponseDataAttributesAnalyticsToJSON,
} from './GetAccountsResponseDataAttributesAnalytics'
import type { GetAccountsResponseDataAttributesCorporateAddress } from './GetAccountsResponseDataAttributesCorporateAddress'
import {
  GetAccountsResponseDataAttributesCorporateAddressFromJSON,
  GetAccountsResponseDataAttributesCorporateAddressFromJSONTyped,
  GetAccountsResponseDataAttributesCorporateAddressToJSON,
} from './GetAccountsResponseDataAttributesCorporateAddress'

/**
 *
 * @export
 * @interface GetAccountsResponseDataAttributes
 */
export interface GetAccountsResponseDataAttributes {
  /**
   * Account Name
   * @type {string}
   * @memberof GetAccountsResponseDataAttributes
   */
  name: string
  /**
   * Account sells alcohol products
   * @type {boolean}
   * @memberof GetAccountsResponseDataAttributes
   */
  alcohol: boolean
  /**
   *
   * @type {GetAccountsResponseDataAttributesCorporateAddress}
   * @memberof GetAccountsResponseDataAttributes
   */
  corporateAddress: GetAccountsResponseDataAttributesCorporateAddress
  /**
   * Ads taxonomy reporting entity id
   * @type {number}
   * @memberof GetAccountsResponseDataAttributes
   */
  adsTaxonomyReportingEntityId?: number
  /**
   * Ads taxonomy reporting entity name
   * @type {string}
   * @memberof GetAccountsResponseDataAttributes
   */
  adsTaxonomyReportingEntityName?: string
  /**
   * Ads taxonomy reporting entity level
   * @type {string}
   * @memberof GetAccountsResponseDataAttributes
   */
  adsTaxonomyReportingEntityLevel?: string
  /**
   * Currency for Account
   * @type {string}
   * @memberof GetAccountsResponseDataAttributes
   */
  countryCode?: string
  /**
   * Account Type
   * @type {string}
   * @memberof GetAccountsResponseDataAttributes
   */
  accountType?: GetAccountsResponseDataAttributesAccountTypeEnum
  /**
   * Serving Type: marketplace_only, syndication_network, marketplace_and_syndication(default)
   * @type {string}
   * @memberof GetAccountsResponseDataAttributes
   */
  servingType?: GetAccountsResponseDataAttributesServingTypeEnum
  /**
   * An array of serving groups to target users for ads serving.
   * @type {Array<string>}
   * @memberof GetAccountsResponseDataAttributes
   */
  servingGroups?: Array<GetAccountsResponseDataAttributesServingGroupsEnum>
  /**
   * The maximum daily budget configured for this account, in cents.
   * @type {number}
   * @memberof GetAccountsResponseDataAttributes
   */
  dailyBudgetCapCents?: number
  /**
   * The maximum lifetime budget configured for this account, in cents.
   * @type {number}
   * @memberof GetAccountsResponseDataAttributes
   */
  lifetimeBudgetCapCents?: number
  /**
   * Whether the account is non billable
   * @type {boolean}
   * @memberof GetAccountsResponseDataAttributes
   */
  nonBillable?: boolean
  /**
   * Whether internal users should be auto assigned Account Admin role on this account
   * @type {boolean}
   * @memberof GetAccountsResponseDataAttributes
   */
  autoAssignInternalUsersRoles?: boolean
  /**
   * The external marketplace that the account belongs to
   * @type {string}
   * @memberof GetAccountsResponseDataAttributes
   */
  externalMarketplace?: GetAccountsResponseDataAttributesExternalMarketplaceEnum
  /**
   * The coupon Instacart fee configured for this account, in cents.
   * @type {number}
   * @memberof GetAccountsResponseDataAttributes
   */
  couponInstacartFeeCents?: number
  /**
   *
   * @type {GetAccountsResponseDataAttributesAnalytics}
   * @memberof GetAccountsResponseDataAttributes
   */
  analytics: GetAccountsResponseDataAttributesAnalytics
  /**
   * ISO 4217 currency code for the Account
   * @type {string}
   * @memberof GetAccountsResponseDataAttributes
   */
  currency: string
  /**
   * ISO 3166 numeric country code for the Account
   * @type {number}
   * @memberof GetAccountsResponseDataAttributes
   */
  countryId: number
}

/**
 * @export
 */
export const GetAccountsResponseDataAttributesAccountTypeEnum = {
  Default: 'default',
  Retailer: 'retailer',
  RetailerRsd: 'retailer_rsd',
  AdvertiserRtd: 'advertiser_rtd',
} as const
export type GetAccountsResponseDataAttributesAccountTypeEnum =
  typeof GetAccountsResponseDataAttributesAccountTypeEnum[keyof typeof GetAccountsResponseDataAttributesAccountTypeEnum]

/**
 * @export
 */
export const GetAccountsResponseDataAttributesServingTypeEnum = {
  MarketplaceAndSyndication: 'marketplace_and_syndication',
  SyndicationNetwork: 'syndication_network',
  MarketplaceOnly: 'marketplace_only',
} as const
export type GetAccountsResponseDataAttributesServingTypeEnum =
  typeof GetAccountsResponseDataAttributesServingTypeEnum[keyof typeof GetAccountsResponseDataAttributesServingTypeEnum]

/**
 * @export
 */
export const GetAccountsResponseDataAttributesServingGroupsEnum = {
  Admin: ':admin',
  AdsAdmin: 'Ads Admin',
  None: 'none',
  ConnectAdmin: 'connect_admin',
} as const
export type GetAccountsResponseDataAttributesServingGroupsEnum =
  typeof GetAccountsResponseDataAttributesServingGroupsEnum[keyof typeof GetAccountsResponseDataAttributesServingGroupsEnum]

/**
 * @export
 */
export const GetAccountsResponseDataAttributesExternalMarketplaceEnum = {
  Provi: 'provi',
} as const
export type GetAccountsResponseDataAttributesExternalMarketplaceEnum =
  typeof GetAccountsResponseDataAttributesExternalMarketplaceEnum[keyof typeof GetAccountsResponseDataAttributesExternalMarketplaceEnum]

/**
 * Check if a given object implements the GetAccountsResponseDataAttributes interface.
 */
export function instanceOfGetAccountsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'alcohol' in value
  isInstance = isInstance && 'corporateAddress' in value
  isInstance = isInstance && 'analytics' in value
  isInstance = isInstance && 'currency' in value
  isInstance = isInstance && 'countryId' in value

  return isInstance
}

export function GetAccountsResponseDataAttributesFromJSON(
  json: any
): GetAccountsResponseDataAttributes {
  return GetAccountsResponseDataAttributesFromJSONTyped(json, false)
}

export function GetAccountsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAccountsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    alcohol: json['alcohol'],
    corporateAddress: GetAccountsResponseDataAttributesCorporateAddressFromJSON(
      json['corporate_address']
    ),
    adsTaxonomyReportingEntityId: !exists(json, 'ads_taxonomy_reporting_entity_id')
      ? undefined
      : json['ads_taxonomy_reporting_entity_id'],
    adsTaxonomyReportingEntityName: !exists(json, 'ads_taxonomy_reporting_entity_name')
      ? undefined
      : json['ads_taxonomy_reporting_entity_name'],
    adsTaxonomyReportingEntityLevel: !exists(json, 'ads_taxonomy_reporting_entity_level')
      ? undefined
      : json['ads_taxonomy_reporting_entity_level'],
    countryCode: !exists(json, 'country_code') ? undefined : json['country_code'],
    accountType: !exists(json, 'account_type') ? undefined : json['account_type'],
    servingType: !exists(json, 'serving_type') ? undefined : json['serving_type'],
    servingGroups: !exists(json, 'serving_groups') ? undefined : json['serving_groups'],
    dailyBudgetCapCents: !exists(json, 'daily_budget_cap_cents')
      ? undefined
      : json['daily_budget_cap_cents'],
    lifetimeBudgetCapCents: !exists(json, 'lifetime_budget_cap_cents')
      ? undefined
      : json['lifetime_budget_cap_cents'],
    nonBillable: !exists(json, 'non_billable') ? undefined : json['non_billable'],
    autoAssignInternalUsersRoles: !exists(json, 'auto_assign_internal_users_roles')
      ? undefined
      : json['auto_assign_internal_users_roles'],
    externalMarketplace: !exists(json, 'external_marketplace')
      ? undefined
      : json['external_marketplace'],
    couponInstacartFeeCents: !exists(json, 'coupon_instacart_fee_cents')
      ? undefined
      : json['coupon_instacart_fee_cents'],
    analytics: GetAccountsResponseDataAttributesAnalyticsFromJSON(json['analytics']),
    currency: json['currency'],
    countryId: json['country_id'],
  }
}

export function GetAccountsResponseDataAttributesToJSON(
  value?: GetAccountsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    alcohol: value.alcohol,
    corporate_address: GetAccountsResponseDataAttributesCorporateAddressToJSON(
      value.corporateAddress
    ),
    ads_taxonomy_reporting_entity_id: value.adsTaxonomyReportingEntityId,
    ads_taxonomy_reporting_entity_name: value.adsTaxonomyReportingEntityName,
    ads_taxonomy_reporting_entity_level: value.adsTaxonomyReportingEntityLevel,
    country_code: value.countryCode,
    account_type: value.accountType,
    serving_type: value.servingType,
    serving_groups: value.servingGroups,
    daily_budget_cap_cents: value.dailyBudgetCapCents,
    lifetime_budget_cap_cents: value.lifetimeBudgetCapCents,
    non_billable: value.nonBillable,
    auto_assign_internal_users_roles: value.autoAssignInternalUsersRoles,
    external_marketplace: value.externalMarketplace,
    coupon_instacart_fee_cents: value.couponInstacartFeeCents,
    analytics: GetAccountsResponseDataAttributesAnalyticsToJSON(value.analytics),
    currency: value.currency,
    country_id: value.countryId,
  }
}
