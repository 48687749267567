// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiAdGroupsControllerUpdateInputAdGroupUserTargeting
 */
export interface ApiAdGroupsControllerUpdateInputAdGroupUserTargeting {
  /**
   * Target users based on license type.
   * @type {Array<string>}
   * @memberof ApiAdGroupsControllerUpdateInputAdGroupUserTargeting
   */
  buyerLicense?: Array<ApiAdGroupsControllerUpdateInputAdGroupUserTargetingBuyerLicenseEnum>
  /**
   * Target users within specific US states
   * @type {Array<string>}
   * @memberof ApiAdGroupsControllerUpdateInputAdGroupUserTargeting
   */
  geography?: Array<ApiAdGroupsControllerUpdateInputAdGroupUserTargetingGeographyEnum>
  /**
   * When true, this ad group will target everyone and ignore the other user targeting criteria
   * @type {boolean}
   * @memberof ApiAdGroupsControllerUpdateInputAdGroupUserTargeting
   */
  targetingEveryone?: boolean
}

/**
 * @export
 */
export const ApiAdGroupsControllerUpdateInputAdGroupUserTargetingBuyerLicenseEnum = {
  OnPremises: 'alcohol_on_premises',
  OffPremises: 'alcohol_off_premises',
  OnAndOffPremises: 'alcohol_on_and_off_premises',
} as const
export type ApiAdGroupsControllerUpdateInputAdGroupUserTargetingBuyerLicenseEnum =
  typeof ApiAdGroupsControllerUpdateInputAdGroupUserTargetingBuyerLicenseEnum[keyof typeof ApiAdGroupsControllerUpdateInputAdGroupUserTargetingBuyerLicenseEnum]

/**
 * @export
 */
export const ApiAdGroupsControllerUpdateInputAdGroupUserTargetingGeographyEnum = {
  Aa: 'AA',
  Ae: 'AE',
  Ak: 'AK',
  Al: 'AL',
  Ap: 'AP',
  Ar: 'AR',
  As: 'AS',
  Az: 'AZ',
  Ca: 'CA',
  Co: 'CO',
  Ct: 'CT',
  Dc: 'DC',
  De: 'DE',
  Fl: 'FL',
  Ga: 'GA',
  Gu: 'GU',
  Hi: 'HI',
  Ia: 'IA',
  Id: 'ID',
  Il: 'IL',
  In: 'IN',
  Ks: 'KS',
  Ky: 'KY',
  La: 'LA',
  Ma: 'MA',
  Md: 'MD',
  Me: 'ME',
  Mi: 'MI',
  Mn: 'MN',
  Mo: 'MO',
  Mp: 'MP',
  Ms: 'MS',
  Mt: 'MT',
  Nc: 'NC',
  Nd: 'ND',
  Ne: 'NE',
  Nh: 'NH',
  Nj: 'NJ',
  Nm: 'NM',
  Nv: 'NV',
  Ny: 'NY',
  Oh: 'OH',
  Ok: 'OK',
  Or: 'OR',
  Pa: 'PA',
  Pr: 'PR',
  Ri: 'RI',
  Sc: 'SC',
  Sd: 'SD',
  Tn: 'TN',
  Tx: 'TX',
  Um: 'UM',
  Ut: 'UT',
  Va: 'VA',
  Vi: 'VI',
  Vt: 'VT',
  Wa: 'WA',
  Wi: 'WI',
  Wv: 'WV',
  Wy: 'WY',
} as const
export type ApiAdGroupsControllerUpdateInputAdGroupUserTargetingGeographyEnum =
  typeof ApiAdGroupsControllerUpdateInputAdGroupUserTargetingGeographyEnum[keyof typeof ApiAdGroupsControllerUpdateInputAdGroupUserTargetingGeographyEnum]

/**
 * Check if a given object implements the ApiAdGroupsControllerUpdateInputAdGroupUserTargeting interface.
 */
export function instanceOfApiAdGroupsControllerUpdateInputAdGroupUserTargeting(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ApiAdGroupsControllerUpdateInputAdGroupUserTargetingFromJSON(
  json: any
): ApiAdGroupsControllerUpdateInputAdGroupUserTargeting {
  return ApiAdGroupsControllerUpdateInputAdGroupUserTargetingFromJSONTyped(json, false)
}

export function ApiAdGroupsControllerUpdateInputAdGroupUserTargetingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdGroupsControllerUpdateInputAdGroupUserTargeting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    buyerLicense: !exists(json, 'buyer_license') ? undefined : json['buyer_license'],
    geography: !exists(json, 'geography') ? undefined : json['geography'],
    targetingEveryone: !exists(json, 'targeting_everyone') ? undefined : json['targeting_everyone'],
  }
}

export function ApiAdGroupsControllerUpdateInputAdGroupUserTargetingToJSON(
  value?: ApiAdGroupsControllerUpdateInputAdGroupUserTargeting | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    buyer_license: value.buyerLicense,
    geography: value.geography,
    targeting_everyone: value.targetingEveryone,
  }
}
