// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { AdGroupResponse } from '../models/AdGroupResponse'
import { AdGroupResponseFromJSON, AdGroupResponseToJSON } from '../models/AdGroupResponse'
import type { AdGroupSuggestedBidResponse } from '../models/AdGroupSuggestedBidResponse'
import {
  AdGroupSuggestedBidResponseFromJSON,
  AdGroupSuggestedBidResponseToJSON,
} from '../models/AdGroupSuggestedBidResponse'
import type { ApiAdGroupsControllerBulkCreateInput } from '../models/ApiAdGroupsControllerBulkCreateInput'
import {
  ApiAdGroupsControllerBulkCreateInputFromJSON,
  ApiAdGroupsControllerBulkCreateInputToJSON,
} from '../models/ApiAdGroupsControllerBulkCreateInput'
import type { ApiAdGroupsControllerBulkUpdateInput } from '../models/ApiAdGroupsControllerBulkUpdateInput'
import {
  ApiAdGroupsControllerBulkUpdateInputFromJSON,
  ApiAdGroupsControllerBulkUpdateInputToJSON,
} from '../models/ApiAdGroupsControllerBulkUpdateInput'
import type { ApiAdGroupsControllerCreateInput } from '../models/ApiAdGroupsControllerCreateInput'
import {
  ApiAdGroupsControllerCreateInputFromJSON,
  ApiAdGroupsControllerCreateInputToJSON,
} from '../models/ApiAdGroupsControllerCreateInput'
import type { ApiAdGroupsControllerDuplicateAsyncInput } from '../models/ApiAdGroupsControllerDuplicateAsyncInput'
import {
  ApiAdGroupsControllerDuplicateAsyncInputFromJSON,
  ApiAdGroupsControllerDuplicateAsyncInputToJSON,
} from '../models/ApiAdGroupsControllerDuplicateAsyncInput'
import type { ApiAdGroupsControllerUpdateInput } from '../models/ApiAdGroupsControllerUpdateInput'
import {
  ApiAdGroupsControllerUpdateInputFromJSON,
  ApiAdGroupsControllerUpdateInputToJSON,
} from '../models/ApiAdGroupsControllerUpdateInput'
import type { AsyncTaskResponse } from '../models/AsyncTaskResponse'
import { AsyncTaskResponseFromJSON, AsyncTaskResponseToJSON } from '../models/AsyncTaskResponse'
import type { BulkAdGroupResponse } from '../models/BulkAdGroupResponse'
import {
  BulkAdGroupResponseFromJSON,
  BulkAdGroupResponseToJSON,
} from '../models/BulkAdGroupResponse'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { GetAdGroupAnalyticsResponse } from '../models/GetAdGroupAnalyticsResponse'
import {
  GetAdGroupAnalyticsResponseFromJSON,
  GetAdGroupAnalyticsResponseToJSON,
} from '../models/GetAdGroupAnalyticsResponse'
import type { GetAdGroupsResponse } from '../models/GetAdGroupsResponse'
import {
  GetAdGroupsResponseFromJSON,
  GetAdGroupsResponseToJSON,
} from '../models/GetAdGroupsResponse'
import type { GetAdGroupsSumResponse } from '../models/GetAdGroupsSumResponse'
import {
  GetAdGroupsSumResponseFromJSON,
  GetAdGroupsSumResponseToJSON,
} from '../models/GetAdGroupsSumResponse'

export interface GetAdGroupsRequest {
  campaignId: string
  query?: string
  dateRangeStartDate?: string
  dateRangeEndDate?: string
  attributionModels?: Array<string>
  sortBy?: GetAdGroupsSortByEnum
  page?: string
  perPage?: number
  excludeBidRecs?: boolean
  excludeAnalytics?: boolean
  includeProductCount?: boolean
  includeSupplementaryStatuses?: boolean
  xApiKey?: string
  xSecretKey?: string
}

export interface GetAdGroupsIdRequest {
  id: string
  dateRangeStartDate?: string
  dateRangeEndDate?: string
  attributionModels?: Array<string>
  excludeBidRecs?: boolean
  excludeAnalytics?: boolean
  xApiKey?: string
  xSecretKey?: string
}

export interface GetAdGroupsIdAnalyticsRequest {
  dateRangeStartDate: string
  metric: GetAdGroupsIdAnalyticsMetricEnum
  id: string
  dateRangeEndDate?: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetAdGroupsIdSuggestedBidRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetAdGroupsSumRequest {
  campaignId: string
  query?: string
  dateRangeStartDate?: string
  dateRangeEndDate?: string
  attributionModels?: Array<string>
  xApiKey?: string
  xSecretKey?: string
}

export interface PostAdGroupsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAdGroupsControllerCreateInput
}

export interface PostAdGroupsBulkRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAdGroupsControllerBulkCreateInput
}

export interface PostAdGroupsIdDuplicateAsyncRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAdGroupsControllerDuplicateAsyncInput
}

export interface PutAdGroupsBulkRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAdGroupsControllerBulkUpdateInput
}

export interface PutAdGroupsIdRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAdGroupsControllerUpdateInput
}

/**
 *
 */
export class AdGroupsApi extends runtime.BaseAPI {
  /**
   * Use GET /ad_groups to retrieve a list of all ad groups under a specific campaign. You can optionally search for a specific ad group by name and this method returns only the ad groups with matching names. You can also send filter criteria as parameters and this method returns only the ad groups that match.   This method returns details for each ad group, including its attributes and analytics on how it’s performing. Notably, it returns the bid_recs object, which contains the suggested_bid value and a status of your bid_strength. These calculated values can help you to ensure your sponsored products are displayed in prominent positions. In addition, it returns some metadata about the list of campaigns it returns, including the number of pages of results which can be used to break the results you receive up.
   * Retrieve all ad groups
   */
  async getAdGroupsRaw(
    requestParameters: GetAdGroupsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetAdGroupsResponse>> {
    if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
      throw new runtime.RequiredError(
        'campaignId',
        'Required parameter requestParameters.campaignId was null or undefined when calling getAdGroups.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.campaignId !== undefined) {
      queryParameters['campaign_id'] = requestParameters.campaignId
    }

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query
    }

    if (requestParameters.dateRangeStartDate !== undefined) {
      queryParameters['date_range[start_date]'] = requestParameters.dateRangeStartDate
    }

    if (requestParameters.dateRangeEndDate !== undefined) {
      queryParameters['date_range[end_date]'] = requestParameters.dateRangeEndDate
    }

    if (requestParameters.attributionModels) {
      queryParameters['attribution_models'] = requestParameters.attributionModels.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.sortBy !== undefined) {
      queryParameters['sort_by'] = requestParameters.sortBy
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters['per_page'] = requestParameters.perPage
    }

    if (requestParameters.excludeBidRecs !== undefined) {
      queryParameters['exclude_bid_recs'] = requestParameters.excludeBidRecs
    }

    if (requestParameters.excludeAnalytics !== undefined) {
      queryParameters['exclude_analytics'] = requestParameters.excludeAnalytics
    }

    if (requestParameters.includeProductCount !== undefined) {
      queryParameters['include_product_count'] = requestParameters.includeProductCount
    }

    if (requestParameters.includeSupplementaryStatuses !== undefined) {
      queryParameters['include_supplementary_statuses'] =
        requestParameters.includeSupplementaryStatuses
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_groups`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetAdGroupsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use GET /ad_groups to retrieve a list of all ad groups under a specific campaign. You can optionally search for a specific ad group by name and this method returns only the ad groups with matching names. You can also send filter criteria as parameters and this method returns only the ad groups that match.   This method returns details for each ad group, including its attributes and analytics on how it’s performing. Notably, it returns the bid_recs object, which contains the suggested_bid value and a status of your bid_strength. These calculated values can help you to ensure your sponsored products are displayed in prominent positions. In addition, it returns some metadata about the list of campaigns it returns, including the number of pages of results which can be used to break the results you receive up.
   * Retrieve all ad groups
   */
  async getAdGroups(
    requestParameters: GetAdGroupsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetAdGroupsResponse> {
    const response = await this.getAdGroupsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method returns details about a specific ad group, including some analytics and optionally the recommended bid and bid strength of the ad group. You need to specify the id of the ad group — which was returned by POST /ad_groups when you first created the ad group — and optionally a date range to limit the analytics data returned.
   * Retrieve an ad group
   */
  async getAdGroupsIdRaw(
    requestParameters: GetAdGroupsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AdGroupResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getAdGroupsId.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.dateRangeStartDate !== undefined) {
      queryParameters['date_range[start_date]'] = requestParameters.dateRangeStartDate
    }

    if (requestParameters.dateRangeEndDate !== undefined) {
      queryParameters['date_range[end_date]'] = requestParameters.dateRangeEndDate
    }

    if (requestParameters.attributionModels) {
      queryParameters['attribution_models'] = requestParameters.attributionModels.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.excludeBidRecs !== undefined) {
      queryParameters['exclude_bid_recs'] = requestParameters.excludeBidRecs
    }

    if (requestParameters.excludeAnalytics !== undefined) {
      queryParameters['exclude_analytics'] = requestParameters.excludeAnalytics
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_groups/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AdGroupResponseFromJSON(jsonValue))
  }

  /**
   * This method returns details about a specific ad group, including some analytics and optionally the recommended bid and bid strength of the ad group. You need to specify the id of the ad group — which was returned by POST /ad_groups when you first created the ad group — and optionally a date range to limit the analytics data returned.
   * Retrieve an ad group
   */
  async getAdGroupsId(
    requestParameters: GetAdGroupsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AdGroupResponse> {
    const response = await this.getAdGroupsIdRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will return detailed analytics information on a given Ad Group. You must specify the metric you wish to use and the date range to filter the information with. The end date can be left empty but the start date must be defined.
   * Returns analytics for a given Ad Group
   */
  async getAdGroupsIdAnalyticsRaw(
    requestParameters: GetAdGroupsIdAnalyticsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetAdGroupAnalyticsResponse>> {
    if (
      requestParameters.dateRangeStartDate === null ||
      requestParameters.dateRangeStartDate === undefined
    ) {
      throw new runtime.RequiredError(
        'dateRangeStartDate',
        'Required parameter requestParameters.dateRangeStartDate was null or undefined when calling getAdGroupsIdAnalytics.'
      )
    }

    if (requestParameters.metric === null || requestParameters.metric === undefined) {
      throw new runtime.RequiredError(
        'metric',
        'Required parameter requestParameters.metric was null or undefined when calling getAdGroupsIdAnalytics.'
      )
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getAdGroupsIdAnalytics.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.dateRangeStartDate !== undefined) {
      queryParameters['date_range[start_date]'] = requestParameters.dateRangeStartDate
    }

    if (requestParameters.metric !== undefined) {
      queryParameters['metric'] = requestParameters.metric
    }

    if (requestParameters.dateRangeEndDate !== undefined) {
      queryParameters['date_range[end_date]'] = requestParameters.dateRangeEndDate
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_groups/{id}/analytics`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetAdGroupAnalyticsResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method will return detailed analytics information on a given Ad Group. You must specify the metric you wish to use and the date range to filter the information with. The end date can be left empty but the start date must be defined.
   * Returns analytics for a given Ad Group
   */
  async getAdGroupsIdAnalytics(
    requestParameters: GetAdGroupsIdAnalyticsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetAdGroupAnalyticsResponse> {
    const response = await this.getAdGroupsIdAnalyticsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Get suggested bid for an ad group
   */
  async getAdGroupsIdSuggestedBidRaw(
    requestParameters: GetAdGroupsIdSuggestedBidRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AdGroupSuggestedBidResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getAdGroupsIdSuggestedBid.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_groups/{id}/suggested_bid`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      AdGroupSuggestedBidResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Get suggested bid for an ad group
   */
  async getAdGroupsIdSuggestedBid(
    requestParameters: GetAdGroupsIdSuggestedBidRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AdGroupSuggestedBidResponse> {
    const response = await this.getAdGroupsIdSuggestedBidRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use GET /ad_groups/sum to retrieve a summation of all ad group analytics under a specific campaign. You can optionally search for a specific ad group by name and this method sums only the ad groups with matching names. You can also send filter criteria as parameters and this method sums only the ad groups that match.
   * Retrieve summation of all ad groups
   */
  async getAdGroupsSumRaw(
    requestParameters: GetAdGroupsSumRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetAdGroupsSumResponse>> {
    if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
      throw new runtime.RequiredError(
        'campaignId',
        'Required parameter requestParameters.campaignId was null or undefined when calling getAdGroupsSum.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.campaignId !== undefined) {
      queryParameters['campaign_id'] = requestParameters.campaignId
    }

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query
    }

    if (requestParameters.dateRangeStartDate !== undefined) {
      queryParameters['date_range[start_date]'] = requestParameters.dateRangeStartDate
    }

    if (requestParameters.dateRangeEndDate !== undefined) {
      queryParameters['date_range[end_date]'] = requestParameters.dateRangeEndDate
    }

    if (requestParameters.attributionModels) {
      queryParameters['attribution_models'] = requestParameters.attributionModels.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_groups/sum`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetAdGroupsSumResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use GET /ad_groups/sum to retrieve a summation of all ad group analytics under a specific campaign. You can optionally search for a specific ad group by name and this method sums only the ad groups with matching names. You can also send filter criteria as parameters and this method sums only the ad groups that match.
   * Retrieve summation of all ad groups
   */
  async getAdGroupsSum(
    requestParameters: GetAdGroupsSumRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetAdGroupsSumResponse> {
    const response = await this.getAdGroupsSumRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use POST /ad_groups to create a new ad group within an existing campaign. You need to pass the id of the campaign you want to create the ad group underneath, as well as a name for the ad group and the maximum CPC bid you want to pay for sponsored products in it.   When successfully called, POST /ad_groups returns the ad group’s id, status, and a variety of attributes and analytics.
   * Create a new ad group
   */
  async postAdGroupsRaw(
    requestParameters: PostAdGroupsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AdGroupResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_groups`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdGroupsControllerCreateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AdGroupResponseFromJSON(jsonValue))
  }

  /**
   * Use POST /ad_groups to create a new ad group within an existing campaign. You need to pass the id of the campaign you want to create the ad group underneath, as well as a name for the ad group and the maximum CPC bid you want to pay for sponsored products in it.   When successfully called, POST /ad_groups returns the ad group’s id, status, and a variety of attributes and analytics.
   * Create a new ad group
   */
  async postAdGroups(
    requestParameters: PostAdGroupsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AdGroupResponse> {
    const response = await this.postAdGroupsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use POST /ad_groups to create up to 25 new ad groups in bulk within existing campaigns. You may create new ad groups for multiple campaign IDs at once. When creating new ad groups you must specify the ad group name, maximum CPC bid and the corresponding campaign ID.   When successfully called, POST /ad_groups returns the new ad groups\' IDs and their attributes .
   * Bulk create ad groups
   */
  async postAdGroupsBulkRaw(
    requestParameters: PostAdGroupsBulkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BulkAdGroupResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_groups/bulk`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdGroupsControllerBulkCreateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkAdGroupResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use POST /ad_groups to create up to 25 new ad groups in bulk within existing campaigns. You may create new ad groups for multiple campaign IDs at once. When creating new ad groups you must specify the ad group name, maximum CPC bid and the corresponding campaign ID.   When successfully called, POST /ad_groups returns the new ad groups\' IDs and their attributes .
   * Bulk create ad groups
   */
  async postAdGroupsBulk(
    requestParameters: PostAdGroupsBulkRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BulkAdGroupResponse> {
    const response = await this.postAdGroupsBulkRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method will take an existing ad group, of any state, and duplicate it into a new draft ad group, with duplicated products and keywords. When successfully called, it returns the task URL you’ll need to hit to retrieve the status of your task, as well as your request’s ID and other metadata.
   * Duplicates an existing ad group
   */
  async postAdGroupsIdDuplicateAsyncRaw(
    requestParameters: PostAdGroupsIdDuplicateAsyncRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AsyncTaskResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling postAdGroupsIdDuplicateAsync.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_groups/{id}/duplicate_async`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdGroupsControllerDuplicateAsyncInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AsyncTaskResponseFromJSON(jsonValue))
  }

  /**
   * This method will take an existing ad group, of any state, and duplicate it into a new draft ad group, with duplicated products and keywords. When successfully called, it returns the task URL you’ll need to hit to retrieve the status of your task, as well as your request’s ID and other metadata.
   * Duplicates an existing ad group
   */
  async postAdGroupsIdDuplicateAsync(
    requestParameters: PostAdGroupsIdDuplicateAsyncRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AsyncTaskResponse> {
    const response = await this.postAdGroupsIdDuplicateAsyncRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Call this method to bulk update up to 25 ad groups. You can enable or disable ad groups and update their names and default bids. You must specify the ids of all the ad groups you want to update when calling this method.
   * Bulk update ad groups
   */
  async putAdGroupsBulkRaw(
    requestParameters: PutAdGroupsBulkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BulkAdGroupResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_groups/bulk`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdGroupsControllerBulkUpdateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkAdGroupResponseFromJSON(jsonValue)
    )
  }

  /**
   * Call this method to bulk update up to 25 ad groups. You can enable or disable ad groups and update their names and default bids. You must specify the ids of all the ad groups you want to update when calling this method.
   * Bulk update ad groups
   */
  async putAdGroupsBulk(
    requestParameters: PutAdGroupsBulkRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BulkAdGroupResponse> {
    const response = await this.putAdGroupsBulkRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Call this method to update a specific ad group. You can enable or disable the ad group, update its name and its CPC bid. You need to specify the id of the ad group you want to update — which was returned by POST /ad_groups when you first created the ad group — when calling this method.
   * Update an ad group
   */
  async putAdGroupsIdRaw(
    requestParameters: PutAdGroupsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AdGroupResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putAdGroupsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_groups/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdGroupsControllerUpdateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AdGroupResponseFromJSON(jsonValue))
  }

  /**
   * Call this method to update a specific ad group. You can enable or disable the ad group, update its name and its CPC bid. You need to specify the id of the ad group you want to update — which was returned by POST /ad_groups when you first created the ad group — when calling this method.
   * Update an ad group
   */
  async putAdGroupsId(
    requestParameters: PutAdGroupsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AdGroupResponse> {
    const response = await this.putAdGroupsIdRaw(requestParameters, initOverrides)
    return await response.value()
  }
}

/**
 * @export
 */
export const GetAdGroupsSortByEnum = {
  DefaultBidAsc: 'default_bid_asc',
  DefaultBidDesc: 'default_bid_desc',
  StatusAsc: 'status_asc',
  StatusDesc: 'status_desc',
  NameAsc: 'name_asc',
  NameDesc: 'name_desc',
  EnabledAsc: 'enabled_asc',
  EnabledDesc: 'enabled_desc',
  SpendAsc: 'spend_asc',
  SpendDesc: 'spend_desc',
  AttributedSalesAsc: 'attributed_sales_asc',
  AttributedSalesDesc: 'attributed_sales_desc',
  AttributedQuantitiesAsc: 'attributed_quantities_asc',
  AttributedQuantitiesDesc: 'attributed_quantities_desc',
  RoasAsc: 'roas_asc',
  RoasDesc: 'roas_desc',
  ImpressionsAsc: 'impressions_asc',
  ImpressionsDesc: 'impressions_desc',
  ClicksAsc: 'clicks_asc',
  ClicksDesc: 'clicks_desc',
  CtrAsc: 'ctr_asc',
  CtrDesc: 'ctr_desc',
  CpcAsc: 'cpc_asc',
  CpcDesc: 'cpc_desc',
  NtbAttributedSalesAsc: 'ntb_attributed_sales_asc',
  NtbAttributedSalesDesc: 'ntb_attributed_sales_desc',
  PercentNtbAttributedSalesAsc: 'percent_ntb_attributed_sales_asc',
  PercentNtbAttributedSalesDesc: 'percent_ntb_attributed_sales_desc',
  NtbSpendAsc: 'ntb_spend_asc',
  NtbSpendDesc: 'ntb_spend_desc',
  NtbRoasAsc: 'ntb_roas_asc',
  NtbRoasDesc: 'ntb_roas_desc',
} as const
export type GetAdGroupsSortByEnum = typeof GetAdGroupsSortByEnum[keyof typeof GetAdGroupsSortByEnum]
/**
 * @export
 */
export const GetAdGroupsIdAnalyticsMetricEnum = {
  Spend: 'spend',
  Clicks: 'clicks',
  Impressions: 'impressions',
  Ctr: 'ctr',
  Roas: 'roas',
  NtbRoas: 'ntb_roas',
  NtbSpend: 'ntb_spend',
  AttributedSales: 'attributed_sales',
  NtbAttributedSales: 'ntb_attributed_sales',
  AttributedQuantities: 'attributed_quantities',
} as const
export type GetAdGroupsIdAnalyticsMetricEnum =
  typeof GetAdGroupsIdAnalyticsMetricEnum[keyof typeof GetAdGroupsIdAnalyticsMetricEnum]
