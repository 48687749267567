// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiAdGroupsControllerCreateInputAdGroup } from './ApiAdGroupsControllerCreateInputAdGroup'
import {
  ApiAdGroupsControllerCreateInputAdGroupFromJSON,
  ApiAdGroupsControllerCreateInputAdGroupFromJSONTyped,
  ApiAdGroupsControllerCreateInputAdGroupToJSON,
} from './ApiAdGroupsControllerCreateInputAdGroup'

/**
 *
 * @export
 * @interface ApiAdGroupsControllerCreateInput
 */
export interface ApiAdGroupsControllerCreateInput {
  /**
   * Specify the ID of the campaign you want to create the ad group under. Ad groups must belong to a campaign.
   * @type {string}
   * @memberof ApiAdGroupsControllerCreateInput
   */
  campaignId: string
  /**
   *
   * @type {ApiAdGroupsControllerCreateInputAdGroup}
   * @memberof ApiAdGroupsControllerCreateInput
   */
  adGroup: ApiAdGroupsControllerCreateInputAdGroup
}

/**
 * Check if a given object implements the ApiAdGroupsControllerCreateInput interface.
 */
export function instanceOfApiAdGroupsControllerCreateInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'campaignId' in value
  isInstance = isInstance && 'adGroup' in value

  return isInstance
}

export function ApiAdGroupsControllerCreateInputFromJSON(
  json: any
): ApiAdGroupsControllerCreateInput {
  return ApiAdGroupsControllerCreateInputFromJSONTyped(json, false)
}

export function ApiAdGroupsControllerCreateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdGroupsControllerCreateInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    campaignId: json['campaign_id'],
    adGroup: ApiAdGroupsControllerCreateInputAdGroupFromJSON(json['ad_group']),
  }
}

export function ApiAdGroupsControllerCreateInputToJSON(
  value?: ApiAdGroupsControllerCreateInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    campaign_id: value.campaignId,
    ad_group: ApiAdGroupsControllerCreateInputAdGroupToJSON(value.adGroup),
  }
}
