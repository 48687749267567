// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetDisplayAdGroupsParamBrandPageBlockSnapshot,
  instanceOfGetDisplayAdGroupsParamBrandPageBlockSnapshot,
  GetDisplayAdGroupsParamBrandPageBlockSnapshotFromJSON,
  GetDisplayAdGroupsParamBrandPageBlockSnapshotFromJSONTyped,
  GetDisplayAdGroupsParamBrandPageBlockSnapshotToJSON,
} from './GetDisplayAdGroupsParamBrandPageBlockSnapshot'
import {
  GetDisplayAdGroupsParamBrandPageSnapshot,
  instanceOfGetDisplayAdGroupsParamBrandPageSnapshot,
  GetDisplayAdGroupsParamBrandPageSnapshotFromJSON,
  GetDisplayAdGroupsParamBrandPageSnapshotFromJSONTyped,
  GetDisplayAdGroupsParamBrandPageSnapshotToJSON,
} from './GetDisplayAdGroupsParamBrandPageSnapshot'
import {
  GetDisplayAdGroupsParamCreativeSnapshot,
  instanceOfGetDisplayAdGroupsParamCreativeSnapshot,
  GetDisplayAdGroupsParamCreativeSnapshotFromJSON,
  GetDisplayAdGroupsParamCreativeSnapshotFromJSONTyped,
  GetDisplayAdGroupsParamCreativeSnapshotToJSON,
} from './GetDisplayAdGroupsParamCreativeSnapshot'
import {
  GetDisplayAdGroupsParamDisplayAdGroupSnapshot,
  instanceOfGetDisplayAdGroupsParamDisplayAdGroupSnapshot,
  GetDisplayAdGroupsParamDisplayAdGroupSnapshotFromJSON,
  GetDisplayAdGroupsParamDisplayAdGroupSnapshotFromJSONTyped,
  GetDisplayAdGroupsParamDisplayAdGroupSnapshotToJSON,
} from './GetDisplayAdGroupsParamDisplayAdGroupSnapshot'
import {
  GetDisplayAdGroupsParamDisplayAssetSnapshot,
  instanceOfGetDisplayAdGroupsParamDisplayAssetSnapshot,
  GetDisplayAdGroupsParamDisplayAssetSnapshotFromJSON,
  GetDisplayAdGroupsParamDisplayAssetSnapshotFromJSONTyped,
  GetDisplayAdGroupsParamDisplayAssetSnapshotToJSON,
} from './GetDisplayAdGroupsParamDisplayAssetSnapshot'
import {
  GetDisplayAdGroupsParamDisplayProductAssetSnapshot,
  instanceOfGetDisplayAdGroupsParamDisplayProductAssetSnapshot,
  GetDisplayAdGroupsParamDisplayProductAssetSnapshotFromJSON,
  GetDisplayAdGroupsParamDisplayProductAssetSnapshotFromJSONTyped,
  GetDisplayAdGroupsParamDisplayProductAssetSnapshotToJSON,
} from './GetDisplayAdGroupsParamDisplayProductAssetSnapshot'

/**
 * @type GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshot
 *
 * @export
 */
export type GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshot =
  | ({ type: 'ASSET' } & GetDisplayAdGroupsParamDisplayAssetSnapshot)
  | ({ type: 'BRAND_PAGE' } & GetDisplayAdGroupsParamBrandPageSnapshot)
  | ({ type: 'BRAND_PAGE_BLOCK' } & GetDisplayAdGroupsParamBrandPageBlockSnapshot)
  | ({ type: 'CREATIVE' } & GetDisplayAdGroupsParamCreativeSnapshot)
  | ({ type: 'DISPLAY_AD_GROUP' } & GetDisplayAdGroupsParamDisplayAdGroupSnapshot)
  | ({ type: 'PRODUCT_ASSET' } & GetDisplayAdGroupsParamDisplayProductAssetSnapshot)

export function GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshotFromJSON(
  json: any
): GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshot {
  return GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshot {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...GetDisplayAdGroupsParamDisplayAssetSnapshotFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...GetDisplayAdGroupsParamBrandPageSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...GetDisplayAdGroupsParamBrandPageBlockSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...GetDisplayAdGroupsParamCreativeSnapshotFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...GetDisplayAdGroupsParamDisplayAdGroupSnapshotFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...GetDisplayAdGroupsParamDisplayProductAssetSnapshotFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${json['type']}'`
      )
  }
}

export function GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshotToJSON(
  value?: GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return GetDisplayAdGroupsParamDisplayAssetSnapshotToJSON(value)
    case 'BRAND_PAGE':
      return GetDisplayAdGroupsParamBrandPageSnapshotToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return GetDisplayAdGroupsParamBrandPageBlockSnapshotToJSON(value)
    case 'CREATIVE':
      return GetDisplayAdGroupsParamCreativeSnapshotToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return GetDisplayAdGroupsParamDisplayAdGroupSnapshotToJSON(value)
    case 'PRODUCT_ASSET':
      return GetDisplayAdGroupsParamDisplayProductAssetSnapshotToJSON(value)
    default:
      throw new Error(
        `No variant of GetDisplayAdGroupsParamDataAttributesEditorialStatesEntitySnapshot exists with 'type=${value['type']}'`
      )
  }
}
