// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetAdGroupsSumResponseData } from './GetAdGroupsSumResponseData'
import {
  GetAdGroupsSumResponseDataFromJSON,
  GetAdGroupsSumResponseDataFromJSONTyped,
  GetAdGroupsSumResponseDataToJSON,
} from './GetAdGroupsSumResponseData'
import type { GetAdGroupsSumResponseMeta } from './GetAdGroupsSumResponseMeta'
import {
  GetAdGroupsSumResponseMetaFromJSON,
  GetAdGroupsSumResponseMetaFromJSONTyped,
  GetAdGroupsSumResponseMetaToJSON,
} from './GetAdGroupsSumResponseMeta'

/**
 *
 * @export
 * @interface GetAdGroupsSumResponse
 */
export interface GetAdGroupsSumResponse {
  /**
   *
   * @type {GetAdGroupsSumResponseData}
   * @memberof GetAdGroupsSumResponse
   */
  data: GetAdGroupsSumResponseData
  /**
   *
   * @type {GetAdGroupsSumResponseMeta}
   * @memberof GetAdGroupsSumResponse
   */
  meta: GetAdGroupsSumResponseMeta
}

/**
 * Check if a given object implements the GetAdGroupsSumResponse interface.
 */
export function instanceOfGetAdGroupsSumResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function GetAdGroupsSumResponseFromJSON(json: any): GetAdGroupsSumResponse {
  return GetAdGroupsSumResponseFromJSONTyped(json, false)
}

export function GetAdGroupsSumResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdGroupsSumResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: GetAdGroupsSumResponseDataFromJSON(json['data']),
    meta: GetAdGroupsSumResponseMetaFromJSON(json['meta']),
  }
}

export function GetAdGroupsSumResponseToJSON(value?: GetAdGroupsSumResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: GetAdGroupsSumResponseDataToJSON(value.data),
    meta: GetAdGroupsSumResponseMetaToJSON(value.meta),
  }
}
