// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PutCampaignResponseDataAttributesAnalyticsAttributionDataLastTouch } from './PutCampaignResponseDataAttributesAnalyticsAttributionDataLastTouch'
import {
  PutCampaignResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON,
  PutCampaignResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  PutCampaignResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './PutCampaignResponseDataAttributesAnalyticsAttributionDataLastTouch'
import type { PutCampaignResponseDataAttributesAnalyticsAttributionDataLinear } from './PutCampaignResponseDataAttributesAnalyticsAttributionDataLinear'
import {
  PutCampaignResponseDataAttributesAnalyticsAttributionDataLinearFromJSON,
  PutCampaignResponseDataAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  PutCampaignResponseDataAttributesAnalyticsAttributionDataLinearToJSON,
} from './PutCampaignResponseDataAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface PutCampaignResponseDataAttributesAnalyticsAttributionData
 */
export interface PutCampaignResponseDataAttributesAnalyticsAttributionData {
  /**
   *
   * @type {PutCampaignResponseDataAttributesAnalyticsAttributionDataLinear}
   * @memberof PutCampaignResponseDataAttributesAnalyticsAttributionData
   */
  linear: PutCampaignResponseDataAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {PutCampaignResponseDataAttributesAnalyticsAttributionDataLastTouch}
   * @memberof PutCampaignResponseDataAttributesAnalyticsAttributionData
   */
  lastTouch?: PutCampaignResponseDataAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the PutCampaignResponseDataAttributesAnalyticsAttributionData interface.
 */
export function instanceOfPutCampaignResponseDataAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function PutCampaignResponseDataAttributesAnalyticsAttributionDataFromJSON(
  json: any
): PutCampaignResponseDataAttributesAnalyticsAttributionData {
  return PutCampaignResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(json, false)
}

export function PutCampaignResponseDataAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutCampaignResponseDataAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear: PutCampaignResponseDataAttributesAnalyticsAttributionDataLinearFromJSON(json['linear']),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : PutCampaignResponseDataAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function PutCampaignResponseDataAttributesAnalyticsAttributionDataToJSON(
  value?: PutCampaignResponseDataAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear: PutCampaignResponseDataAttributesAnalyticsAttributionDataLinearToJSON(value.linear),
    last_touch: PutCampaignResponseDataAttributesAnalyticsAttributionDataLastTouchToJSON(
      value.lastTouch
    ),
  }
}
