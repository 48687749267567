// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetCampaignResponseData } from './GetCampaignResponseData'
import {
  GetCampaignResponseDataFromJSON,
  GetCampaignResponseDataFromJSONTyped,
  GetCampaignResponseDataToJSON,
} from './GetCampaignResponseData'
import type { GetCampaignResponseMeta } from './GetCampaignResponseMeta'
import {
  GetCampaignResponseMetaFromJSON,
  GetCampaignResponseMetaFromJSONTyped,
  GetCampaignResponseMetaToJSON,
} from './GetCampaignResponseMeta'

/**
 *
 * @export
 * @interface GetCampaignResponse
 */
export interface GetCampaignResponse {
  /**
   *
   * @type {GetCampaignResponseData}
   * @memberof GetCampaignResponse
   */
  data: GetCampaignResponseData
  /**
   *
   * @type {GetCampaignResponseMeta}
   * @memberof GetCampaignResponse
   */
  meta: GetCampaignResponseMeta
}

/**
 * Check if a given object implements the GetCampaignResponse interface.
 */
export function instanceOfGetCampaignResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'data' in value
  isInstance = isInstance && 'meta' in value

  return isInstance
}

export function GetCampaignResponseFromJSON(json: any): GetCampaignResponse {
  return GetCampaignResponseFromJSONTyped(json, false)
}

export function GetCampaignResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: GetCampaignResponseDataFromJSON(json['data']),
    meta: GetCampaignResponseMetaFromJSON(json['meta']),
  }
}

export function GetCampaignResponseToJSON(value?: GetCampaignResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: GetCampaignResponseDataToJSON(value.data),
    meta: GetCampaignResponseMetaToJSON(value.meta),
  }
}
