// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLastTouch } from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLastTouch'
import {
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLastTouchFromJSON,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLastTouch'
import type { GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLinear } from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLinear'
import {
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLinearFromJSON,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLinearToJSON,
} from './GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionData
 */
export interface GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionData {
  /**
   *
   * @type {GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLinear}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionData
   */
  linear: GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLastTouch}
   * @memberof GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionData
   */
  lastTouch?: GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionData interface.
 */
export function instanceOfGetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataFromJSON(
  json: any
): GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionData {
  return GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataFromJSONTyped(
    json,
    false
  )
}

export function GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear:
      GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLinearFromJSON(
        json['linear']
      ),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataToJSON(
  value?: GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear:
      GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLinearToJSON(
        value.linear
      ),
    last_touch:
      GetCampaignsIdActionsParamDataDefaultBidActionsAttributesAdGroupAttributesAnalyticsAttributionDataLastTouchToJSON(
        value.lastTouch
      ),
  }
}
