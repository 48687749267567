// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetDisplayAdGroupsParamAssetFieldDecisions,
  instanceOfGetDisplayAdGroupsParamAssetFieldDecisions,
  GetDisplayAdGroupsParamAssetFieldDecisionsFromJSON,
  GetDisplayAdGroupsParamAssetFieldDecisionsFromJSONTyped,
  GetDisplayAdGroupsParamAssetFieldDecisionsToJSON,
} from './GetDisplayAdGroupsParamAssetFieldDecisions'
import {
  GetDisplayAdGroupsParamBrandPageBlockFieldDecisions,
  instanceOfGetDisplayAdGroupsParamBrandPageBlockFieldDecisions,
  GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsFromJSON,
  GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsFromJSONTyped,
  GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsToJSON,
} from './GetDisplayAdGroupsParamBrandPageBlockFieldDecisions'
import {
  GetDisplayAdGroupsParamBrandPageFieldDecisions,
  instanceOfGetDisplayAdGroupsParamBrandPageFieldDecisions,
  GetDisplayAdGroupsParamBrandPageFieldDecisionsFromJSON,
  GetDisplayAdGroupsParamBrandPageFieldDecisionsFromJSONTyped,
  GetDisplayAdGroupsParamBrandPageFieldDecisionsToJSON,
} from './GetDisplayAdGroupsParamBrandPageFieldDecisions'
import {
  GetDisplayAdGroupsParamCreativeFieldDecisions,
  instanceOfGetDisplayAdGroupsParamCreativeFieldDecisions,
  GetDisplayAdGroupsParamCreativeFieldDecisionsFromJSON,
  GetDisplayAdGroupsParamCreativeFieldDecisionsFromJSONTyped,
  GetDisplayAdGroupsParamCreativeFieldDecisionsToJSON,
} from './GetDisplayAdGroupsParamCreativeFieldDecisions'
import {
  GetDisplayAdGroupsParamDisplayAdGroupFieldDecisions,
  instanceOfGetDisplayAdGroupsParamDisplayAdGroupFieldDecisions,
  GetDisplayAdGroupsParamDisplayAdGroupFieldDecisionsFromJSON,
  GetDisplayAdGroupsParamDisplayAdGroupFieldDecisionsFromJSONTyped,
  GetDisplayAdGroupsParamDisplayAdGroupFieldDecisionsToJSON,
} from './GetDisplayAdGroupsParamDisplayAdGroupFieldDecisions'
import {
  GetDisplayAdGroupsParamProductAssetFieldDecisions,
  instanceOfGetDisplayAdGroupsParamProductAssetFieldDecisions,
  GetDisplayAdGroupsParamProductAssetFieldDecisionsFromJSON,
  GetDisplayAdGroupsParamProductAssetFieldDecisionsFromJSONTyped,
  GetDisplayAdGroupsParamProductAssetFieldDecisionsToJSON,
} from './GetDisplayAdGroupsParamProductAssetFieldDecisions'

/**
 * @type GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions
 *
 * @export
 */
export type GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions =
  | ({ type: 'ASSET' } & GetDisplayAdGroupsParamAssetFieldDecisions)
  | ({ type: 'BRAND_PAGE' } & GetDisplayAdGroupsParamBrandPageFieldDecisions)
  | ({ type: 'BRAND_PAGE_BLOCK' } & GetDisplayAdGroupsParamBrandPageBlockFieldDecisions)
  | ({ type: 'CREATIVE' } & GetDisplayAdGroupsParamCreativeFieldDecisions)
  | ({ type: 'DISPLAY_AD_GROUP' } & GetDisplayAdGroupsParamDisplayAdGroupFieldDecisions)
  | ({ type: 'PRODUCT_ASSET' } & GetDisplayAdGroupsParamProductAssetFieldDecisions)

export function GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON(
  json: any
): GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  return GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...GetDisplayAdGroupsParamAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...GetDisplayAdGroupsParamBrandPageFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...GetDisplayAdGroupsParamCreativeFieldDecisionsFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...GetDisplayAdGroupsParamDisplayAdGroupFieldDecisionsFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...GetDisplayAdGroupsParamProductAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${json['type']}'`
      )
  }
}

export function GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON(
  value?: GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return GetDisplayAdGroupsParamAssetFieldDecisionsToJSON(value)
    case 'BRAND_PAGE':
      return GetDisplayAdGroupsParamBrandPageFieldDecisionsToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return GetDisplayAdGroupsParamBrandPageBlockFieldDecisionsToJSON(value)
    case 'CREATIVE':
      return GetDisplayAdGroupsParamCreativeFieldDecisionsToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return GetDisplayAdGroupsParamDisplayAdGroupFieldDecisionsToJSON(value)
    case 'PRODUCT_ASSET':
      return GetDisplayAdGroupsParamProductAssetFieldDecisionsToJSON(value)
    default:
      throw new Error(
        `No variant of GetDisplayAdGroupsParamDataAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${value['type']}'`
      )
  }
}
