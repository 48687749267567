// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetCampaignsResponseDataAttributesAnalytics } from './GetCampaignsResponseDataAttributesAnalytics'
import {
  GetCampaignsResponseDataAttributesAnalyticsFromJSON,
  GetCampaignsResponseDataAttributesAnalyticsFromJSONTyped,
  GetCampaignsResponseDataAttributesAnalyticsToJSON,
} from './GetCampaignsResponseDataAttributesAnalytics'
import type { GetCampaignsResponseDataAttributesAutomatedBiddingParameters } from './GetCampaignsResponseDataAttributesAutomatedBiddingParameters'
import {
  GetCampaignsResponseDataAttributesAutomatedBiddingParametersFromJSON,
  GetCampaignsResponseDataAttributesAutomatedBiddingParametersFromJSONTyped,
  GetCampaignsResponseDataAttributesAutomatedBiddingParametersToJSON,
} from './GetCampaignsResponseDataAttributesAutomatedBiddingParameters'
import type { GetCampaignsResponseDataAttributesOptimizedBiddingParameters } from './GetCampaignsResponseDataAttributesOptimizedBiddingParameters'
import {
  GetCampaignsResponseDataAttributesOptimizedBiddingParametersFromJSON,
  GetCampaignsResponseDataAttributesOptimizedBiddingParametersFromJSONTyped,
  GetCampaignsResponseDataAttributesOptimizedBiddingParametersToJSON,
} from './GetCampaignsResponseDataAttributesOptimizedBiddingParameters'
import type { GetCampaignsResponseDataAttributesSupplementaryStatuses } from './GetCampaignsResponseDataAttributesSupplementaryStatuses'
import {
  GetCampaignsResponseDataAttributesSupplementaryStatusesFromJSON,
  GetCampaignsResponseDataAttributesSupplementaryStatusesFromJSONTyped,
  GetCampaignsResponseDataAttributesSupplementaryStatusesToJSON,
} from './GetCampaignsResponseDataAttributesSupplementaryStatuses'

/**
 *
 * @export
 * @interface GetCampaignsResponseDataAttributes
 */
export interface GetCampaignsResponseDataAttributes {
  /**
   * A descriptive name for the campaign.
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  name: string
  /**
   * Set to true to activate the campaign. You will bid according to the details of the ad groups contained within this campaign. Set to false and the campaign is not active. This field cannot be set to true for a campaign in draft state. Draft campaign can only be launched from Instacart Ads Manager.
   * @type {boolean}
   * @memberof GetCampaignsResponseDataAttributes
   */
  enabled: boolean
  /**
   * The campaign’s start date in iso8601 format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  scheduledStartAt: string
  /**
   * The campaign's effective end date in iso8601 format. Campaigns end at midnight, PST, on the specified date. The campaign only stops when the campaign’s budget has been spent.
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  scheduledEndAt: string
  /**
   * The campaign’s start date in the 'YYYY-MM-DD' format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  scheduledStartAtDate: string
  /**
   * The campaign's effective end date in the 'YYYY-MM-DD' format. Campaigns end at midnight, PST, on the specified date. The campaign only stops when the campaign’s budget has been spent.
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  scheduledEndAtDate: string
  /**
   * Send "featured_product" for this field. We currently only support one type of ad campaign.
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  campaignType: string
  /**
   * The total budget for the ads campaign, expressed in dollars. When the campaign has spent this budget, it automatically ends.
   * @type {number}
   * @memberof GetCampaignsResponseDataAttributes
   */
  budget: number
  /**
   * Campaign Status
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  campaignStatus: string
  /**
   * The budgeting strategy for the campaign. One of "daily_budget", "max_impressions".
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  budgetType: GetCampaignsResponseDataAttributesBudgetTypeEnum
  /**
   * The daily budget for a v2 campaign, expressed in dollars.
   * @type {number}
   * @memberof GetCampaignsResponseDataAttributes
   */
  targetDailyBudget?: number
  /**
   * The campaign’s goal.
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  goal?: string
  /**
   * The campaign’s goal format.
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  goalFormat?: string
  /**
   * Override for default account-level bill payer. Agency, advertiser or retailer to be billed for the campaign.
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  billPayer?: GetCampaignsResponseDataAttributesBillPayerEnum
  /**
   * Enables automated bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof GetCampaignsResponseDataAttributes
   */
  automatedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  automatedBiddingGoal?: GetCampaignsResponseDataAttributesAutomatedBiddingGoalEnum
  /**
   *
   * @type {GetCampaignsResponseDataAttributesAutomatedBiddingParameters}
   * @memberof GetCampaignsResponseDataAttributes
   */
  automatedBiddingParameters?: GetCampaignsResponseDataAttributesAutomatedBiddingParameters
  /**
   * Enables optimized bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof GetCampaignsResponseDataAttributes
   */
  optimizedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  optimizedBiddingGoal?: GetCampaignsResponseDataAttributesOptimizedBiddingGoalEnum
  /**
   *
   * @type {GetCampaignsResponseDataAttributesOptimizedBiddingParameters}
   * @memberof GetCampaignsResponseDataAttributes
   */
  optimizedBiddingParameters?: GetCampaignsResponseDataAttributesOptimizedBiddingParameters
  /**
   * Campaign risk status
   * @type {{ [key: string]: any; }}
   * @memberof GetCampaignsResponseDataAttributes
   */
  atRiskMeta: { [key: string]: any }
  /**
   * Puchase Order #
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  purchaseOrder: string
  /**
   * Objective of the campaign
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  objective: string
  /**
   * Custom objective of the campaign
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  objectiveCustom: string
  /**
   * Memo for the campaign
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  memo: string
  /**
   * How you pay for the campaign
   * @type {string}
   * @memberof GetCampaignsResponseDataAttributes
   */
  paymentType: string
  /**
   *
   * @type {GetCampaignsResponseDataAttributesSupplementaryStatuses}
   * @memberof GetCampaignsResponseDataAttributes
   */
  supplementaryStatuses?: GetCampaignsResponseDataAttributesSupplementaryStatuses
  /**
   *
   * @type {GetCampaignsResponseDataAttributesAnalytics}
   * @memberof GetCampaignsResponseDataAttributes
   */
  analytics: GetCampaignsResponseDataAttributesAnalytics
}

/**
 * @export
 */
export const GetCampaignsResponseDataAttributesBudgetTypeEnum = {
  DailyBudget: 'daily_budget',
  PacedSpending: 'paced_spending',
  MaxImpressions: 'max_impressions',
  Null: 'null',
} as const
export type GetCampaignsResponseDataAttributesBudgetTypeEnum =
  typeof GetCampaignsResponseDataAttributesBudgetTypeEnum[keyof typeof GetCampaignsResponseDataAttributesBudgetTypeEnum]

/**
 * @export
 */
export const GetCampaignsResponseDataAttributesBillPayerEnum = {
  Agency: 'agency',
  Advertiser: 'advertiser',
  Retailer: 'retailer',
} as const
export type GetCampaignsResponseDataAttributesBillPayerEnum =
  typeof GetCampaignsResponseDataAttributesBillPayerEnum[keyof typeof GetCampaignsResponseDataAttributesBillPayerEnum]

/**
 * @export
 */
export const GetCampaignsResponseDataAttributesAutomatedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type GetCampaignsResponseDataAttributesAutomatedBiddingGoalEnum =
  typeof GetCampaignsResponseDataAttributesAutomatedBiddingGoalEnum[keyof typeof GetCampaignsResponseDataAttributesAutomatedBiddingGoalEnum]

/**
 * @export
 */
export const GetCampaignsResponseDataAttributesOptimizedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type GetCampaignsResponseDataAttributesOptimizedBiddingGoalEnum =
  typeof GetCampaignsResponseDataAttributesOptimizedBiddingGoalEnum[keyof typeof GetCampaignsResponseDataAttributesOptimizedBiddingGoalEnum]

/**
 * Check if a given object implements the GetCampaignsResponseDataAttributes interface.
 */
export function instanceOfGetCampaignsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'scheduledStartAt' in value
  isInstance = isInstance && 'scheduledEndAt' in value
  isInstance = isInstance && 'scheduledStartAtDate' in value
  isInstance = isInstance && 'scheduledEndAtDate' in value
  isInstance = isInstance && 'campaignType' in value
  isInstance = isInstance && 'budget' in value
  isInstance = isInstance && 'campaignStatus' in value
  isInstance = isInstance && 'budgetType' in value
  isInstance = isInstance && 'atRiskMeta' in value
  isInstance = isInstance && 'purchaseOrder' in value
  isInstance = isInstance && 'objective' in value
  isInstance = isInstance && 'objectiveCustom' in value
  isInstance = isInstance && 'memo' in value
  isInstance = isInstance && 'paymentType' in value
  isInstance = isInstance && 'analytics' in value

  return isInstance
}

export function GetCampaignsResponseDataAttributesFromJSON(
  json: any
): GetCampaignsResponseDataAttributes {
  return GetCampaignsResponseDataAttributesFromJSONTyped(json, false)
}

export function GetCampaignsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    enabled: json['enabled'],
    scheduledStartAt: json['scheduled_start_at'],
    scheduledEndAt: json['scheduled_end_at'],
    scheduledStartAtDate: json['scheduled_start_at_date'],
    scheduledEndAtDate: json['scheduled_end_at_date'],
    campaignType: json['campaign_type'],
    budget: json['budget'],
    campaignStatus: json['campaign_status'],
    budgetType: json['budget_type'],
    targetDailyBudget: !exists(json, 'target_daily_budget')
      ? undefined
      : json['target_daily_budget'],
    goal: !exists(json, 'goal') ? undefined : json['goal'],
    goalFormat: !exists(json, 'goal_format') ? undefined : json['goal_format'],
    billPayer: !exists(json, 'bill_payer') ? undefined : json['bill_payer'],
    automatedBiddingEnabled: !exists(json, 'automated_bidding_enabled')
      ? undefined
      : json['automated_bidding_enabled'],
    automatedBiddingGoal: !exists(json, 'automated_bidding_goal')
      ? undefined
      : json['automated_bidding_goal'],
    automatedBiddingParameters: !exists(json, 'automated_bidding_parameters')
      ? undefined
      : GetCampaignsResponseDataAttributesAutomatedBiddingParametersFromJSON(
          json['automated_bidding_parameters']
        ),
    optimizedBiddingEnabled: !exists(json, 'optimized_bidding_enabled')
      ? undefined
      : json['optimized_bidding_enabled'],
    optimizedBiddingGoal: !exists(json, 'optimized_bidding_goal')
      ? undefined
      : json['optimized_bidding_goal'],
    optimizedBiddingParameters: !exists(json, 'optimized_bidding_parameters')
      ? undefined
      : GetCampaignsResponseDataAttributesOptimizedBiddingParametersFromJSON(
          json['optimized_bidding_parameters']
        ),
    atRiskMeta: json['at_risk_meta'],
    purchaseOrder: json['purchase_order'],
    objective: json['objective'],
    objectiveCustom: json['objective_custom'],
    memo: json['memo'],
    paymentType: json['payment_type'],
    supplementaryStatuses: !exists(json, 'supplementary_statuses')
      ? undefined
      : GetCampaignsResponseDataAttributesSupplementaryStatusesFromJSON(
          json['supplementary_statuses']
        ),
    analytics: GetCampaignsResponseDataAttributesAnalyticsFromJSON(json['analytics']),
  }
}

export function GetCampaignsResponseDataAttributesToJSON(
  value?: GetCampaignsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    enabled: value.enabled,
    scheduled_start_at: value.scheduledStartAt,
    scheduled_end_at: value.scheduledEndAt,
    scheduled_start_at_date: value.scheduledStartAtDate,
    scheduled_end_at_date: value.scheduledEndAtDate,
    campaign_type: value.campaignType,
    budget: value.budget,
    campaign_status: value.campaignStatus,
    budget_type: value.budgetType,
    target_daily_budget: value.targetDailyBudget,
    goal: value.goal,
    goal_format: value.goalFormat,
    bill_payer: value.billPayer,
    automated_bidding_enabled: value.automatedBiddingEnabled,
    automated_bidding_goal: value.automatedBiddingGoal,
    automated_bidding_parameters:
      GetCampaignsResponseDataAttributesAutomatedBiddingParametersToJSON(
        value.automatedBiddingParameters
      ),
    optimized_bidding_enabled: value.optimizedBiddingEnabled,
    optimized_bidding_goal: value.optimizedBiddingGoal,
    optimized_bidding_parameters:
      GetCampaignsResponseDataAttributesOptimizedBiddingParametersToJSON(
        value.optimizedBiddingParameters
      ),
    at_risk_meta: value.atRiskMeta,
    purchase_order: value.purchaseOrder,
    objective: value.objective,
    objective_custom: value.objectiveCustom,
    memo: value.memo,
    payment_type: value.paymentType,
    supplementary_statuses: GetCampaignsResponseDataAttributesSupplementaryStatusesToJSON(
      value.supplementaryStatuses
    ),
    analytics: GetCampaignsResponseDataAttributesAnalyticsToJSON(value.analytics),
  }
}
