// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValues } from './GetDisplayAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValues'
import {
  GetDisplayAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSON,
  GetDisplayAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSONTyped,
  GetDisplayAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValuesToJSON,
} from './GetDisplayAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValues'
import type { GetDisplayAdGroupsParamCreativeFieldDecisionsTagline } from './GetDisplayAdGroupsParamCreativeFieldDecisionsTagline'
import {
  GetDisplayAdGroupsParamCreativeFieldDecisionsTaglineFromJSON,
  GetDisplayAdGroupsParamCreativeFieldDecisionsTaglineFromJSONTyped,
  GetDisplayAdGroupsParamCreativeFieldDecisionsTaglineToJSON,
} from './GetDisplayAdGroupsParamCreativeFieldDecisionsTagline'

/**
 *
 * @export
 * @interface GetDisplayAdGroupsParamCreativeFieldDecisions
 */
export interface GetDisplayAdGroupsParamCreativeFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetDisplayAdGroupsParamCreativeFieldDecisions
   */
  type: string
  /**
   *
   * @type {{ [key: string]: GetDisplayAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValues; }}
   * @memberof GetDisplayAdGroupsParamCreativeFieldDecisions
   */
  mainItemGridProducts?: {
    [key: string]: GetDisplayAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValues
  }
  /**
   *
   * @type {GetDisplayAdGroupsParamCreativeFieldDecisionsTagline}
   * @memberof GetDisplayAdGroupsParamCreativeFieldDecisions
   */
  tagline?: GetDisplayAdGroupsParamCreativeFieldDecisionsTagline
}

/**
 * Check if a given object implements the GetDisplayAdGroupsParamCreativeFieldDecisions interface.
 */
export function instanceOfGetDisplayAdGroupsParamCreativeFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function GetDisplayAdGroupsParamCreativeFieldDecisionsFromJSON(
  json: any
): GetDisplayAdGroupsParamCreativeFieldDecisions {
  return GetDisplayAdGroupsParamCreativeFieldDecisionsFromJSONTyped(json, false)
}

export function GetDisplayAdGroupsParamCreativeFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupsParamCreativeFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    mainItemGridProducts: !exists(json, 'main_item_grid_products')
      ? undefined
      : mapValues(
          json['main_item_grid_products'],
          GetDisplayAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSON
        ),
    tagline: !exists(json, 'tagline')
      ? undefined
      : GetDisplayAdGroupsParamCreativeFieldDecisionsTaglineFromJSON(json['tagline']),
  }
}

export function GetDisplayAdGroupsParamCreativeFieldDecisionsToJSON(
  value?: GetDisplayAdGroupsParamCreativeFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    main_item_grid_products:
      value.mainItemGridProducts === undefined
        ? undefined
        : mapValues(
            value.mainItemGridProducts,
            GetDisplayAdGroupsParamCreativeFieldDecisionsMainItemGridProductsValuesToJSON
          ),
    tagline: GetDisplayAdGroupsParamCreativeFieldDecisionsTaglineToJSON(value.tagline),
  }
}
