// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostCampaignResponseDataAttributesAnalytics } from './PostCampaignResponseDataAttributesAnalytics'
import {
  PostCampaignResponseDataAttributesAnalyticsFromJSON,
  PostCampaignResponseDataAttributesAnalyticsFromJSONTyped,
  PostCampaignResponseDataAttributesAnalyticsToJSON,
} from './PostCampaignResponseDataAttributesAnalytics'
import type { PostCampaignResponseDataAttributesAutomatedBiddingParameters } from './PostCampaignResponseDataAttributesAutomatedBiddingParameters'
import {
  PostCampaignResponseDataAttributesAutomatedBiddingParametersFromJSON,
  PostCampaignResponseDataAttributesAutomatedBiddingParametersFromJSONTyped,
  PostCampaignResponseDataAttributesAutomatedBiddingParametersToJSON,
} from './PostCampaignResponseDataAttributesAutomatedBiddingParameters'
import type { PostCampaignResponseDataAttributesOptimizedBiddingParameters } from './PostCampaignResponseDataAttributesOptimizedBiddingParameters'
import {
  PostCampaignResponseDataAttributesOptimizedBiddingParametersFromJSON,
  PostCampaignResponseDataAttributesOptimizedBiddingParametersFromJSONTyped,
  PostCampaignResponseDataAttributesOptimizedBiddingParametersToJSON,
} from './PostCampaignResponseDataAttributesOptimizedBiddingParameters'

/**
 *
 * @export
 * @interface PostCampaignResponseDataAttributes
 */
export interface PostCampaignResponseDataAttributes {
  /**
   * Campaign Spend
   * @type {number}
   * @memberof PostCampaignResponseDataAttributes
   */
  spend: number
  /**
   * A descriptive name for the campaign.
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  name: string
  /**
   * Set to true to activate the campaign. You will bid according to the details of the ad groups contained within this campaign. Set to false and the campaign is not active. This field cannot be set to true for a campaign in draft state. Draft campaign can only be launched from Instacart Ads Manager.
   * @type {boolean}
   * @memberof PostCampaignResponseDataAttributes
   */
  enabled: boolean
  /**
   * The campaign’s start date in iso8601 format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  scheduledStartAt: string
  /**
   * The campaign's effective end date in iso8601 format. Campaigns end at midnight, PST, on the specified date. The campaign only stops when the campaign's budget has been spent.
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  scheduledEndAt: string
  /**
   * The campaign’s start date in the 'YYYY-MM-DD' format. Campaigns start midnight, PST, on the specified date.
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  scheduledStartAtDate: string
  /**
   * The campaign's effective end date in the 'YYYY-MM-DD' format. Campaigns end at midnight, PST, on the specified date. The campaign only stops when the campaign’s budget has been spent.
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  scheduledEndAtDate: string
  /**
   * Send "featured_product" for this field. We currently only support one type of ad campaign.
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  campaignType: string
  /**
   * The total budget for the ads campaign, expressed in dollars. When the campaign has spent this budget, it automatically ends.
   * @type {number}
   * @memberof PostCampaignResponseDataAttributes
   */
  budget: number
  /**
   * Campaign Status
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  campaignStatus: string
  /**
   * Campaign risk status
   * @type {{ [key: string]: any; }}
   * @memberof PostCampaignResponseDataAttributes
   */
  atRiskMeta: { [key: string]: any }
  /**
   * The budgeting strategy for the campaign. One of "daily_budget", "max_impressions".
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  budgetType: PostCampaignResponseDataAttributesBudgetTypeEnum
  /**
   * The daily budget for a v2 campaign, expressed in dollars.
   * @type {number}
   * @memberof PostCampaignResponseDataAttributes
   */
  targetDailyBudget?: number
  /**
   * Puchase Order #
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  purchaseOrder: string
  /**
   * Objective of the campaign
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  objective: string
  /**
   * Custom objective of the campaign
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  objectiveCustom: string
  /**
   * Memo for the campaign
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  memo: string
  /**
   * How you pay for the campaign
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  paymentType: string
  /**
   * Enables optimized bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof PostCampaignResponseDataAttributes
   */
  optimizedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  optimizedBiddingGoal?: PostCampaignResponseDataAttributesOptimizedBiddingGoalEnum
  /**
   *
   * @type {PostCampaignResponseDataAttributesOptimizedBiddingParameters}
   * @memberof PostCampaignResponseDataAttributes
   */
  optimizedBiddingParameters?: PostCampaignResponseDataAttributesOptimizedBiddingParameters
  /**
   * The campaign’s goal.
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  goal?: string
  /**
   * The campaign’s goal format.
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  goalFormat?: string
  /**
   *
   * @type {PostCampaignResponseDataAttributesAnalytics}
   * @memberof PostCampaignResponseDataAttributes
   */
  analytics: PostCampaignResponseDataAttributesAnalytics
  /**
   * Override for default account-level bill payer. Agency, advertiser or retailer to be billed for the campaign.
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  billPayer?: PostCampaignResponseDataAttributesBillPayerEnum
  /**
   * The campaign’s negative keywords
   * @type {Array<string>}
   * @memberof PostCampaignResponseDataAttributes
   */
  negativeKeywords?: Array<string>
  /**
   * Enables automated bidding for this campaign, can only be enabled for `budget_type` = `daily_budget` campaigns
   * @type {boolean}
   * @memberof PostCampaignResponseDataAttributes
   */
  automatedBiddingEnabled?: boolean
  /**
   * The goal for the bidding algorithm to aim for
   * @type {string}
   * @memberof PostCampaignResponseDataAttributes
   */
  automatedBiddingGoal?: PostCampaignResponseDataAttributesAutomatedBiddingGoalEnum
  /**
   *
   * @type {PostCampaignResponseDataAttributesAutomatedBiddingParameters}
   * @memberof PostCampaignResponseDataAttributes
   */
  automatedBiddingParameters?: PostCampaignResponseDataAttributesAutomatedBiddingParameters
}

/**
 * @export
 */
export const PostCampaignResponseDataAttributesBudgetTypeEnum = {
  DailyBudget: 'daily_budget',
  PacedSpending: 'paced_spending',
  MaxImpressions: 'max_impressions',
  Null: 'null',
} as const
export type PostCampaignResponseDataAttributesBudgetTypeEnum =
  typeof PostCampaignResponseDataAttributesBudgetTypeEnum[keyof typeof PostCampaignResponseDataAttributesBudgetTypeEnum]

/**
 * @export
 */
export const PostCampaignResponseDataAttributesOptimizedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type PostCampaignResponseDataAttributesOptimizedBiddingGoalEnum =
  typeof PostCampaignResponseDataAttributesOptimizedBiddingGoalEnum[keyof typeof PostCampaignResponseDataAttributesOptimizedBiddingGoalEnum]

/**
 * @export
 */
export const PostCampaignResponseDataAttributesBillPayerEnum = {
  Agency: 'agency',
  Advertiser: 'advertiser',
  Retailer: 'retailer',
} as const
export type PostCampaignResponseDataAttributesBillPayerEnum =
  typeof PostCampaignResponseDataAttributesBillPayerEnum[keyof typeof PostCampaignResponseDataAttributesBillPayerEnum]

/**
 * @export
 */
export const PostCampaignResponseDataAttributesAutomatedBiddingGoalEnum = {
  MaxSales: 'max_sales',
  AcquireNtb: 'acquire_ntb',
} as const
export type PostCampaignResponseDataAttributesAutomatedBiddingGoalEnum =
  typeof PostCampaignResponseDataAttributesAutomatedBiddingGoalEnum[keyof typeof PostCampaignResponseDataAttributesAutomatedBiddingGoalEnum]

/**
 * Check if a given object implements the PostCampaignResponseDataAttributes interface.
 */
export function instanceOfPostCampaignResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'spend' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'scheduledStartAt' in value
  isInstance = isInstance && 'scheduledEndAt' in value
  isInstance = isInstance && 'scheduledStartAtDate' in value
  isInstance = isInstance && 'scheduledEndAtDate' in value
  isInstance = isInstance && 'campaignType' in value
  isInstance = isInstance && 'budget' in value
  isInstance = isInstance && 'campaignStatus' in value
  isInstance = isInstance && 'atRiskMeta' in value
  isInstance = isInstance && 'budgetType' in value
  isInstance = isInstance && 'purchaseOrder' in value
  isInstance = isInstance && 'objective' in value
  isInstance = isInstance && 'objectiveCustom' in value
  isInstance = isInstance && 'memo' in value
  isInstance = isInstance && 'paymentType' in value
  isInstance = isInstance && 'analytics' in value

  return isInstance
}

export function PostCampaignResponseDataAttributesFromJSON(
  json: any
): PostCampaignResponseDataAttributes {
  return PostCampaignResponseDataAttributesFromJSONTyped(json, false)
}

export function PostCampaignResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostCampaignResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    spend: json['spend'],
    name: json['name'],
    enabled: json['enabled'],
    scheduledStartAt: json['scheduled_start_at'],
    scheduledEndAt: json['scheduled_end_at'],
    scheduledStartAtDate: json['scheduled_start_at_date'],
    scheduledEndAtDate: json['scheduled_end_at_date'],
    campaignType: json['campaign_type'],
    budget: json['budget'],
    campaignStatus: json['campaign_status'],
    atRiskMeta: json['at_risk_meta'],
    budgetType: json['budget_type'],
    targetDailyBudget: !exists(json, 'target_daily_budget')
      ? undefined
      : json['target_daily_budget'],
    purchaseOrder: json['purchase_order'],
    objective: json['objective'],
    objectiveCustom: json['objective_custom'],
    memo: json['memo'],
    paymentType: json['payment_type'],
    optimizedBiddingEnabled: !exists(json, 'optimized_bidding_enabled')
      ? undefined
      : json['optimized_bidding_enabled'],
    optimizedBiddingGoal: !exists(json, 'optimized_bidding_goal')
      ? undefined
      : json['optimized_bidding_goal'],
    optimizedBiddingParameters: !exists(json, 'optimized_bidding_parameters')
      ? undefined
      : PostCampaignResponseDataAttributesOptimizedBiddingParametersFromJSON(
          json['optimized_bidding_parameters']
        ),
    goal: !exists(json, 'goal') ? undefined : json['goal'],
    goalFormat: !exists(json, 'goal_format') ? undefined : json['goal_format'],
    analytics: PostCampaignResponseDataAttributesAnalyticsFromJSON(json['analytics']),
    billPayer: !exists(json, 'bill_payer') ? undefined : json['bill_payer'],
    negativeKeywords: !exists(json, 'negative_keywords') ? undefined : json['negative_keywords'],
    automatedBiddingEnabled: !exists(json, 'automated_bidding_enabled')
      ? undefined
      : json['automated_bidding_enabled'],
    automatedBiddingGoal: !exists(json, 'automated_bidding_goal')
      ? undefined
      : json['automated_bidding_goal'],
    automatedBiddingParameters: !exists(json, 'automated_bidding_parameters')
      ? undefined
      : PostCampaignResponseDataAttributesAutomatedBiddingParametersFromJSON(
          json['automated_bidding_parameters']
        ),
  }
}

export function PostCampaignResponseDataAttributesToJSON(
  value?: PostCampaignResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    spend: value.spend,
    name: value.name,
    enabled: value.enabled,
    scheduled_start_at: value.scheduledStartAt,
    scheduled_end_at: value.scheduledEndAt,
    scheduled_start_at_date: value.scheduledStartAtDate,
    scheduled_end_at_date: value.scheduledEndAtDate,
    campaign_type: value.campaignType,
    budget: value.budget,
    campaign_status: value.campaignStatus,
    at_risk_meta: value.atRiskMeta,
    budget_type: value.budgetType,
    target_daily_budget: value.targetDailyBudget,
    purchase_order: value.purchaseOrder,
    objective: value.objective,
    objective_custom: value.objectiveCustom,
    memo: value.memo,
    payment_type: value.paymentType,
    optimized_bidding_enabled: value.optimizedBiddingEnabled,
    optimized_bidding_goal: value.optimizedBiddingGoal,
    optimized_bidding_parameters:
      PostCampaignResponseDataAttributesOptimizedBiddingParametersToJSON(
        value.optimizedBiddingParameters
      ),
    goal: value.goal,
    goal_format: value.goalFormat,
    analytics: PostCampaignResponseDataAttributesAnalyticsToJSON(value.analytics),
    bill_payer: value.billPayer,
    negative_keywords: value.negativeKeywords,
    automated_bidding_enabled: value.automatedBiddingEnabled,
    automated_bidding_goal: value.automatedBiddingGoal,
    automated_bidding_parameters:
      PostCampaignResponseDataAttributesAutomatedBiddingParametersToJSON(
        value.automatedBiddingParameters
      ),
  }
}
