import { useFormikContext } from 'formik'
import BillingCard, { BillingCardProps } from './BillingCard'

type FormikWrappedBillingCardValues = {
  purchaseOrder: string
  memo: string
  paymentType: string
  billPayer: string
}

export type FormikWrappedBillingCardProps = Pick<
  BillingCardProps,
  'title' | 'paymentTypeOptions' | 'billPayerOptions' | 'expandOnMount'
>

export default function FormikWrappedBillingCard({
  title,
  expandOnMount = false,
  paymentTypeOptions,
  billPayerOptions,
}: FormikWrappedBillingCardProps) {
  const { setFieldValue, values } = useFormikContext<FormikWrappedBillingCardValues>()

  return (
    <BillingCard
      title={title}
      memo={values.memo}
      expandOnMount={expandOnMount}
      billPayer={values.billPayer}
      paymentType={values.paymentType}
      purchaseOrder={values.purchaseOrder}
      paymentTypeOptions={paymentTypeOptions}
      billPayerOptions={billPayerOptions}
      handleChange={value => setFieldValue(value.id, value.value)}
    />
  )
}
