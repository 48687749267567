// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiCampaignsControllerUpdateInputCampaign } from './ApiCampaignsControllerUpdateInputCampaign'
import {
  ApiCampaignsControllerUpdateInputCampaignFromJSON,
  ApiCampaignsControllerUpdateInputCampaignFromJSONTyped,
  ApiCampaignsControllerUpdateInputCampaignToJSON,
} from './ApiCampaignsControllerUpdateInputCampaign'

/**
 *
 * @export
 * @interface ApiCampaignsControllerUpdateInput
 */
export interface ApiCampaignsControllerUpdateInput {
  /**
   *
   * @type {ApiCampaignsControllerUpdateInputCampaign}
   * @memberof ApiCampaignsControllerUpdateInput
   */
  campaign: ApiCampaignsControllerUpdateInputCampaign
  /**
   * Controls whether or not PUT /campaigns returns the analytics object, which contains the detailed analytics information for the campaign.
   * @type {boolean}
   * @memberof ApiCampaignsControllerUpdateInput
   */
  excludeAnalytics?: boolean
}

/**
 * Check if a given object implements the ApiCampaignsControllerUpdateInput interface.
 */
export function instanceOfApiCampaignsControllerUpdateInput(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'campaign' in value

  return isInstance
}

export function ApiCampaignsControllerUpdateInputFromJSON(
  json: any
): ApiCampaignsControllerUpdateInput {
  return ApiCampaignsControllerUpdateInputFromJSONTyped(json, false)
}

export function ApiCampaignsControllerUpdateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiCampaignsControllerUpdateInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    campaign: ApiCampaignsControllerUpdateInputCampaignFromJSON(json['campaign']),
    excludeAnalytics: !exists(json, 'exclude_analytics') ? undefined : json['exclude_analytics'],
  }
}

export function ApiCampaignsControllerUpdateInputToJSON(
  value?: ApiCampaignsControllerUpdateInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    campaign: ApiCampaignsControllerUpdateInputCampaignToJSON(value.campaign),
    exclude_analytics: value.excludeAnalytics,
  }
}
