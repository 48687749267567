// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PutAdGroupsBulkParamAdGroups
 */
export interface PutAdGroupsBulkParamAdGroups {
  /**
   * Ad Group ID
   * @type {string}
   * @memberof PutAdGroupsBulkParamAdGroups
   */
  id: string
  /**
   * Specify a value to update the name of the ad group.
   * @type {string}
   * @memberof PutAdGroupsBulkParamAdGroups
   */
  name?: string
  /**
   * Set to true to activate the ad group. Set to false to deactivate the ad group. Do not specify this field to make no update.
   * @type {boolean}
   * @memberof PutAdGroupsBulkParamAdGroups
   */
  enabled?: boolean
  /**
   * The maximum amount, in dollars, you want to pay per click for advertised products in this ad group.
   * @type {number}
   * @memberof PutAdGroupsBulkParamAdGroups
   */
  defaultBid?: number
}

/**
 * Check if a given object implements the PutAdGroupsBulkParamAdGroups interface.
 */
export function instanceOfPutAdGroupsBulkParamAdGroups(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value

  return isInstance
}

export function PutAdGroupsBulkParamAdGroupsFromJSON(json: any): PutAdGroupsBulkParamAdGroups {
  return PutAdGroupsBulkParamAdGroupsFromJSONTyped(json, false)
}

export function PutAdGroupsBulkParamAdGroupsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutAdGroupsBulkParamAdGroups {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
    defaultBid: !exists(json, 'default_bid') ? undefined : json['default_bid'],
  }
}

export function PutAdGroupsBulkParamAdGroupsToJSON(
  value?: PutAdGroupsBulkParamAdGroups | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    enabled: value.enabled,
    default_bid: value.defaultBid,
  }
}
