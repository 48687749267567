// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributes } from './ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributes'
import {
  ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributesFromJSON,
  ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributesFromJSONTyped,
  ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributesToJSON,
} from './ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributes'

/**
 *
 * @export
 * @interface ApiAdGroupsControllerDuplicateAsyncInput
 */
export interface ApiAdGroupsControllerDuplicateAsyncInput {
  /**
   * The page requesting the ad group to be copied
   * @type {string}
   * @memberof ApiAdGroupsControllerDuplicateAsyncInput
   */
  pageName?: ApiAdGroupsControllerDuplicateAsyncInputPageNameEnum
  /**
   *
   * @type {ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributes}
   * @memberof ApiAdGroupsControllerDuplicateAsyncInput
   */
  overrideAttributes?: ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributes
}

/**
 * @export
 */
export const ApiAdGroupsControllerDuplicateAsyncInputPageNameEnum = {
  AdGroupDetail: 'ad_group_detail',
  CampaignDetail: 'campaign_detail',
} as const
export type ApiAdGroupsControllerDuplicateAsyncInputPageNameEnum =
  typeof ApiAdGroupsControllerDuplicateAsyncInputPageNameEnum[keyof typeof ApiAdGroupsControllerDuplicateAsyncInputPageNameEnum]

/**
 * Check if a given object implements the ApiAdGroupsControllerDuplicateAsyncInput interface.
 */
export function instanceOfApiAdGroupsControllerDuplicateAsyncInput(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiAdGroupsControllerDuplicateAsyncInputFromJSON(
  json: any
): ApiAdGroupsControllerDuplicateAsyncInput {
  return ApiAdGroupsControllerDuplicateAsyncInputFromJSONTyped(json, false)
}

export function ApiAdGroupsControllerDuplicateAsyncInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdGroupsControllerDuplicateAsyncInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    pageName: !exists(json, 'page_name') ? undefined : json['page_name'],
    overrideAttributes: !exists(json, 'override_attributes')
      ? undefined
      : ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributesFromJSON(
          json['override_attributes']
        ),
  }
}

export function ApiAdGroupsControllerDuplicateAsyncInputToJSON(
  value?: ApiAdGroupsControllerDuplicateAsyncInput | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    page_name: value.pageName,
    override_attributes: ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributesToJSON(
      value.overrideAttributes
    ),
  }
}
