import { AccountFeatures } from 'common/accountFeatures'
import { getCreativeSchema } from 'common/common-definitions/creative'
import {
  FLIPPER_ENABLE_SEPARATE_ALT_TEXT_FIELDS,
  FLIPPER_SHOPPABLE_GRID_ORDER,
  FLIPPER_SHOPPABLE_SINGLE_UPC,
} from 'common/flippers'
import useFlipper from 'common/useFlipper'
import {
  mixed,
  array,
  number,
  object,
  SchemaType,
  string,
  useSyncValidator,
  boolean,
} from 'common/validation'
import { useIsAdvancedTargetingEnabledForAccount } from 'components/organisms/AdvancedTargetingCardCollapse/components/hooks'
import { displayTargetingFormSchema } from 'components/organisms/TargetingRule/FormHelpers'
import { TargetingFormData } from 'components/organisms/TargetingRule/types'
import { AuthCtx, isProviAccount, useAuthContext } from 'context'
import useCapabilities from 'hooks/useCapabilities'
import { useDisplayMinBid } from 'hooks/useDisplayMinBid'
import { AdminDisplayAdGroupResponseDataAttributesCreativeTypeEnum as CreativeTypeEnum } from 'service/openapi/__codegen__/models/AdminDisplayAdGroupResponseDataAttributesCreative'
import { AdminDisplayAdGroupResponseDataAttributesCreativeAssets } from 'service/openapi/__codegen__/models/AdminDisplayAdGroupResponseDataAttributesCreativeAssets'
import {
  AdminDisplayAdGroupResponseDataAttributesTargetingStrategy,
  AdminDisplayAdGroupResponseDataAttributesTargetingStrategyKeywordMatchTypeEnum as KeywordMatchType,
} from 'service/openapi/__codegen__/models/AdminDisplayAdGroupResponseDataAttributesTargetingStrategy'
import { DisplayAdGroupResponseDataTypeEnum } from 'service/openapi/__codegen__/models/DisplayAdGroupResponseData'
import { GetAdminBrandPagesIdParamBlockHeroBannerAssets } from 'service/openapi/__codegen__/models/GetAdminBrandPagesIdParamBlockHeroBannerAssets'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsTypeEnum,
  PutDisplayCampaignsIdParamDataAttributesAdGroups,
} from 'service/openapi/__codegen__/models/PutDisplayCampaignsIdParamDataAttributesAdGroups'
import { MAX_ADDITIONAL_PRODUCTS_DISPLAY } from '../common/components/BrandPage/brandPage.constants'
import { brandPageAssetsSchema } from '../common/components/BrandPage/brandPage.types'
import {
  BRAND_PAGE_FIELD_NAME,
  MAX_PRODUCTS_FOR_BANNER,
  MIN_PRODUCTS_FOR_BANNER,
  MIN_BRAND_PAGE_TITLE_LENGTH,
  MAX_PRODUCTS_FOR_PROMOTED_AISLE,
  MIN_KEYWORDS_FOR_BANNER,
  MAX_KEYWORDS_FOR_BANNER,
  MIN_CHARACTERS_FOR_ALT_TEXT,
  MAX_BRAND_PAGE_TITLE_LENGTH,
  TargetingType,
  TRACKED_PRODUCT_IDS_FIELD_NAME,
  TRACKED_PRODUCT_GROUP_IDS_FIELD_NAME,
  BRAND_PAGE_ID_FIELD_NAME,
  MIN_PRODUCTS_FOR_SHOPPABLE,
  URL_CLICK_URL_FIELD_NAME,
  PRODUCT_GROUP_FIELD_NAME,
  MAX_PRODUCT_GROUPS_FOR_SHOPPABLE,
  MIN_PRODUCT_GROUPS_FOR_SHOPPABLE,
  RECIPE_ID_FIELD_NAME,
  COLLECTIONS_FIELD_NAME,
  COLLECTION_TITLE_CHAR_MIN,
  COLLECTION_TITLE_CHAR_MAX,
  COLLECTION_PRODUCT_MIN,
  COLLECTION_PRODUCT_MAX,
  COLLECTIONS_MIN,
  COLLECTIONS_MAX,
  SPONSORED_COLLECTION_MAX,
  SPONSORED_COLLECTION_MIN,
  NON_SPONSORED_COLLECTION_MAX,
  NON_SPONSORED_COLLECTION_MIN,
  COLLECTION_SEARCH_TERM_CHAR_MIN,
  COLLECTION_SEARCH_TERM_CHAR_MAX,
} from './constants'

export const CollectionType = {
  productCollection: 'product_collection',
  searchTermCollection: 'search_term_collection',
}
export type CollectionTypeEnum = typeof CollectionType[keyof typeof CollectionType]

export const creativeAssetsSchema = object({
  id: string().default(''),
  urls: object({
    previewUrl: string().when(['isUploaded', 'isCropping'], {
      is: (isUploaded: boolean, isCropping: boolean) => !isUploaded && !!isCropping,
      then: string().test('notUploaded', function () {
        return this.createError({
          message: 'notUploaded',
        })
      }),
      otherwise: string().required().default(''),
    }),
    uploadedUri: string().when(['isUploaded', 'isCropping'], {
      is: (isUploaded: boolean, isCropping: boolean) => !isUploaded && !!isCropping,
      then: string().test('notUploaded', function () {
        return this.createError({
          message: 'notUploaded',
        })
      }),
      otherwise: string().required().default(''),
    }),
    isUploaded: boolean().default(false),
    isCropping: boolean().default(false),
  }),
})

export const creativeAssetsWithAltSchema = creativeAssetsSchema.concat(
  object({
    alt: string().min(MIN_CHARACTERS_FOR_ALT_TEXT).required().default(''),
  })
)

export const creativeVideoAssetsSchema = object({
  id: string().default(''),
  urls: object({
    previewUrl: string().required().default(''),
    uploadedUri: string().required().default(''),
    videoSubtitlePreviewUrl: string().default(''),
    videoSubtitleUploadedUri: string().default(''),
    videoSubtitleUploadedFileName: string().default(''),
  }),
})

const brandPageTitleSchema = string()
  .min(MIN_BRAND_PAGE_TITLE_LENGTH)
  .max(MAX_BRAND_PAGE_TITLE_LENGTH)
  .required()
  .default('')

const brandPageFormSchema = object({
  id: string().default(''),
  title: brandPageTitleSchema,
  highlightedProductIds: array()
    .of(string().required())
    .min(MIN_PRODUCTS_FOR_BANNER)
    .max(MAX_PRODUCTS_FOR_BANNER)
    .default([])
    .required(),
  additionalProductIds: array().of(string()).max(MAX_ADDITIONAL_PRODUCTS_DISPLAY).default([]),
  blockTypeToIdMap: object().default({}),
  heroBanner: object({
    alt: string().default(''),
    mobile: brandPageAssetsSchema,
    desktop: brandPageAssetsSchema,
  }),
})

export const brandPageFormSchemaPromotedAisle = object({
  id: string().default(''),
  title: brandPageTitleSchema,
  additionalProductIds: array().of(string()).max(MAX_ADDITIONAL_PRODUCTS_DISPLAY).default([]),
  blockTypeToIdMap: object().default({}),
  heroBanner: object({
    alt: string().default(''),
    mobile: brandPageAssetsSchema,
    desktop: brandPageAssetsSchema,
  }),
})

export const displayAdGroupPromotedAisleCreativeSchema = object({
  brandLogo: creativeAssetsWithAltSchema,
  heroImage: creativeAssetsWithAltSchema,
})

export const displayAdGroupPromotedAisleV2CreativeSchema = object({
  heroImage: creativeAssetsWithAltSchema,
})

export const displayAdGroupImageBannerV2CreativeSchema = object({
  alt: string().min(MIN_CHARACTERS_FOR_ALT_TEXT).required().default(''),
  topImageMobile: creativeAssetsWithAltSchema,
  topImageWeb: creativeAssetsWithAltSchema,
  brandLogo: creativeAssetsWithAltSchema,
})

export const displayAdGroupUvcBannerCreativeSchema = object({
  uvcImageMobile: creativeAssetsSchema,
  uvcImageWeb: creativeAssetsSchema,
})

export const displayAdGroupVideoCreativeSchema = object({
  id: string().default(''),
  brandLogo: creativeAssetsWithAltSchema,
  video: creativeVideoAssetsSchema,
  thumbnail: creativeAssetsWithAltSchema,
})

const productIdsSchema = array()
  .of(string().required())
  .min(COLLECTION_PRODUCT_MIN)
  .max(COLLECTION_PRODUCT_MAX)
  .required()
  .default([])

const collectionsSchema = object({
  title: string().when('deleted', {
    is: (deleted: boolean) => deleted === true,
    then: string().nullable().default(''),
    otherwise: string()
      .min(COLLECTION_TITLE_CHAR_MIN)
      .max(COLLECTION_TITLE_CHAR_MAX)
      .required()
      .default(''),
  }),
  productIds: array().when('deleted', {
    is: (deleted: boolean) => deleted === true,
    then: array().default([]),
    otherwise: array().when('collectionType', {
      is: (collectionType: CollectionTypeEnum) =>
        collectionType === CollectionType.searchTermCollection,
      then: array().default([]),
      otherwise: productIdsSchema,
    }),
  }),
  sponsored: boolean().required().default(false),
  deleted: boolean().default(false),
  collectionType: mixed<CollectionTypeEnum>().required().oneOf(Object.values(CollectionType)),
  keyword: string().when('collectionType', {
    is: (collectionType: CollectionTypeEnum) =>
      collectionType === CollectionType.searchTermCollection,
    then: string()
      .min(COLLECTION_SEARCH_TERM_CHAR_MIN)
      .max(COLLECTION_SEARCH_TERM_CHAR_MAX)
      .required()
      .default(''),
    otherwise: string().nullable().default(''),
  }),
})

// eslint-disable-next-line no-restricted-syntax
export enum PlacementType {
  HOME_INTERLEAVED = 'home_interleaved.v1',
  HOME_INTERLEAVED_V2 = 'home_interleaved.v2',
  SEARCH_TOP = 'search_top.v1',
  SEARCH_INTERLEAVED_V2 = 'search_interleaved.v2',
  STOREFRONT_INTERLEAVED = 'storefront_interleaved.v1',
  PROVI_SEARCH_TOP_V1 = 'provi_search_top.v1',
  PROVI_SEARCH_INTERLEAVED_V1 = 'provi_search_interleaved.v1',
  SEARCH_INTERLEAVED_V4 = 'search_interleaved.v4',
  STOREFRONT_INTERLEAVED_V3 = 'storefront_interleaved.v3',
  PROVI_SEARCH_INTERLEAVED_V2 = 'provi_search_interleaved.v2',
  UNIVERSAL_VALUE_CAROUSEL_V1 = 'universal_value_carousel.v1',
}

export const getPlacementForCreative = (authContext: AuthCtx, creativeType: string) => {
  switch (creativeType) {
    case CreativeTypeEnum.SearchBannerV1:
      if (isProviAccount(authContext)) {
        return PlacementType.PROVI_SEARCH_TOP_V1
      }
      return PlacementType.SEARCH_TOP
    case CreativeTypeEnum.ImageBannerV3:
      return PlacementType.PROVI_SEARCH_TOP_V1
    case CreativeTypeEnum.PromotedAisleV1:
      return PlacementType.SEARCH_INTERLEAVED_V2
    case CreativeTypeEnum.PromotedAisleV3:
    case CreativeTypeEnum.PromotedAisleV4:
      return PlacementType.PROVI_SEARCH_INTERLEAVED_V1
    case CreativeTypeEnum.ShoppableVideoV1:
    case CreativeTypeEnum.SponsoredRecipeVideoV1:
      return PlacementType.SEARCH_INTERLEAVED_V4
    case CreativeTypeEnum.ShoppableVideoV2:
    case CreativeTypeEnum.ShoppableVideoV3:
      return PlacementType.PROVI_SEARCH_INTERLEAVED_V2
    case CreativeTypeEnum.UvcBannerV1:
      return PlacementType.UNIVERSAL_VALUE_CAROUSEL_V1
    default:
      return PlacementType.SEARCH_TOP
  }
}

export const isTrackedProductsRequired = (authContext: AuthCtx, creativeType: string) => {
  const creativesRequiringTrackedProducts: string[] = [CreativeTypeEnum.ImageBannerV2]

  if (creativesRequiringTrackedProducts.includes(creativeType)) {
    return true
  }

  if (creativeType === CreativeTypeEnum.SearchBannerV1 && !isProviAccount(authContext)) {
    return true
  }

  return false
}

export const isTrackedProductGroupsRequired = (authContext: AuthCtx, creativeType: string) => {
  const creativesRequiringTrackedProductGroups: string[] = [CreativeTypeEnum.ImageBannerV3]

  if (creativesRequiringTrackedProductGroups.includes(creativeType)) {
    return true
  }

  if (creativeType === CreativeTypeEnum.SearchBannerV1 && isProviAccount(authContext)) {
    return true
  }

  return false
}

export const isCreativeUsingProductGroups = (authContext: AuthCtx, creativeType: string) => {
  const creativeSchema = getCreativeSchema(creativeType)
  return (
    creativeSchema.actions.hasProductGroupGrid() ||
    isTrackedProductGroupsRequired(authContext, creativeType)
  )
}

// Advanced Targeting refers to geo targeting and buyer license targeting.
// These features are only available to Provi accounts.
// The are stored in the targeting rule. This function checks if the only targeting
// in the targeting rule is advanced targeting features.
const isOnlyAdvancedTargeting = (
  targetingStrategy: AdminDisplayAdGroupResponseDataAttributesTargetingStrategy | undefined
): boolean => {
  const noKeywords = !targetingStrategy?.keywords?.length

  const targetingRule = targetingStrategy?.targetingRule?.conjunctions?.[0]
  const userTargeting = targetingStrategy?.userTargeting
  // If the targeting rule has 2 elements, and both buy license and geo are populated
  // then the user has only selected advanced targeting.
  const onlyAdvancedTargetingInTargetingRule =
    targetingRule?.elements?.length === 2 &&
    userTargeting?.buyerLicense?.length &&
    userTargeting?.geography?.length

  if (noKeywords && onlyAdvancedTargetingInTargetingRule) {
    return true
  }
  return false
}

export const getTargetingType = (
  targetingStrategy: AdminDisplayAdGroupResponseDataAttributesTargetingStrategy | undefined,
  targetingShortcut: string | undefined,
  proviAccount?: boolean
): TargetingType | void => {
  if (
    targetingShortcut &&
    targetingShortcut !== 'TARGETING_SHORTCUT_DRAFT_ADGROUP' &&
    targetingShortcut !== 'TARGETING_SHORTCUT_NON_AUDIENCE_TARGETING' &&
    targetingShortcut !== 'TARGETING_SHORTCUT_CUSTOM' &&
    targetingShortcut !== 'TARGETING_SHORTCUT_UNKNOWN'
  ) {
    return TargetingType.TARGETING_SHORTCUT
  }

  if (targetingStrategy?.keywords?.length) {
    return TargetingType.KEYWORD_TARGETING
  }

  if (proviAccount && isOnlyAdvancedTargeting(targetingStrategy)) {
    // Intentionally returning void. When a provi account has only advanced targeting,
    // they have not yet selected a targeting type.
    return
  }

  if (
    targetingStrategy?.targetingRule &&
    Object.keys(targetingStrategy?.targetingRule).length > 0
  ) {
    return TargetingType.USER_TARGETING
  }
}

export const displayAdGroupSearchBannerCreativeSchema = object({
  alt: string().min(MIN_CHARACTERS_FOR_ALT_TEXT).required().default(''),
  topImageMobile: creativeAssetsSchema,
  topImageWeb: creativeAssetsSchema,
})

export const displayAdGroupSearchBannerSeparateAltTextFieldsCreativeSchema = object({
  topImageMobile: creativeAssetsWithAltSchema,
  topImageWeb: creativeAssetsWithAltSchema,
})

export const commonDisplayAdGroupSchema = {
  name: string().required().default(''),
  [TRACKED_PRODUCT_IDS_FIELD_NAME]: array().of(string()).default([]),
  [TRACKED_PRODUCT_GROUP_IDS_FIELD_NAME]: array().of(string()).default([]),
  [BRAND_PAGE_ID_FIELD_NAME]: string().default(''),
  [URL_CLICK_URL_FIELD_NAME]: string().default(''),
  [RECIPE_ID_FIELD_NAME]: string().when('creativeType', creativeType => {
    const schema = getCreativeSchema(creativeType)

    if (schema.actions.hasRecipeClick()) {
      return string().required().default('')
    }

    return string()
  }),
  [COLLECTIONS_FIELD_NAME]: array().when('creativeType', creativeType => {
    const schema = getCreativeSchema(creativeType)

    if (schema.actions.hasCollectionGrid()) {
      return array()
        .of(collectionsSchema)
        .required()
        .default([])
        .test('validateCollection', (collections, context) => {
          let sponsoredCount = 0
          let nonSponsoredCount = 0
          let deletedCount = 0
          let isNonSponsoredFound = false
          for (const collection of collections) {
            if (collection.deleted) {
              deletedCount += 1
            } else if (collection.sponsored) {
              sponsoredCount += 1
              if (isNonSponsoredFound) {
                return context.createError({
                  message: 'order.sponsored', // this uses "pages.displayProduct.displayAdGroup.validation.order.sponsored.collections" in en-US.json
                })
              }
            } else {
              nonSponsoredCount += 1
              isNonSponsoredFound = true
            }
          }
          const collectionsCount = collections.length - deletedCount

          if (collectionsCount > COLLECTIONS_MAX) {
            return context.createError({
              message: 'max', // this uses "pages.displayProduct.displayAdGroup.validation.max.collections" in en-US.json
              params: { max: COLLECTIONS_MAX },
            })
          }

          if (collectionsCount < COLLECTIONS_MIN) {
            return context.createError({
              message: 'min', // this uses "pages.displayProduct.displayAdGroup.validation.min.collections" in en-US.json
              params: { min: COLLECTIONS_MIN },
            })
          }

          if (sponsoredCount > SPONSORED_COLLECTION_MAX) {
            return context.createError({
              message: 'max.sponsored', // this uses "pages.displayProduct.displayAdGroup.validation.max.sponsored.collections" in en-US.json
              params: { max: SPONSORED_COLLECTION_MAX },
            })
          }

          if (collectionsCount > 0 && sponsoredCount < SPONSORED_COLLECTION_MIN) {
            return context.createError({
              message: 'min.sponsored', // this uses "pages.displayProduct.displayAdGroup.validation.min.sponsored.collections" in en-US.json
              params: { min: SPONSORED_COLLECTION_MIN },
            })
          }

          // Validate maximum count for non-sponsored collections - Technically, the code never reach to this condition
          if (nonSponsoredCount > NON_SPONSORED_COLLECTION_MAX) {
            return context.createError({
              message: 'max.nonSponsored', // this uses "pages.displayProduct.displayAdGroup.validation.max.nonSponsored.collections" in en-US.json
              params: { max: NON_SPONSORED_COLLECTION_MAX },
            })
          }

          // Validate minimum count for non-sponsored collections
          if (collectionsCount > 0 && nonSponsoredCount < NON_SPONSORED_COLLECTION_MIN) {
            return context.createError({
              message: 'min.nonSponsored', // this uses "pages.displayProduct.displayAdGroup.validation.min.nonSponsored.collections" in en-US.json
              params: { min: NON_SPONSORED_COLLECTION_MIN },
            })
          }

          return true
        })
    }

    return array()
  }),
}

export const useCommonTargetingSchema = (isAdvancedTargetingEnabled: boolean) => {
  const minimumAdvancedTargetingElements = isAdvancedTargetingEnabled ? 1 : 0
  const isBroadMatchEnabled = useCapabilities({
    featureCapabilities: AccountFeatures.BROAD_MATCH_KEYWORD_TARGETING,
  })
  return {
    targetingStrategy: object({
      targetingShortcut: string().when('targetingType', {
        is: TargetingType.TARGETING_SHORTCUT,
        then: string().required(),
      }),
      targetingType: mixed<TargetingType>()
        .required()
        .oneOf(Object.values(TargetingType))
        .default(undefined),
      keywords: array().when('targetingType', {
        is: TargetingType.KEYWORD_TARGETING,
        then: array()
          .of(string().required())
          .min(MIN_KEYWORDS_FOR_BANNER)
          .max(MAX_KEYWORDS_FOR_BANNER)
          .default([])
          .required(),
      }),
      keywordMatchType: isBroadMatchEnabled
        ? string().default(KeywordMatchType.Broad)
        : string().default(KeywordMatchType.Exact),
      userTargeting: object({
        geography: array().default([]).min(minimumAdvancedTargetingElements),
        buyerLicense: array().default([]).min(minimumAdvancedTargetingElements),
      }),
    }),
  }
}

type CommonDisplayCreativeSchema = {
  authContext: AuthCtx
  singleUpc?: boolean
  gridOrderFlipper?: boolean
  isSeparateAltTextFieldsFlipperEnabled?: boolean
}
export const commonDisplayCreativeSchema = ({
  authContext,
  singleUpc,
  gridOrderFlipper,
  isSeparateAltTextFieldsFlipperEnabled,
}: CommonDisplayCreativeSchema) => {
  return {
    creativeType: string().required().default(CreativeTypeEnum.SearchBannerV1),
    placementType: string()
      .required()
      .when('creativeType', (creativeType: string) => {
        return string().default(getPlacementForCreative(authContext, creativeType))
      })
      .default(PlacementType.SEARCH_TOP), // Needed to make this type safe
    tagline: string().when('creativeType', creativeType => {
      const schema = getCreativeSchema(creativeType)

      if (schema.properties.hasTagline()) {
        const taglineMaxLength = schema.properties.getTaglineProperties().maxLength
        return string().max(taglineMaxLength).required().default('')
      }

      return string()
    }),
    productIds: array().when('creativeType', creativeType => {
      const schema = getCreativeSchema(creativeType)

      if (schema.actions.hasItemGrid()) {
        return array()
          .required()
          .of(string().required())
          .min(MIN_PRODUCTS_FOR_SHOPPABLE(singleUpc))
          .max(MAX_PRODUCTS_FOR_PROMOTED_AISLE)
          .default([])
      }

      return array().default([])
    }),
    gridOrder: string().when('creativeType', creativeType => {
      const schema = getCreativeSchema(creativeType)

      if (schema.actions.hasItemGrid() || schema.actions.hasProductGroupGrid()) {
        if (gridOrderFlipper && schema.actions.hasGridOrderDynamic()) {
          return string().required().default('DYNAMIC')
        }
        return string().required().default('FIXED')
      }

      return string()
    }),
    [PRODUCT_GROUP_FIELD_NAME]: array().when('creativeType', creativeType => {
      const schema = getCreativeSchema(creativeType)

      if (schema.actions.hasProductGroupGrid()) {
        return array()
          .required()
          .of(string().required())
          .min(MIN_PRODUCT_GROUPS_FOR_SHOPPABLE)
          .max(MAX_PRODUCT_GROUPS_FOR_SHOPPABLE)
          .default([])
      }

      return array()
    }),
    creative: object().when('creativeType', creativeType => {
      switch (creativeType) {
        case CreativeTypeEnum.PromotedAisleV1:
        case CreativeTypeEnum.PromotedAisleV3:
        case CreativeTypeEnum.PromotedAisleV4:
        case CreativeTypeEnum.SmartV1:
        case CreativeTypeEnum.SponsoredRecipeV1:
        case CreativeTypeEnum.OccasionV1:
          return displayAdGroupPromotedAisleCreativeSchema
        case CreativeTypeEnum.PromotedAisleV2:
          return displayAdGroupPromotedAisleV2CreativeSchema
        case CreativeTypeEnum.ImageBannerV2:
          return displayAdGroupImageBannerV2CreativeSchema
        case CreativeTypeEnum.ShoppableVideoV1:
        case CreativeTypeEnum.ShoppableVideoV2:
        case CreativeTypeEnum.ShoppableVideoV3:
        case CreativeTypeEnum.SponsoredRecipeVideoV1:
          return displayAdGroupVideoCreativeSchema
        case CreativeTypeEnum.UvcBannerV1:
          return displayAdGroupUvcBannerCreativeSchema
        default:
          return isSeparateAltTextFieldsFlipperEnabled
            ? displayAdGroupSearchBannerSeparateAltTextFieldsCreativeSchema
            : displayAdGroupSearchBannerCreativeSchema
      }
    }),
  }
}

// eslint-disable-next-line max-params
export function useDisplayAdGroupFormSchema(
  authContext: AuthCtx,
  minBid: number,
  isAdvancedTargetingEnabled: boolean,
  singleUpc?: boolean,
  gridOrderFlipper?: boolean,
  isSeparateAltTextFieldsFlipperEnabled?: boolean
) {
  const returnVal = {
    ...commonDisplayAdGroupSchema,
    ...commonDisplayCreativeSchema({
      authContext,
      singleUpc,
      gridOrderFlipper,
      isSeparateAltTextFieldsFlipperEnabled,
    }),
    ...useCommonTargetingSchema(isAdvancedTargetingEnabled),
    defaultBid: number().required().positive().default(0).min(minBid),
    [BRAND_PAGE_FIELD_NAME]: object().when('creativeType', {
      is: CreativeTypeEnum.SearchBannerV1,
      then: brandPageFormSchema.required(),
      otherwise: brandPageFormSchemaPromotedAisle.required(),
    }),
  }

  return object(returnVal)
}

export type DisplayAdGroupFormData = SchemaType<ReturnType<typeof useDisplayAdGroupFormSchema>> &
  TargetingFormData

export type BrandPageFormData = SchemaType<typeof brandPageFormSchema>

export const useDisplayAdGroupValidator = () => {
  const useShoppableSingleUpc = useFlipper(FLIPPER_SHOPPABLE_SINGLE_UPC)
  const gridOrderFlipper = useFlipper(FLIPPER_SHOPPABLE_GRID_ORDER)
  const isSeparateAltTextFieldsFlipperEnabled = useFlipper(FLIPPER_ENABLE_SEPARATE_ALT_TEXT_FIELDS)
  const isAdvancedTargetingEnabled = useIsAdvancedTargetingEnabledForAccount()
  const minBid = useDisplayMinBid()
  const authContext = useAuthContext()

  return useSyncValidator(
    useDisplayAdGroupFormSchema(
      authContext,
      minBid,
      isAdvancedTargetingEnabled,
      useShoppableSingleUpc,
      gridOrderFlipper,
      isSeparateAltTextFieldsFlipperEnabled
    ).concat(displayTargetingFormSchema),
    'pages.displayProduct.displayAdGroup.validation'
  )
}

export const DisplayAdGroupTypeEnum = {
  ...DisplayAdGroupResponseDataTypeEnum,
  ...PutDisplayCampaignsIdParamDataAttributesAdGroupsTypeEnum,
}

export interface DisplayAdGroupResponseDataWithType
  extends PutDisplayCampaignsIdParamDataAttributesAdGroups {
  type: typeof PutDisplayCampaignsIdParamDataAttributesAdGroupsTypeEnum.DisplayAdGroup
}

export type DisplayAdGroupBackendData = DisplayAdGroupResponseDataWithType
export type CreativeAssetIdentifier = keyof AdminDisplayAdGroupResponseDataAttributesCreativeAssets
export type BrandPageAssetIdentifier = keyof GetAdminBrandPagesIdParamBlockHeroBannerAssets
