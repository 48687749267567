// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostCampaignResponseDataAttributes } from './PostCampaignResponseDataAttributes'
import {
  PostCampaignResponseDataAttributesFromJSON,
  PostCampaignResponseDataAttributesFromJSONTyped,
  PostCampaignResponseDataAttributesToJSON,
} from './PostCampaignResponseDataAttributes'
import type { PostCampaignResponseDataRelationships } from './PostCampaignResponseDataRelationships'
import {
  PostCampaignResponseDataRelationshipsFromJSON,
  PostCampaignResponseDataRelationshipsFromJSONTyped,
  PostCampaignResponseDataRelationshipsToJSON,
} from './PostCampaignResponseDataRelationships'

/**
 *
 * @export
 * @interface PostCampaignResponseData
 */
export interface PostCampaignResponseData {
  /**
   * The campaign ID.
   * @type {string}
   * @memberof PostCampaignResponseData
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PostCampaignResponseData
   */
  type: PostCampaignResponseDataTypeEnum
  /**
   *
   * @type {PostCampaignResponseDataAttributes}
   * @memberof PostCampaignResponseData
   */
  attributes: PostCampaignResponseDataAttributes
  /**
   *
   * @type {PostCampaignResponseDataRelationships}
   * @memberof PostCampaignResponseData
   */
  relationships: PostCampaignResponseDataRelationships
}

/**
 * @export
 */
export const PostCampaignResponseDataTypeEnum = {
  Campaign: 'campaign',
} as const
export type PostCampaignResponseDataTypeEnum =
  typeof PostCampaignResponseDataTypeEnum[keyof typeof PostCampaignResponseDataTypeEnum]

/**
 * Check if a given object implements the PostCampaignResponseData interface.
 */
export function instanceOfPostCampaignResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value
  isInstance = isInstance && 'relationships' in value

  return isInstance
}

export function PostCampaignResponseDataFromJSON(json: any): PostCampaignResponseData {
  return PostCampaignResponseDataFromJSONTyped(json, false)
}

export function PostCampaignResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostCampaignResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: PostCampaignResponseDataAttributesFromJSON(json['attributes']),
    relationships: PostCampaignResponseDataRelationshipsFromJSON(json['relationships']),
  }
}

export function PostCampaignResponseDataToJSON(value?: PostCampaignResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: PostCampaignResponseDataAttributesToJSON(value.attributes),
    relationships: PostCampaignResponseDataRelationshipsToJSON(value.relationships),
  }
}
