// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressions
 */
export interface GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressions {
  /**
   * x-axis. Date
   * @type {string}
   * @memberof GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressions
   */
  x: string
  /**
   * y-axis. Percentage of lost impressions
   * @type {number}
   * @memberof GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressions
   */
  y: number
}

/**
 * Check if a given object implements the GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressions interface.
 */
export function instanceOfGetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'x' in value
  isInstance = isInstance && 'y' in value

  return isInstance
}

export function GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressionsFromJSON(
  json: any
): GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressions {
  return GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressionsFromJSONTyped(
    json,
    false
  )
}

export function GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    x: json['x'],
    y: json['y'],
  }
}

export function GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressionsToJSON(
  value?: GetCampaignsIdActionsParamDataDailyBudgetAtRiskAttributesChartDataEstimatedLostImpressions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    x: value.x,
    y: value.y,
  }
}
