import { mapKeys } from 'lodash'
import { MessageIdType } from 'locales/types'
import { camelCased } from '../common/utils'
import { FormattedMessageProps } from '../components/FormattedMessage'

export interface AttributionData {
  attributedQuantities?: number
  attributedSales?: number
  roas?: number
  ntbRoas?: number
  haloRoas?: number
  directSales?: number
  directUnits?: number
  haloSales?: number
  ntbAttributedSales?: number
  ntbAttributedQuantities?: number
  percentNtbAttributedSales?: number
  ntbPercentageUnits?: number
  ntbDirectSales?: number
  ntbHaloSales?: number
  percentNtbDirectSales?: number
  percentNtbHaloSales?: number
}

export interface Analytics {
  attributedSales?: number
  attributedQuantities?: number
  clicks?: number
  impressions?: number
  spend?: number
  ntbSpend?: number
  ctr?: number
  cpc?: number
  roas?: number
  ntbRoas?: number
  metricsToObscure?: string[]
  opens?: number
  pageViews?: number
  haloRoas?: number
  haloSales?: number
  directSales?: number
  directUnits?: number
  ntbAttributedSales?: number
  ntbAttributedQuantities?: number
  percentNtbAttributedSales?: number
  ntbPercentageUnits?: number
  ntbDirectSales?: number
  ntbHaloSales?: number
  percentNtbDirectSales?: number
  percentNtbHaloSales?: number
  attributionData?: {
    [k in 'lastTouch' | 'linear']?: AttributionData
  }
}

export type ChartCardUnits = 'dollar' | 'number' | 'multiplier' | 'percent' | 'minutes'

export interface ChartCard {
  value: number
  type?: ChartCardUnits
  i18n: MessageIdType
  isBeta?: boolean
  tooltip?: FormattedMessageProps
  featureToolTip?: FormattedMessageProps
}
export type KeyedChartCard = ChartCard & { key: string }
export type ChartCardData = Record<string, ChartCard> | KeyedChartCard[]

// eslint-disable-next-line no-restricted-syntax
export enum ChartUnitValues {
  SPEND = 'spend',
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
  CTR = 'ctr',
  ROAS = 'roas',
  OPENS = 'opens',
  PAGE_VIEWS = 'page_views',
  AVG_CPM = 'avg_cpm',
  REDEMPTIONS = 'redemptions',
  COUPON_SALES = 'coupon_sales',
  SALES = 'sales',
}

export type ChartUnit =
  | ChartUnitValues.SPEND
  | ChartUnitValues.IMPRESSIONS
  | ChartUnitValues.CLICKS
  | ChartUnitValues.CTR
  | ChartUnitValues.ROAS
export const DEFAULT_CHART_UNIT = ChartUnitValues.IMPRESSIONS

export interface GetAnalyticsOptions {
  metric: ChartUnit
  dateRange: {
    startDate: string
    endDate: string
  }
}

export const analyticsYAxisTooltipValue = (chartUnit: ChartUnitValues) => {
  let formatter: Intl.NumberFormat

  switch (chartUnit) {
    case ChartUnitValues.CTR:
      formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, style: 'percent' })
      break
    case ChartUnitValues.AVG_CPM:
    case ChartUnitValues.SPEND:
      formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'USD',
      })
      break
    default:
      formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 3, notation: 'compact' })
  }

  return (value: number) =>
    formatter.format(chartUnit === ChartUnitValues.CTR ? value / 100 : value)
}

export const isCardDataObscured = (metric: ChartUnitValues, analytics?: Analytics) => {
  // Analytics for display are manually snakeCased
  // To retrieve metricsToObscure, we need to access it via the camelCased form
  // to abide by the Analytics interface, hence we camelCase the data to access the value
  // For Display: src/client/pages/DisplayProduct/Reporting/AdGroupDetail/utils.ts

  const cameCasedAnalytics = mapKeys(analytics, (_, key) => camelCased(key)) as Analytics
  const metricsToObscure = cameCasedAnalytics?.metricsToObscure
  return !!metricsToObscure?.includes(metric)
}

export const getChartCardDataValue = (metric: ChartUnitValues, analytics?: Analytics) => {
  const analyticsRecord = analytics as unknown as Record<ChartUnitValues, number>
  return analyticsRecord?.[metric]
}
