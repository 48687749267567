// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ApiAdGroupsControllerUpdateInputAdGroupUserTargeting } from './ApiAdGroupsControllerUpdateInputAdGroupUserTargeting'
import {
  ApiAdGroupsControllerUpdateInputAdGroupUserTargetingFromJSON,
  ApiAdGroupsControllerUpdateInputAdGroupUserTargetingFromJSONTyped,
  ApiAdGroupsControllerUpdateInputAdGroupUserTargetingToJSON,
} from './ApiAdGroupsControllerUpdateInputAdGroupUserTargeting'

/**
 *
 * @export
 * @interface ApiAdGroupsControllerUpdateInputAdGroup
 */
export interface ApiAdGroupsControllerUpdateInputAdGroup {
  /**
   * Specify a value to update the name of the ad group.
   * @type {string}
   * @memberof ApiAdGroupsControllerUpdateInputAdGroup
   */
  name?: string
  /**
   * Set to true to activate the ad group. Set to false to deactivate the ad group. Do not specify this field to make no update.
   * @type {boolean}
   * @memberof ApiAdGroupsControllerUpdateInputAdGroup
   */
  enabled?: boolean
  /**
   * Set to true to enable branded keywords opt-out
   * @type {boolean}
   * @memberof ApiAdGroupsControllerUpdateInputAdGroup
   */
  brandedKeywordsOptOutEnabled?: boolean
  /**
   *
   * @type {ApiAdGroupsControllerUpdateInputAdGroupUserTargeting}
   * @memberof ApiAdGroupsControllerUpdateInputAdGroup
   */
  userTargeting?: ApiAdGroupsControllerUpdateInputAdGroupUserTargeting
  /**
   * The maximum amount, in dollars, you want to pay per click for advertised products in this ad group.
   * May be `null` for campaigns with `optimized_bidding_enabled = true` and must not be null otherwise
   * @type {number}
   * @memberof ApiAdGroupsControllerUpdateInputAdGroup
   */
  defaultBid?: number
}

/**
 * Check if a given object implements the ApiAdGroupsControllerUpdateInputAdGroup interface.
 */
export function instanceOfApiAdGroupsControllerUpdateInputAdGroup(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ApiAdGroupsControllerUpdateInputAdGroupFromJSON(
  json: any
): ApiAdGroupsControllerUpdateInputAdGroup {
  return ApiAdGroupsControllerUpdateInputAdGroupFromJSONTyped(json, false)
}

export function ApiAdGroupsControllerUpdateInputAdGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdGroupsControllerUpdateInputAdGroup {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
    brandedKeywordsOptOutEnabled: !exists(json, 'branded_keywords_opt_out_enabled')
      ? undefined
      : json['branded_keywords_opt_out_enabled'],
    userTargeting: !exists(json, 'user_targeting')
      ? undefined
      : ApiAdGroupsControllerUpdateInputAdGroupUserTargetingFromJSON(json['user_targeting']),
    defaultBid: !exists(json, 'default_bid') ? undefined : json['default_bid'],
  }
}

export function ApiAdGroupsControllerUpdateInputAdGroupToJSON(
  value?: ApiAdGroupsControllerUpdateInputAdGroup | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    enabled: value.enabled,
    branded_keywords_opt_out_enabled: value.brandedKeywordsOptOutEnabled,
    user_targeting: ApiAdGroupsControllerUpdateInputAdGroupUserTargetingToJSON(value.userTargeting),
    default_bid: value.defaultBid,
  }
}
