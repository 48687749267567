// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { BulkCampaignsResponseDataAttributes } from './BulkCampaignsResponseDataAttributes'
import {
  BulkCampaignsResponseDataAttributesFromJSON,
  BulkCampaignsResponseDataAttributesFromJSONTyped,
  BulkCampaignsResponseDataAttributesToJSON,
} from './BulkCampaignsResponseDataAttributes'

/**
 *
 * @export
 * @interface BulkCampaignsResponseData
 */
export interface BulkCampaignsResponseData {
  /**
   *
   * @type {string}
   * @memberof BulkCampaignsResponseData
   */
  type: BulkCampaignsResponseDataTypeEnum
  /**
   * HTTP Status Code
   * @type {number}
   * @memberof BulkCampaignsResponseData
   */
  status: number
  /**
   *
   * @type {Array<BulkCampaignsResponseDataAttributes>}
   * @memberof BulkCampaignsResponseData
   */
  attributes: Array<BulkCampaignsResponseDataAttributes>
}

/**
 * @export
 */
export const BulkCampaignsResponseDataTypeEnum = {
  Campaigns: 'campaigns',
} as const
export type BulkCampaignsResponseDataTypeEnum =
  typeof BulkCampaignsResponseDataTypeEnum[keyof typeof BulkCampaignsResponseDataTypeEnum]

/**
 * Check if a given object implements the BulkCampaignsResponseData interface.
 */
export function instanceOfBulkCampaignsResponseData(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function BulkCampaignsResponseDataFromJSON(json: any): BulkCampaignsResponseData {
  return BulkCampaignsResponseDataFromJSONTyped(json, false)
}

export function BulkCampaignsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BulkCampaignsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    status: json['status'],
    attributes: (json['attributes'] as Array<any>).map(BulkCampaignsResponseDataAttributesFromJSON),
  }
}

export function BulkCampaignsResponseDataToJSON(value?: BulkCampaignsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    status: value.status,
    attributes: (value.attributes as Array<any>).map(BulkCampaignsResponseDataAttributesToJSON),
  }
}
