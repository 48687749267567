// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributes
 */
export interface ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributes {
  /**
   * A descriptive name for the ad group. If blank, a default name is given.
   * @type {string}
   * @memberof ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributes
   */
  name?: string
  /**
   * Campaign ID for the target campaign where the ad group will be copied to. If blank, the target is the same campaign.
   * @type {string}
   * @memberof ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributes
   */
  campaignId?: string
}

/**
 * Check if a given object implements the ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributes interface.
 */
export function instanceOfApiAdGroupsControllerDuplicateAsyncInputOverrideAttributes(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributesFromJSON(
  json: any
): ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributes {
  return ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributesFromJSONTyped(json, false)
}

export function ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
  }
}

export function ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributesToJSON(
  value?: ApiAdGroupsControllerDuplicateAsyncInputOverrideAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    campaign_id: value.campaignId,
  }
}
